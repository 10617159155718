import { Component } from '@angular/core';
import { SelectedFilterComponent } from 'gung-list';

@Component({
  selector: 'otto-olsen-selected-filter',
  templateUrl: './otto-olsen-selected-filter.component.html',
  styleUrl: './otto-olsen-selected-filter.component.scss'
})
export class OttoOlsenSelectedFilterComponent extends SelectedFilterComponent {

  clickDeselectAll() {
    for (let index = 0; index < this.selectedOptions.length; index++) {
      const filter = this.selectedOptions[index];
      this.clickRemoveFilter(filter);
    }
    this.deselectAll.emit();
  }
}
