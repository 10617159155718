<div class="">
  <!-- BREADCRUMB -->
  <div class="row row-breadcrumb" *ngIf="breadcrumbMenu">
    <div class="col-12">
      <!-- <lib-breadcrumb></lib-breadcrumb> <hr/> -->
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb px-0">
          <li
            *ngFor="let breadcrumb of breadcrumbMenu; let last = last"
            class="breadcrumb-item"
            [attr.aria-current]="last ? 'page' : undefined"
          >
            <a [ngClass]="{ 'active-assortment': last }" [routerLink]="breadcrumb.urlParts" translate>{{
              breadcrumb.label
            }}</a>
          </li>
        </ol>
      </nav>
    </div>
  </div>
  <!-- HEADER -->
  <div class="current-assortment" *ngIf="currentAssortment && type && type !== 'PRODUCT'">
    <!-- TITLE ONLY -->
    <div class="row" *ngIf="type !== 'PRODUCTS' && type !== 'CONCEPT'">
      <div class="col-12 current-assortment-name">
        <h2>
          {{ currentAssortment | productName }}
          <a
            *ngIf="isAssortmentManager && !pimEditUrlHide && currentAssortmentPimUrl"
            class="pim-edit"
            href="{{ currentAssortmentPimUrl }}"
            target="_blank"
            (click)="$event.stopPropagation()"
          >
            <i class="fa-regular fa-pen-to-square"></i>
          </a>
        </h2>
      </div>
      <div
        [innerHTML]="currentAssortment.extra.i18n[currentLang].assortmentDescription"
        class="col-12 current-assortment-description"
      ></div>
    </div>
    <!-- TITLE and DETAILS -->
    <div class="row pt-2 pb-4" *ngIf="(type === 'PRODUCTS' || type === 'CONCEPT') && !infoPage">
      <div class="col-xs-12 col-sm-6">
        <!--  && (currentAssortment.extra.images && currentAssortment.extra.images.length && currentAssortment.extra.images[0].s3Uri !== 'images/no-image.jpg') -->
        <!-- <lib-image-carousel *ngIf="currentAssortment" imageSize="400x300"
          [openExternal]="true" [zoom]="false" [images]="currentAssortment.extra.images" (selectedImage)="changeSelectedImage($event)"></lib-image-carousel> -->
        <otto-olsen-image-carousel
          *ngIf="currentAssortment"
          imageSize="400x300"
          [openExternal]="true"
          [zoom]="false"
          [images]="currentAssortment?.extra?.images"
          (selectedImage)="changeSelectedImage($event)"
          [videoUrl]="currentAssortment.extra.pim?.videolink"
        ></otto-olsen-image-carousel>
      </div>
      <!-- Product data -->
      <div class="col-xs-12 col-sm-6">
        <div class="row">
          <div class="col-12">
            <h1>
              {{ currentAssortment | productName }}
              <a
                *ngIf="isProductManager && !pimEditUrlHide && currentConceptPimUrl"
                class="pim-edit"
                href="{{ currentConceptPimUrl }}"
                target="_blank"
                (click)="$event.stopPropagation()"
              >
                <i class="fa-regular fa-pen-to-square"></i>
              </a>
            </h1>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <ul>
              <li *ngFor="let bullet of currentAssortment.extra.bullets">{{ bullet }}</li>
            </ul>
          </div>
        </div>
        <div class="row mt-4" *ngIf="currentAssortment.description">
          <div class="col-12">
            <!-- <p>{{ currentAssortment.description }}</p> -->
            <div *ngIf="!currentAssortment?.extra.descriptionShort || currentAssortment?.extra.hideShort; else descriptionShort" [innerHTML]="currentAssortment.description | safeHtml"></div>
            <button *ngIf="currentAssortment?.extra.descriptionShort && currentAssortment?.extra.hideShort" (click)="currentAssortment?.extra.hideShort = false" type="button" class="btn btn-sm btn-link border my-3 py-0 px-5"><span class="cursor-pointer font-weight-bold" translate>SHOW_LESS</span><i class="fa-solid fa-chevron-up ml-2"></i></button>
            <ng-template #descriptionShort>
              <div [innerHTML]="currentAssortment.extra.descriptionShort | safeHtml"></div>
              <button type="button" class="btn btn-sm btn-link border my-3 py-0 px-5" (click)="currentAssortment?.extra.hideShort = true"><span class="cursor-pointer font-weight-bold" translate>SHOW_MORE</span><i class="fa-solid fa-chevron-down ml-2"></i></button>
            </ng-template>
          </div>
        </div>
        <div class="row" *ngIf="currentAssortment?.documents && currentAssortment?.documents.length > 0">
          <div class="col-12 mt-2">
            <div class="table-responsive">
              <table class="table table-borderless table-striped table-sm">
                <thead>
                  <tr>
                    <th scope="col" translate>DOCUMENT</th>
                    <th scope="col" translate>DOWNLOAD_DOCUMENT</th>
                  </tr>
                </thead>

                <tbody>
                  <tr *ngFor="let doc of currentAssortment?.documents">
                    <td translate>
                      {{ doc.name }}
                      <i
                        title="Internal"
                        *ngIf="doc.extra?.permission === 'Internal'"
                        class="fa-solid fa-house-lock"
                      ></i>
                    </td>
                    <td>
                      <a href="{{ doc.url }}" target="_blank" translate>DOWNLOAD</a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- LIST -->
  <ng-template libCustomHost></ng-template>
  <!-- InfoPages -->
  <div class="row" *ngIf="infoPage">
    <div class="col-12">
      <otto-olsen-product-info-page [product]="currentAssortment"></otto-olsen-product-info-page>
    </div>
  </div>
  <!-- <div *ngIf="type === 'CONCEPT' && products && products.length === 0"><span translate>NOTHING_FOUND_CONTACT_GUNG</span></div> -->
  <div class="row">
    <div class="col-12">
      <div id="parker"></div>
    </div>
  </div>
</div>

<div *ngIf="!currentAssortment || !breadcrumbMenu || !type" class="px-5">
  <div *ngIf="!isProductAccessForbidden; else isAccessForbidden">
    LOADING...
  </div>
</div>

<ng-template #isAccessForbidden>
  <span translate>ACCESS_FORBIDDEN_ERROR</span>
</ng-template>
