import { Injectable, Type } from '@angular/core';
import {
  ButtonsComponent,
  CartSalesStepComponent,
  CartTotalStepComponent,
  CheckoutStepComponent,
  QuantityValidationStepComponent,
  RemoveBlockedProductsStepComponent,
  SimplifiedOrderContext
} from 'gung-standard';
import {
  AssignJeevesDefaultsComponent,
  AssignOrderRowsJeevesComponent,
  DeliveryLocationsJeevesComponent,
  JeevesCheckoutConfigService,
  OrderTermsJeevesComponent,
  SummarizeOrderJeevesComponent
} from 'gung-standard-jeeves';
import { Observable } from 'rxjs';
import { map } from 'rxjs';
import { OttoOlsenAssignJeevesDefaultComponent } from '../components/checkout/steps/otto-olsen-assign-jeeves-default/otto-olsen-assign-jeeves-default.component';
import { OttoOlsenCuttingFeeComponent } from '../components/checkout/steps/otto-olsen-cutting-fee/otto-olsen-cutting-fee.component';
import { OttoOlsenDeliveryLocationsJeevesComponent } from '../components/checkout/steps/otto-olsen-delivery-locations-jeeves/otto-olsen-delivery-locations-jeeves.component';
import { OttoOlsenFreightCostComponent } from '../components/checkout/steps/otto-olsen-freight-cost/otto-olsen-freight-cost.component';
import { OttoOlsenOrderTermsJeevesWithCartListComponent } from '../components/checkout/steps/otto-olsen-order-terms-jeeves-with-cart-list/otto-olsen-order-terms-jeeves-with-cart-list.component';
import { OttoOlsenOrderTermsJeevesWithCartComponent } from '../components/checkout/steps/otto-olsen-order-terms-jeeves-with-cart/otto-olsen-order-terms-jeeves-with-cart.component';
import { OttoOlsenRemoveBlockedProductsStepComponent } from '../components/checkout/steps/otto-olsen-remove-blocked-products-step/otto-olsen-remove-blocked-products-step.component';
import { OttoOlsenSummarizeOrderComponent } from '../components/checkout/steps/otto-olsen-summarize-order/otto-olsen-summarize-order.component';
import { OttoOlsenCheckoutButtonsComponent } from '../components/otto-olsen-checkout-buttons/otto-olsen-checkout-buttons.component';

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenCheckoutConfigService extends JeevesCheckoutConfigService {
  hasUnsavedChanges: boolean = false;

  getPreProcessSteps(): Observable<Type<CheckoutStepComponent>[]> {
    return super.getPreProcessSteps().pipe(
      map(d => {
        if (d.indexOf(RemoveBlockedProductsStepComponent) > -1) {
          d[d.indexOf(RemoveBlockedProductsStepComponent)] = OttoOlsenRemoveBlockedProductsStepComponent;
        }
        return d;
      })
    );
  }

  getMainProcessSteps(): Observable<Type<CheckoutStepComponent>[]> {
    return super.getMainProcessSteps().pipe(
      map(d => {
        // We want assign order rows to happen before the cart modification starts.
        if (d.indexOf(AssignOrderRowsJeevesComponent) > 0) {
          // make the assign step be first
          //     d.splice(d.indexOf(AssignOrderRowsJeevesComponent), 1);
          d.unshift(OttoOlsenOrderTermsJeevesWithCartListComponent);
          //      d.unshift(OttoOlsenCheckoutCartComponent);
          //     d.unshift(AssignOrderRowsJeevesComponent);
        }

        // replace the cart display step

        //   steps[steps.indexOf(BcCartSalesStepComponent)] = ScandcosBcCheckoutCartComponent;

        if (d.indexOf(AssignJeevesDefaultsComponent) > -1) {
          d[d.indexOf(AssignJeevesDefaultsComponent)] = OttoOlsenAssignJeevesDefaultComponent;
        }
        // OttoOlsenCheckoutCartComponent
        if (d.indexOf(DeliveryLocationsJeevesComponent) > -1) {
          d[d.indexOf(DeliveryLocationsJeevesComponent)] = OttoOlsenDeliveryLocationsJeevesComponent;
        }

        // To test gty validation, enable this line
        // d.splice(2,0,  QuantityValidationStepComponent);

        // Add new first step cart list with order terms
        //     d.unshift(OttoOlsenOrderTermsJeevesWithCartListComponent);

        d.splice(d.indexOf(OttoOlsenOrderTermsJeevesWithCartListComponent) + 1, 0, OttoOlsenFreightCostComponent);
        d.splice(d.indexOf(OttoOlsenFreightCostComponent) + 1, 0, OttoOlsenCuttingFeeComponent);
        if (d.indexOf(CartSalesStepComponent) > -1) {
          d.splice(d.indexOf(CartSalesStepComponent), 1);
        }
        if (d.indexOf(CartTotalStepComponent) > -1) {
          d.splice(d.indexOf(CartTotalStepComponent), 1);
        }
        if (d.indexOf(OrderTermsJeevesComponent) > -1) {
          d.splice(d.indexOf(OrderTermsJeevesComponent), 1);
        }
        if (d.indexOf(SummarizeOrderJeevesComponent) > -1) {
          d[d.indexOf(SummarizeOrderJeevesComponent)] = OttoOlsenSummarizeOrderComponent;
        }

        d.push(QuantityValidationStepComponent);

        // NEW STEP VERSION - IN PROGRESS
        if (d.indexOf(OttoOlsenOrderTermsJeevesWithCartListComponent) > -1) {
          d[d.indexOf(OttoOlsenOrderTermsJeevesWithCartListComponent)] = OttoOlsenOrderTermsJeevesWithCartComponent;
        }

        return d;
      })
    );
  }

  getInternalPreProcessSteps(): Observable<Type<CheckoutStepComponent>[]> {
    return super.getInternalPreProcessSteps().pipe(
      map(d => {
        if (d.indexOf(AssignJeevesDefaultsComponent) > -1) {
          d[d.indexOf(AssignJeevesDefaultsComponent)] = OttoOlsenAssignJeevesDefaultComponent;
        }
        return d;
      })
    );
  }

  getButtonsComponent(): Type<ButtonsComponent> {
    return OttoOlsenCheckoutButtonsComponent;
  }

  onOrderSubmitted(context: SimplifiedOrderContext, isOffer = false): void {
    this.hasUnsavedChanges = false;
    super.onOrderSubmitted(context, isOffer);
  }
}
