<div class="row" *ngIf="form && selectedDeliveryLocation">
  <div class="col-12 col-md-6">
    <div class="form-group">
      <label for="deliveryLocation" class="control-label" translate>DELIVERY_LOCATION</label>
      <select name="deliveryLocation" id="deliveryLocation" class="form-control form-select" [(ngModel)]="deliveryLocationId">
        <option [value]="option.id" *ngFor="let option of deliveryLocations">
          {{ option.name }} - {{ option.extra.fr.ftgpostadr5 }}, {{ option.extra.fr.ftgpostadr3 }}
        </option>
      </select>
    </div>
    <div class="custom-control custom-checkbox">
      <input
        type="checkbox"
        class="custom-control-input"
        id="editDeliveryLocation"
        name="editDeliveryLocation"
        (change)="enabledisableDeliveryAddress($event.target.checked)"
      />
      <label class="custom-control-label" for="editDeliveryLocation" translate>CUSTOM_DELIVERY_ADDRESS</label>
    </div>
    <div
      *ngIf="enableEditDeliveryAddress"
      class="alert alert-warning text-center"
      translate
    >
      FORM_WARNING_ENABLE_EDIT_DELIVERY_ADDRESS
    </div>
    <div
      *ngIf="enableEditDeliveryAddress"
      class="alert alert-danger text-center"
      translate
    >
      FORM_DANGER_ENABLE_EDIT_DELIVERY_ADDRESS
    </div>
    <fieldset [disabled]="!enableEditDeliveryAddress">
      <div class="form-group" [formGroup]="form">
        <label for="ordlevadr1" class="control-label" translate>OH_ORDLEVADR1</label>
        <input class="form-control" type="text" name="ordlevadr1" id="ordlevadr1" [formControlName]="'ordlevadr1'" />
      </div>
      <div class="form-group" [formGroup]="form">
        <label for="ordlevadr2" class="control-label" translate>OH_ORDLEVADR2</label>
        <input class="form-control" type="text" name="ordlevadr2" id="ordlevadr2" [formControlName]="'ordlevadr2'" />
      </div>
      <div class="form-group" [formGroup]="form">
        <label for="ordlevadr3" class="control-label" translate>OH_ORDLEVADR3</label>
        <input class="form-control" type="text" name="ordlevadr3" id="ordlevadr3" [formControlName]="'ordlevadr3'" />
      </div>
      <div class="form-group">
        <label for="postalCode" class="control-label" translate>OH_FTGPOSTNR</label>
        <input
          class="form-control"
          type="text"
          (keypress)="numberOnly($event)"
          pattern="\d{4}"
          maxlength="4"
          name="postalCode"
          id="postalCode"
          [(ngModel)]="postalCode"
          #postalCodeEle="ngModel"
        />
        <!-- <lib-forms-message-error [controller]="postalCode"></lib-forms-message-error> -->
        <div
          *ngIf="postalCodeEle && postalCodeEle.invalid && (postalCodeEle.dirty || postalCodeEle.touched)"
          class="alert alert-danger"
        >
          {{ 'FORM_ERROR_MAX_LENGTH' | translate: { actualLength: (postalCode + '').length, requiredLength: 4 } }}
        </div>
      </div>
      <div class="form-group">
        <label for="city" class="control-label" translate>OH_ORDLEVADR4</label>
        <input class="form-control" type="text" name="city" id="city" [(ngModel)]="city" />
      </div>
      <!-- <div [formGroup]="form">
            <lib-meta-select-input formControlName="ordlevadrlandskod" table="xr" metaId="landskod" metaDisplay="land">
            </lib-meta-select-input>
        </div> -->
    </fieldset>
    <fieldset [disabled]="!enableEditDeliveryAddress" *ngIf="enableEditDeliveryAddress">
      <div [formGroup]="formProcargs">
        <lib-meta-select-input
          [hideEmptyOption]="true"
          formControlName="levsattkod"
          table="x2f"
          metaId="levsattkod"
          metaDisplay="levsattbeskr"
          [optionsFilterFunction]="optionsFilterDeliveryMethod.bind(this, selectedCustomer)"
          (ngModelChange)="onDeliveryMethodChanged($event)"
        >
        </lib-meta-select-input>
        <div
          *ngIf="
            formProcargs.get('levsattkod').invalid &&
            (formProcargs.get('levsattkod').dirty || formProcargs.get('levsattkod').touched)
          "
          class="alert alert-danger"
        >
          <span translate>FORM_ERROR_REQUIRED</span>
        </div>
      </div>
    </fieldset>
  </div>
  <div class="col-12 col-md-6">
    <div class="form-group" [formGroup]="formOh">
      <label for="ta_mailnotified" class="control-label" translate>OH_TA_MAILNOTIFIED</label>
      <input
        class="form-control"
        type="text"
        name="ta_mailnotified"
        id="ta_mailnotified"
        formControlName="ta_mailnotified"
      />
    </div>
    <lib-confirmation-emails (emailsUpdated)="checkout.extra.confirmationEmails = $event"></lib-confirmation-emails>
    <!-- <div class="form-group" [formGroup]="formOh">
      <label for="godsmarke3" class="control-label" translate>OH_GODSMARKE3</label>
      <input class="form-control" type="text" name="godsmarke3" id="godsmarke3" [formControlName]="'godsmarke3'">
    </div> -->
    <div class="form-group" [formGroup]="formOh">
      <label for="godsmarke4" class="control-label" translate>OH_GODSMARKE4</label>
      <input class="form-control" type="text" name="godsmarke4" id="godsmarke4" [formControlName]="'godsmarke4'" />
    </div>
    <div class="form-group" [formGroup]="formOh">
      <label for="kundbestnr" class="control-label"><span translate>OH_KUNDBESTNR</span>*</label>
      <input class="form-control" type="text" name="kundbestnr" id="kundbestnr" formControlName="kundbestnr" />
      <div
        *ngIf="formOh.get('kundbestnr').invalid && (formOh.get('kundbestnr').dirty || formOh.get('kundbestnr').touched)"
        class="alert alert-danger"
      >
        <span translate>FORM_ERROR_REQUIRED</span>
      </div>
    </div>
    <div class="form-group" [formGroup]="formOh">
      <label for="kundref2" class="control-label" translate>OH_KUNDREF2</label>
      <input class="form-control" type="text" name="kundref2" id="kundref2" formControlName="kundref2" />
    </div>
    <div class="form-group" [formGroup]="formOh">
      <label for="edit" class="control-label w-100" translate>OH_EDITEXT</label>
      <textarea id="edit" name="edit" class="form-control w-100" formControlName="edit" rows="9" cols="50"></textarea>
    </div>
  </div>
</div>

<!--
  oh.ordberlevdat // Leverans datum TODO-AL
-->
