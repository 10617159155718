<div class="row">
  <div class="col-12 col-md-6">
    <h4 translate>DELIVERY_ADDRESS</h4>
    <div class="form-group">
      <label for="ordlevadr1" class="control-label" translate>OH_ORDLEVADR1</label>
      <input
        type="text"
        class="form-control form-control-sm"
        id="ordlevadr1"
        [value]="checkout.extra.procargs.ordlevadr1 || ''"
        readonly
      />
    </div>
    <div class="form-group">
      <label for="ordlevadr2" class="control-label" translate>OH_ORDLEVADR2</label>
      <input
        type="text"
        class="form-control form-control-sm"
        id="ordlevadr2"
        [value]="checkout.extra.procargs.ordlevadr2 || ''"
        readonly
      />
    </div>
    <div class="form-group">
      <label for="ordlevadr3" class="control-label" translate>OH_ORDLEVADR3</label>
      <input
        type="text"
        class="form-control form-control-sm"
        id="ordlevadr3"
        [value]="checkout.extra.procargs.ordlevadr3 || ''"
        readonly
      />
    </div>
    <div class="form-group">
      <label for="ordlevadr4" class="control-label" translate>OH_FTGPOSTNR</label>
      <input
        type="text"
        class="form-control form-control-sm"
        id="ordlevadr4"
        [value]="checkout.extra.procargs.ordlevadr4 || ''"
        readonly
      />
    </div>
    <div class="form-group">
      <label for="ordlevadrbstort" class="control-label" translate>OH_ORDLEVADR4</label>
      <input
        type="text"
        class="form-control form-control-sm"
        id="ordlevadrbstort"
        [value]="checkout.extra.procargs.ordlevadrbstort || ''"
        readonly
      />
    </div>
    <lib-meta-select-input
      [(ngModel)]="checkout.extra.procargs.ordlevadrlandskod"
      table="xr"
      metaId="landskod"
      metaDisplay="land"
      [disabled]="true"
      class="gung-select-input-sm"
    >
    </lib-meta-select-input>
  </div>
  <div class="col-12 col-md-6" *ngIf="customer">
    <h4 translate>INVOICE_ADDRESS</h4>
    <div class="form-group">
      <label for="billingFtgnr" class="control-label" translate>CUSTOMER_ID</label>
      <input
        type="text"
        class="form-control form-control-sm"
        id="billingFtgnr"
        [value]="customer.extra._billing.ftgnr || ''"
        readonly
      />
    </div>
    <div class="form-group">
      <label for="billingFtgnamn" class="control-label" translate>FTGNAMN</label>
      <input
        type="text"
        class="form-control form-control-sm"
        id="billingFtgnamn"
        [value]="customer.extra._billing.ftgnamn || ''"
        readonly
      />
    </div>
    <div class="form-group">
      <label for="billingFtgpostadr2" class="control-label" translate>FTGPOSTADR2</label>
      <input
        type="text"
        class="form-control form-control-sm"
        id="billingFtgpostadr2"
        [value]="customer.extra._billing.ftgpostadr2 || ''"
        readonly
      />
    </div>
    <div class="form-group">
      <label for="billingFtgpostnr" class="control-label" translate>FTGPOSTNR</label>
      <input
        type="text"
        class="form-control form-control-sm"
        id="billingFtgpostnr"
        [value]="customer.extra._billing.ftgpostnr || ''"
        readonly
      />
    </div>
    <div class="form-group">
      <label for="billingFtgpostadr3" class="control-label" translate>FTGPOSTADR3</label>
      <input
        type="text"
        class="form-control form-control-sm"
        id="billingFtgpostadr3"
        [value]="customer.extra._billing.ftgpostadr3 || ''"
        readonly
      />
    </div>
    <lib-meta-select-input
      [(ngModel)]="customer.extra._billing.landskod"
      table="xr"
      metaId="landskod"
      metaDisplay="land"
      [disabled]="true"
      class="gung-select-input-sm"
    >
    </lib-meta-select-input>
  </div>
</div>

<h4 translate>CONDITIONS</h4>
<div *ngIf="!sales" class="row">
  <div class="col-12 col-md-6">
    <lib-meta-select-input
      [(ngModel)]="checkout.extra.procargs.levsattkod"
      table="x2f"
      metaId="levsattkod"
      metaDisplay="levsattbeskr"
      [disabled]="true"
      class="gung-select-input-sm"
    >
    </lib-meta-select-input>
  </div>
  <div class="col-12 col-md-6">
    <lib-meta-select-input
      [(ngModel)]="checkout.extra.procargs.levvillkkod"
      table="x2e"
      metaId="levvillkkod"
      metaDisplay="levvillkbeskr"
      [disabled]="true"
      class="gung-select-input-sm"
    >
    </lib-meta-select-input>
  </div>
</div>
<div *ngIf="sales" class="row">
  <div class="col-12 col-md-6">
    <lib-meta-select-input
      [(ngModel)]="checkout.extra.procargs.levsattkod"
      table="x2f"
      metaId="levsattkod"
      metaDisplay="levsattbeskr"
      [disabled]="true"
      class="gung-select-input-sm"
    >
    </lib-meta-select-input>
    <lib-meta-select-input
      [(ngModel)]="checkout.extra.procargs.levvillkkod"
      table="x2e"
      metaId="levvillkkod"
      metaDisplay="levvillkbeskr"
      [disabled]="true"
      class="gung-select-input-sm"
    >
    </lib-meta-select-input>
  </div>
  <div class="col-12 col-md-6">
    <lib-meta-select-input
      [(ngModel)]="checkout.extra.oh.betkod"
      table="x2"
      metaId="betkod"
      metaDisplay="betvillbeskr"
      [disabled]="true"
      class="gung-select-input-sm"
    >
    </lib-meta-select-input>
    <lib-meta-select-input
      [(ngModel)]="checkout.extra.procargs.saljare"
      table="salj"
      metaId="saljare"
      metaDisplay="saljarenamn"
      [disabled]="true"
      class="gung-select-input-sm"
    >
    </lib-meta-select-input>
  </div>
</div>

<div *ngIf="cartRowsProducts && productsMap && pricesMap">
  <h4 translate>ROWS</h4>
  <div class="row">
    <div class="col-12 table-responsive gung-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th translate>PRODUCT_ID</th>
            <th translate>NAME</th>
            <th translate>ESTIMATED_DELIVERY_DATE</th>
            <th translate>QUANTITY</th>
            <th translate>UNIT_PRICE</th>
            <th *ngIf="sales" class="d-table-cell" translate>DISCOUNT</th>
            <th translate>TOTAL</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of cartRowsProducts">
            <td [attr.data-label]="'PRODUCT_ID' | translate">
              {{ row.id }}
              <b
                *ngIf="
                  selectedCustomer &&
                  row.product.extra._customerCrossReferences &&
                  row.product.extra._customerCrossReferences[selectedCustomer.id]
                "
                class="d-block small"
              >
                {{ row.product.extra._customerCrossReferences[selectedCustomer.id].referenceId }}
              </b>
            </td>
            <td [attr.data-label]="'NAME' | translate">
              {{ productsMap[row.id] | productName }}
              <span *ngIf="row?.extra?.orp?.editext" class="d-block">
                <b translate>NOTE</b>: <span style="white-space: pre-line">{{ row.extra.orp.editext }}</span>
              </span>
            </td>
            <td [attr.data-label]="'ESTIMATED_DELIVERY_DATE' | translate">
              <span *ngIf="row.extra.orp && row.extra.orp.ordberlevdat; else orderHeadDate">{{
                row.extra.orp.ordberlevdat | date: dateUtilService.dateFormat
              }}</span>
              <ng-template #orderHeadDate>
                <span *ngIf="checkout.extra.oh.ordberlevdat; else defaultDate">{{
                  checkout.extra.oh.ordberlevdat | date: dateUtilService.dateFormat
                }}</span>
              </ng-template>
              <ng-template #defaultDate>{{ today | date: dateUtilService.dateFormat }}</ng-template>
            </td>
            <td [attr.data-label]="'QUANTITY' | translate">
              {{ row.quantity }} {{ productsMap[row.id].extra.ar?.enhetskod }}
            </td>
            <td [attr.data-label]="'UNIT_PRICE' | translate">
              <span *ngIf="pricesMap">
                <lib-price [price]="pricesMap[row.id]" type="cartRowUnitPrice"></lib-price>
              </span>
            </td>
            <td [attr.data-label]="'DISCOUNT' | translate" *ngIf="sales" class="d-table-cell">
              <span *ngIf="pricesMap && pricesMap[row.id].cartRowDiscountPercent" [class.blur]="hidePrice"
                >{{ pricesMap[row.id].cartRowDiscountPercent | number: '1.0-2' }} %</span
              >
            </td>
            <td [attr.data-label]="'TOTAL' | translate">
              <span *ngIf="pricesMap">
                <lib-price [price]="pricesMap[row.id]" type="cartRowTotalPriceInclRowDiscount"></lib-price>
              </span>
            </td>
            <!-- <td *ngIf="pricesMap">{{pricesMap[row.id].customerNetPrice.value}} {{pricesMap[row.id].customerNetPrice.currencyCode}}</td> -->
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
