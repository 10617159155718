import { Component, OnInit } from '@angular/core';
import { AuthService, CartKeyService, CartService, CheckoutConfigService, CheckoutObject, CheckoutRow, Customer, CustomerContactService, GungFlow, GungFlowService, GungModalService, OrderSubmitService, SelectedCustomerService } from 'gung-standard';
import { JeevesDeliveryAddressesService, JeevesOrderDeliveryAddress } from 'gung-standard-jeeves';
import { forkJoin, first, mergeMap, of, switchMap } from 'rxjs';
import { OttoOlsenFreightCostService } from '../../../services/otto-olsen-freight-cost.service';
import { User } from 'gung-standard/lib/state/auth/types';
import { Router } from '@angular/router';

@Component({
  selector: 'otto-olsen-scanner-checkout-confirm',
  templateUrl: './otto-olsen-scanner-checkout-confirm.component.html',
  styleUrls: ['./otto-olsen-scanner-checkout-confirm.component.scss']
})
export class OttoOlsenScannerCheckoutConfirmComponent implements OnInit {
  checkout: CheckoutObject = { extra: {}, rows: [] };
  freightCostProductId = this.freightCostService.freightCostProductId;
  error: string;
  userEmailConfirmation: String[];
  kundbestnr: string;
  kundref2: string;
  edit: string;
  submitting = false;
  public isRoleScannerKunde = false;
  private isDemoAccount = false;

  constructor(
    protected selectedCustomerService: SelectedCustomerService,
    protected gungFlowService: GungFlowService,
    protected authService: AuthService,
    protected cartService: CartService,
    protected freightCostService: OttoOlsenFreightCostService,
    protected jeevesDeliveryAddressesService: JeevesDeliveryAddressesService,
    protected customerContactService: CustomerContactService,
    protected gungModalService: GungModalService,
    protected checkoutConfig: CheckoutConfigService,
    protected orderSubmitService: OrderSubmitService,
    protected cartKeyService: CartKeyService,
    protected router: Router
  ) { }

  ngOnInit(): void {
    this.assignDefaults();
  }

  assignDefaults() {
    forkJoin([
      this.selectedCustomerService.getSelectedCustomer().pipe(first()),
      this.gungFlowService.getSelectedFlow().pipe(first()),
      this.authService.getCurrentUser().pipe(first()),
      this.cartService.getCurrentCart().pipe(first())
    ])
      .pipe(
        mergeMap(([customer, flow, user, cartRows]) => {
          this.isDemoAccount = user.roles.indexOf('DEMO') > -1;
          this.isRoleScannerKunde = Object.keys(user.activeGroups).filter(g => user.activeGroups[g]).findIndex(r => r.indexOf('SCANNER-KUNDE') > -1) > -1;
          const productIds = cartRows.map(row => row.productId);
          if (productIds.includes(this.freightCostProductId)) {
            const freight = cartRows.find(r => r.productId === this.freightCostProductId);
            if (freight.extra._calculateFreight) {
              this.cartService.removeRow(freight);
            }
          }
          return forkJoin([
            of(customer),
            this.jeevesDeliveryAddressesService.getOrderedDeliveryAddressesForCustomer(customer.id),
            of(flow),
            of(user),
            of(cartRows)
          ]);
        }),
        mergeMap(([customer, deliveryAddressList, flow, user, cartRows]) => {
          return forkJoin([
            of(customer),
            of(deliveryAddressList),
            of(flow),
            of(user),
            of(cartRows),
            this.customerContactService.getCustomerContacts(customer.id).pipe(first()),
            of(undefined)
          ]);
        })
      )
      .subscribe(([customer, deliveryAddressList, flow, user, cartRows, customerContacts, billingCustomer]) => {
        this.setValues(customer, flow, user, cartRows);
        this.setDeliveryAddress(deliveryAddressList[0]);
        this.checkout.extra.oh.slutkundnamn2 = user.username;
        this.checkout.extra.confirmationEmails = [user.email];
      });
  }

  protected setValues(
    customer: Customer,
    flow: GungFlow,
    user: User,
    cartRows
  ) {
    this.kundref2 = user.name;
    if (customer == null) {
      this.error = 'Could not find a selected customer';
    }
    if (!this.checkout.extra.oh) {
      this.checkout.extra.oh = {};
    }

    if (!this.checkout.extra.procargs) {
      this.checkout.extra.procargs = {};
    }

    this.checkout.extra._flow = flow;

    this.checkout.extra.confirmationEmails = this.checkout.extra.confirmationEmails || [];

    if (this.userEmailConfirmation) {
      if (this.checkout.extra.confirmationEmails.indexOf(user.email) === -1) {
        this.checkout.extra.confirmationEmails.push(user.email);
      }
    }

    this.checkout.extra.procargs.levsattkod = customer.extra.kus.levsattkod;
    this.checkout.extra.procargs.levvillkkod = customer.extra.kus.levvillkkod;
    this.checkout.extra.oh.betkod = customer.extra.kus.betkod;
    this.checkout.extra.procargs.saljare = customer.extra.kus.saljare;
    this.checkout.deliveryCustomerId = customer.id;
    if (!customer.extra.kus.kundbetalarenr) {
      this.checkout.billingCustomerId = customer.extra.kus.id;
    } else {
      this.checkout.billingCustomerId = customer.extra.kus.kundbetalarenr;
    }

    for (const cartRow of cartRows) {
      const pToAdd: CheckoutRow = {
        extra: cartRow.extra,
        id: cartRow.productId,
        quantity: cartRow.qty,
        targetStockId: cartRow.targetStockId
      };
      this.checkout.rows.push(pToAdd);
    }
  }

  protected setDeliveryAddress(deliveryAddress: JeevesOrderDeliveryAddress) {
    if (!this.checkout.extra.procargs) {
      this.checkout.extra.procargs = {};
    }

    this.checkout.extra.procargs = { ...this.checkout.extra.procargs, ...deliveryAddress.addressValues };

    /* deliveryAddress.addressValues */
    /* this.checkout.extra.procargs = { ...this.checkout.extra.procargs, 
      ordlevadr1: deliveryAddress.addressValues?.ordlevadr1 || '',
      ordlevadr2: deliveryAddress.addressValues?.ordlevadr2 || '',
      ordlevadr3:  deliveryAddress.addressValues?.ordlevadr3 || '',
      ordlevadr4: deliveryAddress.addressValues?.ftgpostnr || '',
      ftgpostnr: deliveryAddress.addressValues?.ftgpostnr || '',
      ordlevadrlandskod: deliveryAddress.addressValues?.ordlevadrlandskod || '',
      ordlevplats1: null
    }; */
  }

  sendOrder() {
    if (!this.kundbestnr || (this.kundbestnr && this.kundbestnr.length === 0)) {
      return;
    }
    this.checkout.extra.oh.kundref2 = this.kundref2;
    this.checkout.extra.oh.kundbestnr = this.kundbestnr;
    this.checkout.extra.oh.editext = this.edit;
    if (this.checkout.rows.length === 0) {
      // Empty rows card
      this.gungModalService
        .openConfirmYesNoModal('ERROR', 'ERROR_SUBMIT_ORDER_EMPTY_CART', { size: 'sm' }, 'OK', null)
        .then();
      return;
    }
    if (this.isDemoAccount) {
      this.demoSubmitOrder();
      return;
    }
    this.submitting = true;
    this.checkoutConfig
      .getSubmitUrl(this.checkout)
      .pipe(switchMap(url => this.orderSubmitService.postOrder(url, this.checkout)))
      .subscribe(
        response => {
          this.cartKeyService
            .getCurrentCartKey()
            .pipe(first())
            .subscribe(key => {
              localStorage.removeItem(key);
              this.cartService.clearCart();
              this.checkoutConfig.onOrderSubmitted(response);
            });
        },
        err => {
          this.checkoutConfig
            .getShowOrderSubmitError()
            .pipe(first())
            .subscribe(show => {
              if (!!show) {
                this.submitting = false;
              }
            });
          console.error('ERROR POSTING ORDER', err.error);
        }
      );
  }

  demoSubmitOrder() {
    this.cartKeyService.getCurrentCartKey().pipe(first()).subscribe(key => {
      localStorage.removeItem(key);
      this.cartService.clearCart();
      this.router.navigate(['/demo/checkout/']);
    });
  }
}
