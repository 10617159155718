import { Component } from '@angular/core';
import { OrderListComponent } from 'gung-standard';

@Component({
  selector: 'otto-olsen-order-list',
  template: `<h5 class="d-block d-md-none page-title text-center mt-0">Tidligere ordrer</h5>
            <lib-filter-list #orderList [configService]="orderListConfigService"></lib-filter-list>
            <otto-olsen-scanner-footer-menu />`,
  styleUrl: './otto-olsen-order-list.component.scss'
})
export class OttoOlsenOrderListComponent extends OrderListComponent {

}
