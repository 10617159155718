import { Injectable } from '@angular/core';
import { HomeNavigationConfigService } from 'gung-standard';

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenHomeNavigationConfigService extends HomeNavigationConfigService {
  multiCustomersUserLink = ['/home'];
  singleCustomerUserLink = ['/home'];
  anonymousLink = ['/home'];
  adminLink = ['/home'];
  salesLink = ['/home'];
  supplierBuyerLink = ['/home'];
  public dynamicCustomPageByRoles = true;
}
