<div class="container" *ngIf="processing && !error">
  <div
    class="progress-bar progress-bar-striped progress-bar-animated bg-primary"
    role="progressbar"
    aria-valuenow="75"
    aria-valuemin="0"
    aria-valuemax="100"
    style="width: 75%"
  ></div>
  <h2>{{ 'SUBMITTING_ORDER' | translate }}</h2>
</div>

<div class="container" *ngIf="!!order && !error">
  <div class="row">
    <div class="col-12 col-md-7 d-flex justify-content-center">
      <div class="card outline" style="width: 40rem">
        <div class="card-body">
          <h5 class="card-title text-center">{{ 'RECEIPT' | translate }}</h5>
          <h6 class="card-subtitle mb-2 text-muted text-center">
            <span> {{ 'PAYMENT_REFERENCE' | translate }}</span
            >: {{ order.pspReference }}
          </h6>
          <hr />
          <div class="table-responsive">
            <table class="table table-borderless">
              <thead>
                <tr>
                  <th scope="col" class="text-center">{{ 'ID' | translate }}</th>
                  <th scope="col">{{ 'NAME' | translate }}</th>
                  <th scope="col" class="text-center">{{ 'QUANTITY' | translate }}</th>
                  <th scope="col" class="text-center">{{ 'UNIT_PRICE' | translate }}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let line of order.lineItems">
                  <th scope="row" class="text-center">{{ line.id }}</th>
                  <td>{{ products[line.id].name }}</td>
                  <td class="text-center">{{ line.quantity }}</td>
                  <td class="text-center">{{ (line.amountIncludingTax || line.amountExcludingTax) / 100 }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <hr />
          <div class="d-flex justify-content-between">
            <div>
              <span> {{ 'TOTAL' | translate }}</span
              >:
            </div>

            <span>
              <lib-price [price]="order.amount.value / 100" [currency]="order.amount.currency"></lib-price>
            </span>
          </div>
          <div class="mt-5 text-center">
            <span> {{ 'COPY_RECEIPT_TO_YOUR_EMAIL' | translate }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-1 vertical-line"></div>
    <div class="col-12 col-md-4 mb-4 d-flex flex-column justify-content-center align-items-center">
      <h2 class="text-center">
        <span> {{ 'THANK_YOU' | translate }}</span
        >!
      </h2>
      <p class="text-center">
        <span>{{ 'YOUR_ORDER_NUMBER' | translate }}</span
        >:
        <span *ngFor="let orderId of order.orderIds; let i = index">
          <a [href]="'orders/' + orderId" target="_blank">{{ orderId }}</a
          ><span *ngIf="i < order.orderIds.length - 1">, </span>
        </span>
      </p>
      <!-- <p class="text-center" translate>THANK_YOU_EMAIL_CONFIRMATION</p> -->
    </div>
  </div>
</div>
<div class="container" *ngIf="error">
  <div class="mb-4">
    <p class="text-center">ERROR_OCCURED</p>
    <p class="text-center">{{ error.error?.message }}</p>
  </div>
</div>
