<div *ngFor="let order of data" class="card mb-2 border">
  <div class="card-body">
    <!-- <div class="card-title row">
            <div class="col-auto ml-auto">
                <lib-action-btn [item]="data" [config]="configService"></lib-action-btn>
            </div>
        </div> -->
    <div class="row">
      <div class="col-7">
        <div class="form-group">
          <span class="white-space-nowrap">
            <label translate>ORDER_NUMBER</label>:&nbsp;
          </span>
          <span class="text-truncate">
            {{order.id}}
          </span>
        </div>
        <div class="form-group">
          <span class="white-space-nowrap">
            <label translate>TOTAL</label>:&nbsp;
          </span>
          <span class="text-truncate">
            <lib-price
              [price]="order.extra.oh.ohvbordsum"
              [currency]="order.extra.oh.valkod" />
          </span>
        </div>
      </div>
      <div class="col-5 d-flex align-items-center justify-content-end">
        <a [routerLink]="'/orders/' + order.id" (click)="orderNavigationService.setOrderNavigation(order.id, getOrderIds(data))">
          <button type="button" class="btn btn-primary" translate>SHOW_DETAILS</button>
        </a>
      </div>
    </div>

    <div class="row">
      <div class="col-4">
        <div class="form-group">
          <span class="d-block white-space-nowrap">
            <label translate>ORDER_DATE</label>:&nbsp;
          </span>
          <span class="d-block text-truncate">
            {{order.extra.oh.orddatum | date: dateUtilService.dateFormat}}
          </span>
        </div>
      </div>
      <div class="col-4">
        <div class="form-group">
          <span class="d-block white-space-nowrap">
            <label translate>EXPECTED_DELIVERY_DATE</label>:&nbsp;
          </span>
          <span class="d-block text-truncate">
            {{order.extra.oh.ordberlevdat | date: dateUtilService.dateFormat}}
          </span>
        </div>
      </div>
      <div class="col-4">
        <div class="form-group">
          <span class="d-block white-space-nowrap">
            <label translate>ORDER_STATUS</label>:&nbsp;
          </span>
          <span class="d-block text-truncate">
            <lib-gung-meta-output
              [value]="order.extra.oh.ordstat"
              [gungTranslate]="'ORDER_STATUS'"
              suppressLabel="true"
              metadataTable="x7"
              metaOptionName="ordstatbeskr" />
          </span>
        </div>
        
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <div class="form-group">
          <span class="d-block white-space-nowrap">
            <label translate>NAME</label>:&nbsp;
          </span>
          <span class="d-block text-truncate" title="{{order.extra.oh.ordlevadr1 ||
            (order.extra.deliveryCustomer ? order.extra.deliveryCustomer.name : order.extra.customerName)}}">
            {{order.extra.oh.ordlevadr1 ||
              (order.extra.deliveryCustomer ? order.extra.deliveryCustomer.name : order.extra.customerName)}}
          </span>
        </div>
      </div>
      <div class="col-4">
        <div class="form-group">
          <span class="d-block white-space-nowrap">
            <label translate>YOUR_REFERENCE</label>:&nbsp;
          </span>
          <span class="d-block text-truncate" title="{{order.extra.oh.kundref2}}">
            {{order.extra.oh.kundref2}}
          </span>
        </div>
      </div>
      <div class="col-4">
        <div class="form-group">
          <span class="d-block white-space-nowrap">
            <label translate>PO_NUMBER</label>:&nbsp;
          </span>
          <span class="d-block text-truncate">
            {{order.extra.oh.kundbestnr}}
          </span>
        </div>
      </div>
    </div>
    @if(order.extra.deliveries) {
      <div class="row my-2">
        <div class="col-1">
          <i class="fa-solid fa-truck text-primary fa-xl"></i>
        </div>
        <div class="col-11">
          @for(delivery of order.extra.deliveries; track delivery.fraktsendnummer) {
            @if(delivery.fraktsendnummer) {
              <a
                target="_blank"
                href="https://sporing.bring.no/sporing/{{ delivery.fraktsendnummer }}?lang=no"
              >
                <button type="button" class="btn btn-link text-primary p-0">
                  {{delivery.fraktsendnummer}}
                </button>
              </a>
            }
          }
        </div>
      </div>
      <div class="row my-2">
        <div class="col-1">
          <i class="fa-solid fa-clipboard text-primary fa-xl"></i>
        </div>
        <div class="col-11">
          @for(delivery of order.extra.deliveries; track delivery.fraktsendnummer) {
            @if(delivery.ordlevanvisnrad2) {
              <a>
                <button type="button" class="btn btn-link text-primary p-0"
                  (click)="$event.stopPropagation(); checkDeliveryNote(delivery.ordernr, delivery.ordlevanvisnrad2, $event)">
                  {{delivery.ordlevanvisnrad2}}
                </button>
              </a>
            }
          }
        </div>
      </div>
      @if(order.extra.oh.q_jis_coc) {
        <div class="row my-2">
          <div class="col-1">
            <i class="fa-solid fa-file-pdf text-primary fa-xl"></i>
          </div>
          <div class="col-11">
            @for(delivery of order.extra.deliveries.filter; track delivery.fraktsendnummer) {
              @if(order.extra.oh.q_jis_coc && delivery.ordlevanvisnrad2) {
                <a>
                  <button type="button" class="btn btn-link text-primary p-0"
                    (click)="$event.stopPropagation(); checkCoc(delivery.ordernr, delivery.ordlevanvisnrad2, $event)">
                    {{ 'COC' | translate }}
                  </button>
                </a>
              }
            }
          </div>
        </div>
      }
    }
  </div>
</div>
