import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService, CreateUserModalComponent, Customer, CustomerService, PasswordPolicyService, UsersService, BackendFeatureService, ConfigurationsService } from 'gung-standard';
import { first } from 'rxjs';

@Component({
  selector: 'otto-olsen-create-user-modal',
  templateUrl: './otto-olsen-create-user-modal.component.html',
  styleUrls: ['./otto-olsen-create-user-modal.component.scss']
})
export class OttoOlsenCreateUserModalComponent extends CreateUserModalComponent implements OnInit {
  @Input()
  customer?: Customer;

  defaultManagedMultistockIds = ['30'];
  userFromCustomer = false;

  isSales = false;
  // blockedCustomersIds = ['7060895', '7060850', '7190912', '7190944', '7120129', '602159', '7000047', '7210770', '7110308', '7150610'];
  blockedCustomersIds = [];
  isBlocked = false;

  constructor(
    protected formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    protected usersService: UsersService,
    protected backendFeatureService: BackendFeatureService,
    protected passwordPolicyService: PasswordPolicyService,
    protected authService: AuthService,
    protected customerService: CustomerService,
    protected configurationsService: ConfigurationsService
  ) {
    super(formBuilder, activeModal, usersService, backendFeatureService, passwordPolicyService, configurationsService);
  }

  ngOnInit(): void {
    this.authService
      .getCurrentUser()
      .pipe(first())
      .subscribe(currentUser => {
        if (currentUser.roles.includes('SALES') && !currentUser.roles.includes('ACTUATOR')) {
          this.isSales = true;
        }
      });
    super.ngOnInit();
  }

  checkBlockedCustomersIds(managedCompanyIdsInput: string) {
    const ids = (this.createUserFrom && this.createUserFrom.managedCompanyIds) || managedCompanyIdsInput;
    if (this.isSales) {
      if (Array.isArray(ids)) {
        this.isBlocked = !!ids.find(id => this.blockedCustomersIds.includes(id));
      } else {
        this.isBlocked = this.blockedCustomersIds.includes(ids);
      }
      if (!this.isBlocked && this.customer) {
        this.customerService.getCustomer(this.customer.id || managedCompanyIdsInput).subscribe(customer => {
          this.isBlocked =
            !!(
              customer.extra._billingCustomer?.extra?.fr?.q_konkurrent_b2b &&
              Number(customer.extra._billingCustomer.extra.fr.q_konkurrent_b2b)
            ) || this.isBlocked;
        });
      }
    }
  }

  initForm(assortmentInput: string, managedCompanyIdsInput: string) {
    this.checkBlockedCustomersIds(managedCompanyIdsInput);
    if (this.userFromCustomer) {
      const ids = (this.createUserFrom && this.createUserFrom.managedCompanyIds) || managedCompanyIdsInput;
      const assortment = (this.createUserFrom && this.createUserFrom.assortment) || '';
      this.userForm = this.formBuilder.group({
        username: new FormControl({ value: '', disabled: true }, Validators.required),
        name: this.formBuilder.control('', [Validators.required]),
        email: this.formBuilder.control('', [Validators.required, Validators.email]),
        phone: this.formBuilder.control('', [Validators.required, Validators.pattern('^[0-9]*$')]),
        password: this.formBuilder.control(''),
        managedCompanyIds: new FormControl({ value: ids, disabled: true }, Validators.required),
        managedMultistockIds: new FormControl(
          { value: this.defaultManagedMultistockIds, disabled: true },
          Validators.required
        ),
        assortment: [{ value: assortment, disabled: true }]
      });
      this.userForm.controls.email.valueChanges.subscribe(change => {
        this.userForm.controls.username.setValue(change);
      });
    } else {
      super.initForm(assortmentInput, managedCompanyIdsInput);
    }
    if (this.isBlocked) {
      this.userForm.disable();
    }
  }

  onAdd() {
    this.alertMessage = undefined;
    const invalidFields = [];
    let error = false;
    for (const key of Object.keys(this.userForm.controls)) {
      const field = this.userForm.controls[key];
      if (field.status === 'INVALID') {
        invalidFields.push(key.toUpperCase());
        error = true;
      }
    }
    if (error) {
      this.alertMessage = {
        type: 'danger',
        message: 'FORM_CONTAINS_ERROR',
        invalidFields
      };
      return;
    }
    this.loading = true;

    const userForm = {
      ...this.userToCopyFrom,
      ...this.userForm.getRawValue(),
      managedCompanyIds: [this.userForm.getRawValue().managedCompanyIds]
    };

    this.usersService.createUser(userForm).subscribe(
      user => {
        this.loading = false;
        this.userForm.reset();
        this.activeModal.close(userForm);
      },
      errors => {
        this.loading = false;
        this.alertMessage = {
          type: 'danger',
          message: errors.error.message
        };
      }
    );
  }
}
