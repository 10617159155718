import { Injectable } from '@angular/core';
import { CustomPageResolverService } from 'gung-standard';
import { OttoOlsenHomeAssortmentNewTemplateComponent } from '../components/otto-olsen-home-assortment-new-template/otto-olsen-home-assortment-new-template.component';

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenCustomPageResolverService extends CustomPageResolverService {

  public getLayouts(): { name; componentDisplay }[] {
    super.getLayouts();
    const layouts = super.getLayouts();
    if (layouts.findIndex(l => l.name === 'homeassortment') > -1) {
      layouts[layouts.findIndex(l => l.name === 'homeassortment')].componentDisplay = OttoOlsenHomeAssortmentNewTemplateComponent;
    }
    return layouts;
  }
}
