import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AvailabilityModalComponent } from 'gung-standard';

@Component({
  selector: 'otto-olsen-availability-modal',
  templateUrl: './otto-olsen-availability-modal.component.html',
  styleUrls: ['./otto-olsen-availability-modal.component.scss']
})
export class OttoOlsenAvailabilityModalComponent extends AvailabilityModalComponent {

}
