import { DatePipe } from '@angular/common';
import { Component, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { isEqual, parse } from 'date-fns';
import {
  AuthService,
  PriceService,
  AvailabilityService,
  Availability,
  TableRecord,
  Customer,
  SelectedCustomerService,
  MetadataService,
  DeliveryDateService,
  CalendarDate,
  CartService,
  CartRow,
  Product,
  CartRowPrice,
  ProductService,
  PriceConfigService
} from 'gung-standard';
import { OrderTermsJeevesComponent } from 'gung-standard-jeeves';
import { Subscription, forkJoin, Observable, Subject, first, map, tap, takeUntil, mergeMap, of } from 'rxjs';
import {
  OttoOlsenFreightCostService,
  OttoOlsenFreightCondition
} from '../../../../services/otto-olsen-freight-cost.service';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { format } from 'date-fns';
import { DateUtilService } from 'gung-common';
import { environment } from './../../../../../environments/environment';
import { JeevesUpdateKuarModalComponent } from 'gung-standard-jeeves';
import { GungModalService, mergeDeep } from 'gung-standard';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductExtended } from '../../../otto-olsen-product-details/otto-olsen-product-details.component';
import { ProductTableRowExtended } from '../../../otto-olsen-product-pagination-list-card/otto-olsen-product-pagination-list-card.component';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'gung-standard/lib/state/auth/types';
import { OttoOlsenCheckoutConfigService } from '../../../../services/otto-olsen-checkout-config.service';
import { fnProductName } from '../../../../pipes/product-name.pipe';

export const getMinDate = (av: Availability, quantity: number): Date => {
  const nowDate = new Date(new Date().setHours(0, 0, 0, 0));
  if (av.currentAvailability > 0) {
    return nowDate;
  }
  if (av.maxFutureStock === 0) {
    return nowDate;
  }
  const valids = Object.keys(av.availabilities).filter(entry => av.availabilities[entry] >= quantity);
  const dateFormat = 'yyMMdd';
  return valids.length > 0 ? parse(valids[0], dateFormat, nowDate) : nowDate;
};

const getMinDateSuper = (av: Availability): Date => {
  const nowDate = new Date(new Date().setHours(0, 0, 0, 0));
  if (av.currentAvailability > 0) {
    return nowDate;
  }
  if (av.maxFutureStock === 0) {
    return nowDate;
  }
  const valids = Object.keys(av.availabilities).filter(entry => av.availabilities[entry] > 0);
  const dateFormat = 'yyMMdd';
  return valids.length > 0 ? parse(valids[0], dateFormat, nowDate) : nowDate;
};

enum DiscountType {
  PERCENTAGE,
  OVERRIDE
}
export interface JeevesSalesCartListRow {
  originalPrice: number;
  name: string;
  productId: string;
  productPartialId?: string;
  targetStockId?: string;
  discountPercent?: number;
  discountPercent2?: number;
  description?: string;
  overridePrice?: number;
  deliveryDate?: string;
  minDate: Date;
  quantity: number;
  currency: string;
  cartRowPriceElement: CartRowPrice;
  deliveryMethod: string;
  cartRow: CartRow;
  currentAvailability?: number;
  product: Product;
  price: CartRowPrice;
}

export interface JeevesSalesCartListRowExtended extends JeevesSalesCartListRow {
  product: ProductExtended;
}

@Component({
  selector: 'otto-olsen-order-terms-jeeves-with-cart',
  templateUrl: './otto-olsen-order-terms-jeeves-with-cart.component.html',
  styleUrls: ['./otto-olsen-order-terms-jeeves-with-cart.component.scss']
})
export class OttoOlsenOrderTermsJeevesWithCartComponent extends OrderTermsJeevesComponent implements OnInit, OnChanges, OnDestroy {
  unsubscribe: Subject<boolean> = new Subject<boolean>();
  partDeliveryEntries: { id: string; name: string }[] = [];
  selectedCustomer: Customer;
  pushDeliveryDate = false;
  dates: CalendarDate[];
  isUser: boolean;
  isAnonymous: boolean;
  public hidePrice: boolean;

  freightRow: CartRow;
  isFreight = false;
  noFreight = false;
  cartRows: CartRow[];
  showFreeShippingAlert = false;
  freeShippingMessage = '';

  deliveryMethod22 = false;

  selectedDeliveryDate: string;
  selectedDeliveryMethod: string;
  public partDeliveriesForbidden = false;

  currentUser: User;

  isFirstLoad: boolean = true;
  wasClicked = false;

  constructor(
    protected cartService: CartService,
    protected authService: AuthService,
    protected formBuilder: FormBuilder,
    protected datePipe: DatePipe,
    protected priceService: PriceService,
    protected availabilityService: AvailabilityService,
    private selectedCustomerService: SelectedCustomerService,
    private metadataService: MetadataService,
    protected deliveryDateService: DeliveryDateService,
    private freightCostService: OttoOlsenFreightCostService,
    protected productService: ProductService,
    public dateUtilService: DateUtilService,
    private gungModalService: GungModalService,
    protected modalService: NgbModal,
    protected translationService: TranslateService,
    protected priceConfigService: PriceConfigService,
    protected checkoutConfig: OttoOlsenCheckoutConfigService
  ) {
    super(authService, formBuilder, datePipe, availabilityService);
    this.priceConfigService.hidePrice
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(hidePrice => (this.hidePrice = hidePrice));
  }

  ngOnInit() {
    // super.ngOnInit();
    this.onNextBtnClicked.subscribe(_ => this.handleNextButtonClicked2());

    forkJoin([
      this.removeExtras().pipe(first()),
      this.selectedCustomerService.getSelectedCustomer().pipe(first()),
      this.authService.getRoles().pipe(first()),
      this.authService.getCurrentUser().pipe(first())
    ]).subscribe(([cartRows, customer, roles, currentUser]) => {
      this.selectedCustomer = customer;
      this.isAnonymous = roles.includes('ANONYMOUS');
      this.isUser = roles.includes('USER') && !roles.includes('SALES') && !roles.includes('ADMIN');
      this.cartRows = JSON.parse(JSON.stringify(cartRows));
      this.currentUser = currentUser;
      this.initStepData();
    });

    if (!this.checkoutConfig.hasUnsavedChanges) {
      this.form.valueChanges.subscribe(() => {
        this.checkoutConfig.hasUnsavedChanges = true;
      })
    }
  }

  removeExtras(): Observable<CartRow[]> {
    return this.cartService.getCurrentCart().pipe(
      first(),
      map(cartRows => {
        const crs: CartRow[] = [];
        if (this.checkout?.extra?._extraRows) {
          for (const row of this.checkout.extra._extraRows) {
            const cartRow = cartRows.find(cr => cr.productId === row.id);
            if (cartRow) {
              crs.push(cartRow);
            }
            this.warningWasActiveBefore[row.productId] = false;
            row.extra = mergeDeep(row.extra, { warning: false });
          }
          this.cartService.bulkRemoveRows(crs);
          delete this.checkout.extra._extraRows;
        }
        return cartRows.filter(
          cr => !crs.map(c => c.productId + '_' + c.productPartialId).includes(cr.productId + '_' + cr.productPartialId)
        );
      })
    );
  }

  ngOnChanges() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.unsubscribe.next(false);
    this.unsubscribe.complete();

    this.updateData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.unsubscribe.next(false);
    this.unsubscribe.complete();
  }

  initStepData() {
    this.initForm();
    this.initPartialDeliveries();
    forkJoin([
      this.availabilityService
        .getAvailabilities(
          this.cartRows.map(r => r.productId),
          undefined,
          true
        )
        .pipe(first()),
      this.deliveryDateService.getDeliveryDates(this.form.get('levsattkod').value).pipe(first()),
      this.freightCostService.calculateFreight().pipe(first())
    ]).subscribe(([avs, dates, freightCondition]) => {
      this.initDeliveryDatesBasedOnDeliveryMethod(avs, dates);
      this.calculateFreight(freightCondition);

      this.cartService
        .getCurrentCart()
        .pipe(
          takeUntil(this.unsubscribe),
          map(cartRows => {
            this.cartRows = JSON.parse(JSON.stringify(cartRows));
          })
        )
        .subscribe(data => {
          this.updateData();
        });
    });
  }

  public initDeliveryDatesBasedOnDeliveryMethod(avs: Availability[], dates: CalendarDate[]) {
    this.dates = dates;

    // Calculate delivery date when delivery method changes
    this.checkPushDeliveryDate(this.form.get('levsattkod').value);
    this.form.get('levsattkod').valueChanges.subscribe(value => {
      this.checkPushDeliveryDate(value);
      this.handlePartDeliveryChange();
    });
    if (this.checkout.extra.oh.dellevtillaten !== '10') {
      this.assignRowDates(avs);
    }

    avs = avs.map(av => ({ ...av, availabilities: av.extra.availabilities }));
    const nowDate = new Date(new Date().setHours(0, 0, 0, 0));
    let minDate = nowDate;
    for (const row of this.cartRows) {
      const av = avs.find(a => a.productId === row.productId);
      if (av) {
        const tempDate = getMinDateSuper(av);
        if (minDate < tempDate) {
          minDate = tempDate;
        }
      }
      // if (row.extra.orp.ordberlevdat) {
      //   const tempDate = new Date(row.extra.orp.ordberlevdat);
      //   if (minDate < tempDate) {
      //     minDate = tempDate;
      //   }
      // }
    }
    // If now date is today and q_jis_cutoff_hour of delivery method is already past
    if (this.pushDeliveryDate && nowDate.getTime() === minDate.getTime()) {
      minDate.setDate(minDate.getDate() + 1);
      //    minDate = this.setMinDate();
    }
    this.minDate = minDate;
    this.loadingComponent = false;
  }

  calculateFreight(freightCondition: OttoOlsenFreightCondition) {
    if (freightCondition.deliveryTerms) {
      this.form.get('levvillkkod').setValue(freightCondition.deliveryTerms);
      this.checkout.extra.procargs.levvillkkod = freightCondition.deliveryTerms;
    }
    this.isFreight = freightCondition.customerApplyFreight && freightCondition.totalValue < 500;
    this.deliveryMethod22 = '' + this.checkout.extra.procargs?.levsattkod === '22';
    if ('' + this.checkout.extra.procargs?.levsattkod === '22') {
      // this.noFreight = true;
    }
  }

  private handleNextButtonClicked2(): void {
    if (this.form.invalid || this.loadingComponent) {
      console.log(this.form.invalid);
      return;
    }

    this.checkout.extra.applyFreight = this.isFreight && !this.noFreight;

    this.assignFormValues(this.form);
    this.stepDone.emit(this.checkout);
  }

  protected initForm() {
    this.form = this.formBuilder.group({
      dellevtillaten: this.formBuilder.control(this.checkout.extra.oh.dellevtillaten || '0'),
      levsattkod: this.formBuilder.control('' + this.checkout.extra.procargs.levsattkod || '', [Validators.required]),
      levvillkkod: this.formBuilder.control({
        value: '' + this.checkout.extra.procargs.levvillkkod || '',
        disabled: this.isUser
      }),
      betkod: this.formBuilder.control(this.checkout.extra.oh.betkod || ''),
      saljare: this.formBuilder.control(this.checkout.extra.procargs.saljare || ''),
      ordstat: this.formBuilder.control(this.checkout.extra.oh.ordstat || 13)
    });

    if (this.form.get('levsattkod').value) {
      // this.onDeliveryMethodChanged(this.checkout.extra.procargs.levsattkod);
      this.selectedDeliveryMethod = this.form.get('levsattkod').value;
    }
  }

  protected assignFormValues(formGroup: FormGroup) {
    super.assignFormValues(formGroup);
    const rawValues = formGroup.getRawValue();
    this.checkout.extra = {
      ...this.checkout.extra,
      oh: {
        ...this.checkout.extra.oh,
        ordstat: rawValues.ordstat
      }
    };

    if (this.checkout.extra.oh.ordstat + '' === '0') {
      this.checkout.extra.procargs.ordtyp = '301';
    } else {
      this.checkout.extra.procargs.ordtyp = '1';
    }
  }

  checkPushDeliveryDate(levsattkod: string) {
    // CHECK IF CURRENT TIME AFTER 16H = ALWAYS PUSH NEXT DAY
    if (new Date().getHours() >= 16) {
      this.pushDeliveryDate = true;
      return;
    }
    let cutoffHour = this.metadataService.getMetadataValue('x2f', 'q_jis_cutoff_hour', levsattkod);
    /*
    The hardcoded value change below was made since we needed it for a demo within an hour. There was no time to change
    the table in jeeves, however it would be better to update in jeeves and then remove this hardcoded override of the
    cut off hour.
     */
    if (!cutoffHour && levsattkod === '22') {
      cutoffHour = '15';
    }
    if (cutoffHour && new Date().getHours() >= Number(cutoffHour)) {
      this.pushDeliveryDate = true;
    } else {
      this.pushDeliveryDate = false;
    }
  }

  setMinDate(): Date {
    const nowDate = new Date();
    nowDate.setDate(nowDate.getDate() + 1);
    const firstDateAvailable = this.deliveryDateService.findFirstAvailableDate(this.dates, nowDate);
    this.minDate = firstDateAvailable.date;
    return firstDateAvailable.date;
  }

  private initPartialDeliveries(): void {
    this.partDeliveryEntries = [
      { id: '0', name: 'OH_DELLEVTILLATEN_0' },
      { id: '10', name: 'OH_DELLEVTILLATEN_10' }
    ];
  }

  handlePartDeliveryChange(): void {
    this.loadingComponent = true;
    const partDeliveryFormControlName = 'dellevtillaten';
    const selectedPArtDeliveryValue = this.form.controls[partDeliveryFormControlName].value || '';
    this.checkout.extra.oh.dellevtillaten = selectedPArtDeliveryValue;
    this.availabilityService
      .getAvailabilities(
        this.cartRows.map(r => r.productId),
        undefined,
        true
      )
      .pipe(map(avs => avs.map(av => ({ ...av, availabilities: av.extra.availabilities }))))
      .subscribe(avs => {
        if (this.checkout.extra.oh.dellevtillaten === '0') {
          this.partDeliveriesForbidden = false;
          delete this.checkout.extra.oh.ordberlevdat;
          this.selectedDeliveryDate = null;
          this.assignRowDates(avs);
        } else {
          this.partDeliveriesForbidden = true;
          delete this.minDate;
          const nowDate = new Date();
          let minDateFromAvailability = nowDate;

          // let maxAssignedDate = '1970-01-01';
          for (const row of this.cartRows) {
            const av = avs.find(a => a.productId === row.productId);
            if (av) {
              const tempDate = getMinDate(av, row.qty);
              if (minDateFromAvailability < tempDate) {
                minDateFromAvailability = tempDate;
              }
            }
            // if (row.extra._user_selected_ordberlevdat && row.extra._user_selected_ordberlevdat > maxAssignedDate) {
            //   maxAssignedDate = row.extra._user_selected_ordberlevdat;
            // }
          }
          // If now date is today and q_jis_cutoff_hour of delivery method is already past
          if (this.pushDeliveryDate && nowDate.getTime() === minDateFromAvailability.getTime()) {
            minDateFromAvailability.setDate(minDateFromAvailability.getDate() + 1);
            minDateFromAvailability = this.setMinDate();
          }
          this.minDate = minDateFromAvailability;

          for (const testDate of this.dates) {
            if (testDate.date >= this.minDate) {
              if (testDate.valid) {
                this.minDate = testDate.date;
                break;
              }
            }
          }

          const dString: string = this.datePipe.transform(this.minDate, 'yyyy-MM-dd');

          this.checkout.extra.oh.ordberlevdat = dString;
          this.selectedDeliveryDate = dString;

          for (const row of this.cartRows) {
            this.cartService.removeExtraField(
              row.productId,
              'orp.ordberlevdat',
              row.targetStockId,
              row.productPartialId
            );
          }
          // if (dString > maxAssignedDate) {
          //   this.checkout.extra.oh.ordberlevdat = dString;
          // } else {
          //   this.checkout.extra.oh.ordberlevdat = maxAssignedDate;
          //   this.minDate = parse(maxAssignedDate, 'yyyy-MM-dd', this.minDate);
          // }
          this.createFullDeliveryPlan();
          this.loadingComponent = false;
        }
      });
  }

  assignRowDates(avs: Availability[]): void {
    avs = avs.map(av => ({ ...av, availabilities: av.extra.availabilities }));
    for (const row of this.cartRows) {
      const av = avs.find(a => a.productId === row.productId);
      // delete row.extra._availability_reference;
      // row.extra._availability_reference = Object.assign([], row.extra._availability_reference);
      // row.extra = Object.assign([], row.extra);
      // row.extra._availability_reference = Object.assign({}, av);

      // row.extra._availability_reference = av;
      let mindate = null;
      if (av) {
        const tempDate = getMinDate(av, row.qty);
        const nowDate = new Date();
        let minDate = nowDate;
        if (minDate < tempDate) {
          minDate = tempDate;
        }

        for (const testDate of this.dates) {
          const dString11: string = this.datePipe.transform(minDate, 'yyyy-MM-dd');
          const dString22: string = this.datePipe.transform(testDate.date, 'yyyy-MM-dd');

          // We cant include time when comparing
          if (dString22 >= dString11) {
            if (testDate.valid) {
              minDate = testDate.date;
              break;
            }
          }
        }

        // If now date is today and q_jis_cutoff_hour of delivery method is already past
        const dString1: string = this.datePipe.transform(nowDate, 'yyyy-MM-dd');
        const dString2: string = this.datePipe.transform(minDate, 'yyyy-MM-dd');

        if (this.pushDeliveryDate && dString1 === dString2) {
          const dStringBefore: string = this.datePipe.transform(minDate, 'yyyy-MM-dd');
          minDate.setDate(minDate.getDate() + 1);
          const dStringAfter: string = this.datePipe.transform(minDate, 'yyyy-MM-dd');
          // minDate = this.setMinDate();
        }

        const dString: string = this.datePipe.transform(minDate, 'yyyy-MM-dd');
        mindate = dString;
      }

      row.extra._calc_min_date = mindate;
      row.extra.orp = row.extra.orp || {};
      row.extra.orp.ordberlevdat = row.extra._calc_min_date;
      if (row.extra._user_selected_ordberlevdat) {
        if (new Date(row.extra._user_selected_ordberlevdat) > new Date(row.extra._calc_min_date)) {
          // row.extra.orp.ordberlevdat = row.extra._user_selected_ordberlevdat;
          // TODO: Check if the selected date is in the available dates in this delivery method
          // this.deliveryDateService.checkIfDateInDeliveryDates(new Date(row.extra._user_selected_ordberlevdat));
        }
      }

      const deliveryPlan = {};
      const dates = Object.keys(av.availabilities);
      let quantityRemaining = row.qty;
      for (const date of dates) {
        const qty = av.availabilities[date];
        if (qty > 0 && quantityRemaining > 0) {
          const parsedDate = parse(date, 'yyMMdd', new Date());
          const formattedDate = this.datePipe.transform(parsedDate, 'yyyy-MM-dd');

          let assignedDate = formattedDate;
          if (new Date(row.extra._user_selected_ordberlevdat) > new Date(assignedDate)) {
            assignedDate = row.extra._user_selected_ordberlevdat;
          }
          const currentOnDate = deliveryPlan[assignedDate] || 0;
          const currentAllocation = Math.min(qty, quantityRemaining);

          deliveryPlan[assignedDate] = currentOnDate + currentAllocation;

          quantityRemaining = quantityRemaining - currentAllocation;
        }
      }
      if (quantityRemaining > 0) {
        // Handle edge case of last quantities of expiring product
        deliveryPlan['2099-12-31'] = quantityRemaining;
      }
      row.extra._preliminaryDeliveryPlan = deliveryPlan;
    }

    for (const row of this.cartRows) {
      this.cartService.removeExtraField(
        row.productId,
        'dummy_element_just_to_trigger',
        row.targetStockId,
        row.productPartialId
      );
      break;
    }

    this.loadingComponent = false;
  }

  createFullDeliveryPlan() {
    for (const row of this.checkout.rows) {
      const deliveryPlan = {};
      deliveryPlan[this.checkout.extra.oh.ordberlevdat] = row.quantity;
      // This is important because used by backend to split lines
      row.extra._preliminaryDeliveryPlan = deliveryPlan;
    }
  }

  optionsFilterDeliveryMethod(customer: Customer, option: { key: string; value: string | TableRecord }): boolean {
    return (
      option.key === '2' ||
      option.key === '4' ||
      option.key === '22' ||
      option.key === '' + customer.extra.kus.levsattkod
    );
  }

  onDeliveryMethodChanged(event) {
    this.checkout.extra.procargs.levsattkod = event || this.selectedCustomer.extra.kus.levsattkod;
    if (event === '' + this.selectedCustomer.extra.kus.levsattkod) {
      this.form.get('levvillkkod').setValue(this.selectedCustomer.extra.kus.levvillkkod);
      this.noFreight = false;
    } else {
      this.form.get('levvillkkod').setValue('0');
      this.noFreight = false;
    }
    this.deliveryMethod22 = event === '22';
    if (event === '22') {
      // this.noFreight = true;
    }

    this.selectedDeliveryMethod = event;

    for (const row of this.mappedData) {
      row.deliveryDate = undefined;
    }

    forkJoin([
      this.availabilityService
        .getAvailabilities(
          this.cartRows.map(r => r.productId),
          undefined,
          true
        )
        .pipe(first()),
      this.deliveryDateService.getDeliveryDates(this.form.get('levsattkod').value).pipe(first())
    ]).subscribe(([avs, dates]) => {
      this.initDeliveryDatesBasedOnDeliveryMethod(avs, dates);
    });
  }

  /**
   * CART product listing
   */
  public cachedData: { [id: string]: { product: Product; price: CartRowPrice; availability: Availability } } = {};
  public subscriptions: Subscription[] = [];

  deliveryDate = new Date();

  warningWasActiveBefore = {};
  public requestPriceEmail = environment.requestPriceEmail;

  public mappedData: JeevesSalesCartListRowExtended[];

  enableDuplicateRow = false;
  firstTime = true;

  updateData() {
    const ids = this.cartRows.map(c => c.productId);
    const cachedIds = Object.keys(this.cachedData);
    const toGetIds = ids.filter(id => !cachedIds.includes(id));

    const sub = forkJoin({
      products: this.productService.getProductsByIds(toGetIds).pipe(first()),
      prices: this.priceService.getCartRowPricesObservable(this.cartRows).pipe(first()),
      customer: this.selectedCustomerService.getSelectedCustomer().pipe(first())
    })
      .pipe(
        first(),
        mergeMap(({ products, prices, customer }) => {
          const toGetIdsAv = structuredClone(toGetIds);
          for (const product of products) {
            if (this.isConfigureProduct(product)) {
              const idx = toGetIds.indexOf(product.id);
              if (idx > -1) {
                toGetIdsAv[idx] = product.extra.templateProductId;
              }
            }
          }
          return forkJoin({
            products: of(products),
            prices: of(prices),
            customer: of(customer),
            availabilities: this.availabilityService.getAvailabilities(toGetIdsAv, undefined, true).pipe(
              first(),
              map(avs => avs.map(av => ({ ...av, availabilities: av.extra.availabilities })))
            ),
          });
        }),
        mergeMap(({ products, prices, customer, availabilities }) => {
          const templateProductsIds = products?.filter(product => this.isConfigureProduct(product)).map(product => product.extra.templateProductId);
          return forkJoin({
            products: of(products),
            prices: of(prices),
            customer: of(customer),
            availabilities: of(availabilities),
            templateProducts: templateProductsIds?.length > 0 ? this.productService.getProductsByIds(templateProductsIds).pipe(first()) : of([])
          })
        }),
        tap(resp => {
          this.cachedData = {
            ...this.cachedData,
            ...toGetIds
              .map(id => {
                const product = resp.products.find(p => p.id === id);
                if (this.isConfigureProduct(product)) {
                  product.extra._templateProduct = resp.templateProducts?.find(p => p.id === product.extra.templateProductId)
                }
                return ({
                  product,
                  price: resp.prices.find(p => p.productId === id),
                  availability: resp.availabilities.find(av => av.productId === (!this.isConfigureProduct(product) ? id : product.extra.templateProductId))
                })
              })
              .reduce((acc, curr) => ({ ...acc, [curr.product.id]: curr }), {})
          };
        }),
        map(data => {
          const cachedProducts = Object.values(this.cachedData).map(d => d.product);
          const cachedPrices = data.prices;
          const cachedAvs = Object.values(this.cachedData).map(d => d.availability);
          return this.mapData(cachedProducts, cachedPrices, cachedAvs, this.cartRows, data.customer);
        })
      )
      .subscribe(d => this.updateMappedData(d));

    this.subscriptions.push(sub);
  }

  updateMappedData(d: JeevesSalesCartListRow[]) {
    this.mappedData = d;
    this.initShipping();
  }

  public trackByFn(index: number, item: JeevesSalesCartListRow) {
    return item.productId + '_' + item.productPartialId;
  }

  public removeRow(row: CartRow) {
    const index = this.mappedData.findIndex(
      mappedDataRow =>
        mappedDataRow.cartRow.productId === row.productId &&
        (!mappedDataRow.cartRow.productPartialId || mappedDataRow.cartRow.productPartialId === row.productPartialId) &&
        (!mappedDataRow.cartRow.targetStockId || mappedDataRow.cartRow.targetStockId === row.targetStockId)
    );
    if (index > -1) {
      this.mappedData.splice(index, 1);
      this.cartService.removeRow(row);
    }
  }

  public updateOverridePrice(row: JeevesSalesCartListRow, value: any) {
    const oldVal = row.overridePrice || row.originalPrice;

    if (oldVal === value) {
      return;
    }

    if (!value || isNaN(value)) {
      this.cartService.removeExtraField(row.productId, 'orp.vb_pris', row.targetStockId, row.productPartialId);
      return;
    }

    this.cartService.setExtra(
      row.productId,
      {
        orp: {
          vb_pris: parseFloat(value).toFixed(2)
        }
      },
      row.targetStockId,
      row.productPartialId
    );
  }

  public mapData(
    products: Product[],
    prices: CartRowPrice[],
    availabilities: Availability[],
    cartRows: CartRow[],
    customer: Customer
  ): JeevesSalesCartListRow[] {
    const returnValue = cartRows.map(cr => {
      const product: ProductExtended = products.find(p => p.id === cr.productId);
      const price = prices.find(
        p => p.productId === cr.productId && (!p.productPartialId || p.productPartialId === cr.productPartialId)
      );
      const av = availabilities.find(a => a.productId === (!this.isConfigureProduct(product) ? cr.productId : product.extra.templateProductId));
      const minDateJs: Date = this.getMinDate(av);
      if (
        product.extra.ar.q_jis_fast_pakke_strl &&
        product.extra.ar.artfsgforp &&
        Number(product.extra.ar.artfsgforp) > 0
      ) {
        (product as ProductExtended).packageSize = Number(product.extra.ar.artfsgforp);
      }
      // Product original size without cutting
      if (product.extra.ar?.artfsgforp && !isNaN(Number(product.extra.ar.artfsgforp))) {
        product.originalSize = Number(product.extra.ar.artfsgforp);
      }
      if (product.extra.ar?.q_salesbatchsize && !isNaN(Number(product.extra.ar.q_salesbatchsize))) {
        product.packageSize = Number(product.extra.ar.q_salesbatchsize);
      }
      if (product.extra.ar?.q_salesmoq && !isNaN(Number(product.extra.ar.q_salesmoq))) {
        product.firstStepAmount = Number(product.extra.ar.q_salesmoq);
      }
      cr.extra._availability_reference = av;
      const toReturn: JeevesSalesCartListRow = {
        productId: cr.productId,
        productPartialId: cr.productPartialId,
        name: fnProductName(product),
        quantity: cr.qty,
        originalPrice: price.customerGrossPrice.value,
        currency: price.customerNetPrice.currencyCode,
        // minDate: new NgbDate(minDateJsgetFullYear(), minDateJs.getMonth() + 1, minDateJs.getDate()),
        minDate: minDateJs,
        targetStockId: cr.targetStockId,
        cartRowPriceElement: price,
        deliveryMethod: customer.extra.kus.levsattkod,
        cartRow: cr,
        currentAvailability: av.currentAvailability,
        product,
        price
      };
      if (cr.extra && cr.extra.orp && cr.extra.orp.rabatt1) {
        toReturn.discountPercent = cr.extra.orp.rabatt1;
      }
      if (cr.extra && cr.extra.orp && cr.extra.orp.rabatt2) {
        toReturn.discountPercent2 = cr.extra.orp.rabatt2;
      }
      if (cr.extra && cr.extra.orp && cr.extra.orp.vb_pris) {
        toReturn.overridePrice = cr.extra.orp.vb_pris;
      }
      if (cr.extra && cr.extra.orp && cr.extra.orp.artbeskr) {
        toReturn.description = cr.extra.orp.artbeskr;
      }
      if (this.getDeliveryDateFromRow(cr)) {
        const setDate = new Date(this.getDeliveryDateFromRow(cr));
        toReturn.deliveryDate = format(setDate, 'yyyy-MM-dd');
      }

      //
      if (this.selectedDeliveryMethod) {
        toReturn.deliveryMethod = this.selectedDeliveryMethod;
      }
      if (!toReturn.cartRow.extra._calc_min_date) {
        // duplicateProduct

        toReturn.cartRow.extra._calc_min_date = cartRows.find(c => c.productId === cr.productId)?.extra?._calc_min_date;
      }
      this.qtyChangeSoChangeDate(toReturn);

      if (toReturn.cartRow.extra._calc_min_date) {
        const parsedDate = this.dateUtilService.parseDate(toReturn.cartRow.extra._calc_min_date, 'yyyy-MM-dd');
        // const nbgMinDate = new NgbDate(parsedDate.getFullYear(), parsedDate.getMonth() + 1, parsedDate.getDate());
        // toReturn.minDate = nbgMinDate;
        toReturn.minDate = parsedDate;
      }

      // return {
      //   ...cr,
      //   product
      // };
      return toReturn;
    });

    if (this.partDeliveriesForbidden) {
      this.qtyChangeSoChangeDateRows(returnValue);
    }

    return returnValue;
  }

  getMinDate(av: Availability): Date {
    const nowDate = new Date(new Date().setHours(0, 0, 0, 0));
    if (av.currentAvailability > 0) {
      return nowDate;
    }
    if (av.maxFutureStock === 0) {
      return nowDate;
    }
    const valids = Object.keys(av.availabilities).filter(entry => av.availabilities[entry] > 0);
    const dateFormat = 'yyMMdd';
    return valids.length > 0 ? parse(valids[0], dateFormat, nowDate) : nowDate;
  }

  public calculateTotal(row: JeevesSalesCartListRow): number {
    const discountPercent = row.discountPercent || row.cartRowPriceElement.cartRowDiscountPercent || 0;
    const price = row.overridePrice || row.originalPrice;
    return price * row.quantity * (1.0 - discountPercent / 100);
  }

  calculateTotalValue(): number {
    return this.mappedData.map(r => this.calculateTotal(r)).reduce((acc, curr) => acc + curr, 0);
  }

  parseFloatFixed(value, fixed = 2) {
    if (!isNaN(value)) {
      return parseFloat(value).toFixed(fixed);
    }
    return value;
  }

  dateSelected(event) {
    const date = event.date;
    const bulkSetExtra: {
      productId: string;
      extra: { [s: string]: any };
      targetStockId?: string;
      productPartialId?: string;
      replace?: boolean;
    }[] = [];

    for (const row of this.mappedData) {
      let rowDate = this.getDeliveryDateFromRow(row.cartRow) || row.deliveryDate || row.minDate;
      if (typeof rowDate === 'string') {
        rowDate = parse(rowDate, 'yyyy-MM-dd', new Date());
      } else if (rowDate instanceof NgbDate) {
        rowDate = new Date(rowDate.year, rowDate.month - 1, rowDate.day);
      }

      if (!!date && date !== rowDate) {
        const dateFortmated = format(date, 'yyyy-MM-dd');
        row.deliveryDate = dateFortmated;
        const extra = this.getExtraWithDeliveryDate(dateFortmated);
        bulkSetExtra.push({
          productId: row.productId,
          extra: extra,
          targetStockId: row.targetStockId || this.currentUser.managedMultistockIds[0],
          productPartialId: row.productPartialId
        });
      }
    }

    this.cartService.bulkSetExtra(bulkSetExtra);

    const date2 = event.date;
    const dString: string = this.datePipe.transform(date2, 'yyyy-MM-dd');
    this.selectedDeliveryDate = dString;
    for (const row of this.checkout.rows) {
      row.extra.orp.ordberlevdat = dString;
    }
  }

  getDeliveryDateFieldName(): string {
    return 'orp.ordberlevdat';
  }

  getExtraWithDeliveryDate(deliveryDate: string) {
    const fields = this.getDeliveryDateFieldName().split('.');
    const data = {};
    let tmp = data;
    for (const field of fields.slice(0, -1)) {
      tmp[field] = {};
      tmp = tmp[field];
    }
    tmp[fields[fields.length - 1]] = deliveryDate;
    return data;
  }

  getDeliveryDateFromRow(row: CartRow) {
    const field = this.getDeliveryDateFieldName();
    const keys = field.split('.');
    return keys.reduce((acc, curr) => acc?.[curr], row.extra);
  }

  editNote(row: CartRow): void {
    let noteInput = '';
    if (!!row.extra.orp) {
      noteInput = row.extra.orp.edit || row.extra.orp.editext || '';
    }

    this.gungModalService.openEditNoteModal(noteInput).then(
      (result: any) => {
        if (!!result && result.hasOwnProperty('note')) {
          this.cartService.setExtra(
            row.productId,
            {
              orp: {
                edit: result.note,
                editext: result.note
              }
            },
            row.targetStockId,
            row.productPartialId
          );
        }
      },
      reason => { }
    );
  }

  public isInteger(row) {
    const decimals = row.product?.extra.ar.antdec;
    return !Number.isInteger(Number((row.quantity / row.product.originalSize).toFixed(decimals || 5)));
  }

  public openCrossReferenceEditor(product: ProductTableRowExtended) {
    const modal = this.modalService.open(JeevesUpdateKuarModalComponent, { size: 'lg' });
    modal.componentInstance.product = product.product;
  }

  public updateDiscountPercentage(row: JeevesSalesCartListRow, value: any, totalDiscount?: boolean) {
    const oldVal = row.discountPercent;
    if (oldVal === value) {
      return;
    }
    // eslint-disable-next-line eqeqeq
    if (!value) {
      this.cartService.removeExtraField(row.productId, 'orp.rabatt1', row.targetStockId, row.productPartialId);
      if (totalDiscount) {
        this.cartService.removeExtraField(row.productId, 'orp.rabatt2', row.targetStockId, row.productPartialId);
        this.cartService.removeExtraField(row.productId, 'orp.rabatt3', row.targetStockId, row.productPartialId);
        this.cartService.removeExtraField(row.productId, 'orp.kundrabatt', row.targetStockId, row.productPartialId);
        this.cartService.removeExtraField(row.productId, 'orp.volymrabatt', row.targetStockId, row.productPartialId);
      }
      return;
    }

    const extra: any = {
      orp: {
        rabatt1: value
      }
    };

    if (totalDiscount) {
      extra.orp.rabatt2 = '0';
      extra.orp.rabatt3 = '0';
      extra.orp.kundrabatt = '0';
      extra.orp.volymrabatt = '0';
    }

    this.cartService.setExtra(row.productId, extra, row.targetStockId, row.productPartialId);
  }

  duplicateProduct(row: JeevesSalesCartListRow, index: number) {
    if (!row.cartRow.productPartialId) {
      // this.cartService.removeRow(row.related[0].cartRow);
      // this.cartService.addToCart(row.related[0].product.id, row.related[0].cartRow.qty, row.related[0].cartRow.targetStockId, row.related[0].product.id + '_' + 0);
      this.cartService.setProductPartialId(row.product.id, row.cartRow.targetStockId, row.product.id + '_' + 0);
    }
    const productPartialId = row.product.id + '_' + new Date().getTime();
    this.cartService.addToCart(
      row.product.id,
      1 || row.cartRow.qty,
      row.cartRow.targetStockId,
      productPartialId,
      index + 1,
      undefined,
      undefined,
      fnProductName(row.product)
    );

    this.updateData();
    // this.updateDataContext(row.related[0].product.id, row.related[0].cartRow.qty, false, productPartialId);
  }

  public updateQty(row: JeevesSalesCartListRow, value: any) {
    const newQty = Number.parseInt(value, 10);
    // check qty
    const oldQty = row.quantity;
    if (oldQty === newQty) {
      return;
    }
    // value has changed, propagate throught the cart
    this.cartService.setProductQuantity(row.productId, newQty, row.targetStockId, row.productPartialId);
  }

  qtyChangeSoChangeDate(row: JeevesSalesCartListRow) {
    if (row.cartRow?.extra?._availability_reference?.availabilities && row.cartRow.extra.orp?.ordberlevdat) {
      const availabilities = row.cartRow.extra._availability_reference.availabilities;
      const rowDeliveryDate = this.dateUtilService.parseDate(row.cartRow.extra.orp.ordberlevdat, 'yyyy-MM-dd');
      let min_date;
      for (const date of Object.keys(availabilities)) {
        const avDate = this.dateUtilService.parseDate(date, 'yyMMdd');
        if (availabilities[date] >= row.cartRow.qty) {
          if (avDate > rowDeliveryDate) {
            min_date = this.dateUtilService.getFormattedDateString(avDate, 'yyyy-MM-dd');
          } else if (rowDeliveryDate > avDate && !this.warningWasActiveBefore[row.productId]) {
            min_date = this.dateUtilService.getFormattedDateString(avDate, 'yyyy-MM-dd');
          }
          break;
        }
      }

      if (min_date) {
        row.cartRow.extra = mergeDeep(row.cartRow.extra, { warning: true });

        const parsedDate = this.dateUtilService.parseDate(min_date || row.cartRow.extra._calc_min_date, 'yyyy-MM-dd');
        const nbgNewMinDate = new NgbDate(parsedDate.getFullYear(), parsedDate.getMonth() + 1, parsedDate.getDate());

        if (!this.partDeliveriesForbidden) {
          this.updateDeliveryDate(row, nbgNewMinDate, true);
        }
      }
    }
    return;
    /*let min_date;
    if (row.cartRow?.extra?._availability_reference?.availabilities) {
      if (row.cartRow.extra._availability_reference.currentAvailability < row.cartRow.qty) {
        const availabilities = row.cartRow.extra._availability_reference.availabilities;

        for (const date of Object.keys(availabilities)) {
          if (availabilities[date] >= row.cartRow.qty) {
            const year = date.substring(0, 2);
            const month = date.substring(2, 4);
            const day = date.substring(4, 6);
            min_date = '20' + year + '-' + month + '-' + day;

            break;
          }
        }
      }
    }

    if (min_date && min_date > row.cartRow.extra.orp.ordberlevdat && !this.warningWasActiveBefore[row.productId]) {
      row.cartRow.extra = {
        ...row.cartRow.extra,
        warning: true
      };
      const parsedDate = this.dateUtilService.parseDate(min_date || row.cartRow.extra._calc_min_date, 'yyyy-MM-dd');
      const nbgNewMinDate = new NgbDate(parsedDate.getFullYear(), parsedDate.getMonth() + 1, parsedDate.getDate());
      this.updateDeliveryDate(row, nbgNewMinDate, true);
    }*/
  }

  updateDeliveryDate(row: JeevesSalesCartListRow, selectedDeliveryDate, warning: boolean) {
    const oldVal = row.deliveryDate || row.minDate;

    let deliveryDate;

    if (selectedDeliveryDate instanceof NgbDate) {
      deliveryDate = format(
        new Date(selectedDeliveryDate.year, selectedDeliveryDate.month - 1, selectedDeliveryDate.day),
        'yyyy-MM-dd'
      );
    } else {
      deliveryDate = format(selectedDeliveryDate.date, 'yyyy-MM-dd');
    }

    if (oldVal === deliveryDate) {
      return;
    }
    if (!warning) {
      row.cartRow.extra = {
        ...row.cartRow.extra,
        warning: false
      };
    }
    if (!deliveryDate) {
      this.cartService.removeExtraField(row.productId, 'orp.ordberlevdat', row.targetStockId, row.productPartialId);
      return;
    }
    this.cartService.setExtra(
      row.productId,
      {
        orp: {
          ordberlevdat: deliveryDate
        }
      },
      row.targetStockId,
      row.productPartialId
    );
  }

  updateDeliveryDateWithWarnings(row: JeevesSalesCartListRow, selectedDeliveryDate, warning: boolean) {
    this.updateDeliveryDate(row, selectedDeliveryDate, warning);

    if (this.isFirstLoad) {
      this.isFirstLoad = false;
      return;
    }

    if (this.wasClicked && !this.checkoutConfig.hasUnsavedChanges) {
      this.checkoutConfig.hasUnsavedChanges = true;
    }

    this.warningWasActiveBefore[row.productId] = true;
  }

  initShipping() {
    const minAmountForFreeShipping = this.selectedCustomer.extra?.kus?.q_jis_fri_levering_grens;
    const remainingAmount = minAmountForFreeShipping - this.calculateTotalValue();
    if (this.selectedCustomer.extra.kus?.kundfraktdeb == '1' && minAmountForFreeShipping > 0) {
      this.showFreeShippingAlert = true;

      if (this.calculateTotalValue() >= minAmountForFreeShipping) {
        this.freeShippingMessage = this.translationService.instant('FREE_FREIGHT_AGREEMENT');
      } else if (this.calculateTotalValue() === 0) {
        this.freeShippingMessage = this.translationService.instant('FREE_FREIGHT_AFTER', {
          name: this.selectedCustomer.name,
          value: this.parseFloatFixed(minAmountForFreeShipping)
        });
      } else {
        this.freeShippingMessage =
          this.translationService.instant('FREE_FREIGHT_AFTER', {
            name: this.selectedCustomer.name,
            value: this.priceConfigService.getPrice(this.parseFloatFixed(minAmountForFreeShipping), null)
          }) +
          this.translationService.instant('MISSING_FREE_FREIGHT', {
            value: this.priceConfigService.getPrice(this.parseFloatFixed(remainingAmount), null)
          });
      }
    } else {
      this.showFreeShippingAlert = false;
    }

    if (this.selectedCustomer.extra.kus.levvillkkod === 62 && this.selectedCustomer.extra.kus.q_jis_fri_levering_grens <= this.calculateTotalValue()) {
      this.checkout.extra.oh.kundfraktdeb = false;
    } else if (this.checkout.extra.procargs.levsattkod && this.checkout.extra.procargs.levsattkod !== this.selectedCustomer.extra.kus.levsattkod) {
      this.checkout.extra.oh.levvillkkod = 0;
      this.checkout.extra.oh.kundfraktdeb = 1;
    } else {
      this.checkout.extra.oh.kundfraktdeb = this.selectedCustomer.extra.kus.kundfraktdeb;
    }
  }

  qtyChangeSoChangeDateRows(rows: JeevesSalesCartListRow[]) {
    let minDateAv: Date = new Date();

    for (const row of rows) {
      if (row.cartRow?.extra?._availability_reference?.availabilities && row.cartRow.extra.orp?.ordberlevdat) {
        const availabilities = row.cartRow.extra._availability_reference.availabilities;
        const rowDeliveryDate = this.dateUtilService.parseDate(row.cartRow.extra.orp.ordberlevdat, 'yyyy-MM-dd');
        let min_date;
        for (const date of Object.keys(availabilities)) {
          const avDate = this.dateUtilService.parseDate(date, 'yyMMdd');
          if (availabilities[date] >= row.cartRow.qty) {
            min_date = this.dateUtilService.getFormattedDateString(avDate, 'yyyy-MM-dd');
            break;
          }
        }

        if (min_date && minDateAv < new Date(min_date)) {
          minDateAv = new Date(min_date);
        }
      }
    }

    if (!minDateAv) {
      return;
    }

    const minDateString = this.dateUtilService.getFormattedDateString(this.minDate, 'yyyy-MM-dd');
    const minDateAvString = this.dateUtilService.getFormattedDateString(minDateAv, 'yyyy-MM-dd');
    const nowDateString = this.dateUtilService.getFormattedDateString(new Date(), 'yyyy-MM-dd');

    if (minDateString !== minDateAvString) {
      if (this.pushDeliveryDate && nowDateString === minDateAvString) {
        minDateAv.setDate(minDateAv.getDate() + 1);
        const nowDate = new Date();
        const firstDateAvailable = this.deliveryDateService.findFirstAvailableDate(this.dates, nowDate);
        minDateAv = firstDateAvailable.date;
      }
      this.minDate = minDateAv;
    }
  }

  isDisableInput(row: JeevesSalesCartListRow): boolean {
    // The configurable products depend on the inputted quantity to get the price at the time of adding to the cart.
    // Allowing changed values will therefore break that pricing logic. We need to ensure that these are unmodifiable
    // in the cart.
    return this.isConfigureProduct(row.product);
  }

  isConfigureProduct(product: Product): boolean {
    return product?.extra?.ar?.webarttext === 'configure';
  }

  getProductLink(row: JeevesSalesCartListRow): string {
    if (this.isConfigureProduct(row.product)) {
      const templateProductId = row.product.extra.templateProductId;
      return `/product/${templateProductId}`;
    }
    if (row.product.extra.assortmentParentPath) {
      if (row.product.extra.assortmentParentPath.includes(row.product.id)) {
        return '/articles/' + row.product.extra.assortmentParentPath;
      }
      return '/articles/' + row.product.extra.assortmentParentPath + '/' + row.product.id;
    }

    return `/product/${row.product.id}`;
  }
}
