import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FilterListSideFilterComponent, RenderFilter } from 'gung-list';

@Component({
  selector: 'otto-olsen-filter-list-side-filter',
  templateUrl: './otto-olsen-filter-list-side-filter.component.html',
  styleUrls: ['./otto-olsen-filter-list-side-filter.component.scss']
})
export class OttoOlsenFilterListSideFilterComponent extends FilterListSideFilterComponent implements OnInit, OnChanges {
  @Input()
  public renderFilter: RenderFilter;

  @Output()
  selectValue = new EventEmitter<string>();

  @Output()
  deselectValue = new EventEmitter<string>();

  @Output()
  selectValueRange = new EventEmitter<string[]>();

  ngOnInit(): void {
    super.ngOnInit();
    this.sortFilterValueList();
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
  }

  sortFilterValueList(){
    // THIS DON't WORK IF YOU HAVE VALUE LIKE: 'G 3/8"'
    // eg: http://localhost:4200/articles/s5/s260/s626/812-1001?limit=160
    if (this.renderFilter.valueList.length === 0) return;

  const value = this.renderFilter.valueList[0];
    if (value.valueId.includes('"')) {
      this.renderFilter.valueList = this.renderFilter.valueList.sort((a, b) => {
        let aValue = a.valueId.replace('"', '');
        let bValue = b.valueId.replace('"', '');

        // Tratamento para valores "UNDEFINED"
        if (aValue === 'UNDEFINED') return 1;
        if (bValue === 'UNDEFINED') return 1;

        let aNum = this.convertToNumber(aValue);
        let bNum = this.convertToNumber(bValue);

        // Comparação dos valores numéricos
        if (aNum < bNum) return -1;
        if (aNum > bNum) return 1;
        return 0;
      });
    }

    
  }

  convertToNumber(value: string): number {
    let number = 0;
    if (value.includes(' ')) {
      const valueParts = value.split(' ');
      number = Number(valueParts[0]);
      if (valueParts[1].includes('/')) {
        number += this.evaluateFraction(valueParts[1]);
      } else {
        number += Number(valueParts[1]);
      }
    } else if (value.includes('/')) {
      number = this.evaluateFraction(value);
    } else {
      number = Number(value);
    }
  
    return isNaN(number) ? 0 : number;
  }

  evaluateFraction(fraction: string): number {
    const [numerator, denominator] = fraction.split('/').map(Number);
    if (isNaN(numerator) || isNaN(denominator) || denominator === 0) {
      return NaN;
    }
    return numerator / denominator;
  }
}
