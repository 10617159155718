<div class="modal-header d-flex align-items-center border-0">
    <i class="fa-solid fa-circle-exclamation fa-3x text-warning mr-2"></i>
    @if (currentStock > 0) {
        <h5 class="m-0" translate>WE_DONT_HAVE_ENOUGH_ITEMS</h5>
    } @else {
        <h5 class="m-0" translate>WE_ARE_OUT_OF_STOCK</h5>
    }
    <button type="button" class="close align-self-start" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <i class="fa-solid fa-circle-xmark text-danger"></i>
    </button>
</div>

<div class="modal-body">
    @if (currentStock > 0) {
        <b>{{'WE_ONLY_HAVE_OF_THIS_ITEM' | translate: {currentStock} }}</b>
    } @else {
        <b>{{'UNFORTUNATELY_WE_ARE_OUT_OF_STOCK' | translate }}</b>
    }
    <ol type="1" class="m-0 px-3">
        @if (currentStock > 0) {
            <li class="p-0" [innerHTML]="'DO_YOU_WANT_TO_REDUCE_FROM' | translate: {qty: qtyToAdd + currentQty, currentStock, unit}"></li>
        }
        <li class="p-0">{{'DELETE_THE_ITEM_LINE_IN_QUESTION' | translate}}</li>
        @if (currentStock > 0) {
            <li class="p-0">{{'KEEP_LEADS_TO_LONGER_DELIVERY_TIME' | translate: {qty: qtyToAdd + currentQty} }}</li>
        } @else {
            <li class="p-0">{{'LEAVE_IN_THE_ORDER' | translate: {qty: qtyToAdd + currentQty, unit} }}</li>
        }
    </ol>
    <div class="row justify-content-center">
        <div class="col-12 col-md-7 text-center">
            @if (currentStock > 0) {
                <button (click)="reduceStock()" type="button" class="btn btn-danger rounded p-3 mb-2 shadow-sm w-100">1. {{'REDUCE_THE_NUMBER_TO_PCS' | translate: {currentStock, unit} }}</button>
            }
            <button (click)="delete()" type="button" class="btn btn-danger rounded p-3 mb-2 shadow-sm w-100">@if(currentStock > 0){2} @else {1}. {{'REMOVE_LINE' | translate}}</button>
            <button (click)="keepQuantiy()" type="button" class="btn btn-danger rounded p-3 mb-2 shadow-sm w-100">@if(currentStock > 0){3} @else {2}. {{'KEEP_PCS' | translate: {qty: qtyToAdd + currentQty, unit} }}</button>
        </div>
    </div>
</div>

<div class="modal-footer border-0">
    <otto-olsen-availability-warehouses
        [availability]="availability"
        [isSales]="isSales"
        [unit]="unit"
        [decimals]="product.extra.ar.antdec"
        [product]="product"
        [productionDays]="
            product?.extra.ar?.anskaffningssatt &&
            product?.extra.ar?.anskaffningssatt * 1 === 12 &&
            product?.extra.ar?.artgenomloppstid
        " 
        mobileModal="true"/>
</div>
