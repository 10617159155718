import { ChangeDetectorRef, Component, ComponentFactoryResolver, OnDestroy, OnInit, Optional } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FilterListComponent,
  FilterListService,
  AbstractSelectionService,
  FilterListRoutingService,
  GungListRouterEventsService,
  FilterListLocationConfigService
} from 'gung-list';
import { Location } from '@angular/common';
import { takeUntil } from 'rxjs';
import { Subject } from 'rxjs';
import { ColumnSortSelectionService } from 'gung-standard';

@Component({
  selector: 'otto-olsen-filter-list',
  templateUrl: './otto-olsen-filter-list.component.html',
  styleUrls: ['./otto-olsen-filter-list.component.scss']
})
export class OttoOlsenFilterListComponent<T> extends FilterListComponent<T> implements OnInit, OnDestroy {
  unsubscribe: Subject<boolean> = new Subject<boolean>();
  qpReady = false;

  constructor(
    public filterListRoutingService: FilterListRoutingService,
    public route: ActivatedRoute,
    public changeDetectorRef: ChangeDetectorRef,
    filterListService: FilterListService<T>,
    protected columnSortSelectionService: ColumnSortSelectionService,
    protected gungListRouterEventsService: GungListRouterEventsService,
    protected filterListLocationConfigService: FilterListLocationConfigService,
    protected router: Router,
    @Optional() protected selectionService?: AbstractSelectionService<T>
  ) {
    super(
      filterListRoutingService,
      route,
      changeDetectorRef,
      filterListService,
      gungListRouterEventsService,
      filterListLocationConfigService,
      router,
      selectionService
    );
  }

  ngOnInit(): void {
    super.ngOnInit();

    // this.columnSortSelectionService
    //   .getSelectedColumnSort()
    //   .pipe(takeUntil(this.unsubscribe))
    //   .subscribe(selectedColumnSort => {
    //     if (selectedColumnSort) {
    //       const items = JSON.parse(JSON.stringify(this.items));
    //       this.items = this.sortItemsByColumn(items, selectedColumnSort);
    //       this.filter();
    //     }
    //   });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.unsubscribe.next(undefined);
    this.unsubscribe.complete();
  }

  sortItemsByColumn(data, headerKey): T[] {
    const ascSort = !headerKey.sort || headerKey.sort === 'asc' ? -1 : 1;
    return data.sort((a, b) => {
      const aValue = this.getValue(headerKey.path, a);
      const bValue = this.getValue(headerKey.path, b);
      if (!aValue && !bValue) {
        return 0;
      }
      if (!aValue) {
        return -1 * ascSort;
      }
      if (!bValue) {
        return 1 * ascSort;
      }
      let aNumber;
      let bNumber;
      if (typeof aValue === 'number') {
        aNumber = aValue;
      }
      if (typeof bValue === 'number') {
        bNumber = bValue;
      }
      if (!aNumber && !isNaN(aValue.replace(',', '.'))) {
        aNumber = Number(aValue.replace(',', '.'));
      }
      if (!bNumber && !isNaN(bValue.replace(',', '.'))) {
        bNumber = Number(bValue.replace(',', '.'));
      }
      // Number
      if (aNumber && bNumber) {
        if (Number(aNumber) < Number(bNumber)) {
          return -1 * ascSort;
        }
        if (Number(bNumber) < Number(aNumber)) {
          return 1 * ascSort;
        }
        return 0;
      }
      // String
      return aValue.localeCompare(bValue) * ascSort;
    });
  }

  getValue(path, item) {
    if (path === 'id') {
      return item.id;
    }
    if (path === 'availabilities') {
      const keys = Object.keys(item.extra.availabilities);
      if (!keys || keys.length === 0) {
        return 0;
      }
      return item.extra.availabilities[keys[0]].currentAvailability;
    }
    if (path === 'pim.OD_tommer') {
      const keys = Object.keys(item.extra.pim.OD_tommer.properties);
      if (!keys || keys.length === 0) {
        return 0;
      }
      return item.extra.pim.OD_tommer.properties.sorting;
    }
    const splitPath = ['extra', ...path.split('.')];

    const reducedValue = splitPath.reduce((oldValue, currentValue) => {
      if (!oldValue) {
        return oldValue;
      }
      if (oldValue[currentValue]) {
        return oldValue[currentValue];
      } else {
        return null;
      }
    }, item);
    if (
      !reducedValue ||
      (typeof reducedValue === 'object' &&
        Object.keys(reducedValue).length === 0 &&
        reducedValue.constructor === Object)
    ) {
      // Empty Object found
      return null;
    }

    if (typeof reducedValue === 'object' && reducedValue.description) {
      return reducedValue.description;
    }

    return reducedValue;
  }

  clearRangeFilter(actions) {
    for (const action of actions) {
      if (!action.selected) {
        const index = this.renderFilters.findIndex(f => f.name === action.filterName);
        if (index > -1 && (this.renderFilters[index].type === 'dateRangeFilter' || this.renderFilters[index].type === 'RangeConfigBaseFilter')) {
          this.renderFilters[index].maxValue = undefined;
          this.renderFilters[index].minValue = undefined;
          this.renderFilters[index] = {
            ...this.renderFilters[index]
          };
        }
      }
    }
  }
}
