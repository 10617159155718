import { AfterViewInit, ChangeDetectorRef, Component, ComponentRef, HostListener, OnDestroy, OnInit, ViewChild, ViewContainerRef, Input } from '@angular/core';
import { GlobalSearchConfigService, GlobalSearchResult, GlobalSearchResults } from '../../services/global-search-config.service';
import { BehaviorSubject, Observable, Subject, catchError, debounceTime, distinctUntilChanged, first, forkJoin, of, switchMap, takeUntil, tap } from 'rxjs';
import { FastSearchLayout, FastsearchLayoutComponent } from 'gung-list';
import { Router } from '@angular/router';
import { ProductAssortmentParentService } from '../../services/product-assortment-parent/product-assortment-parent.service';

@Component({
  selector: 'lib-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss']
})
export class GlobalSearchComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() inputExtraClasses: string = '';
  @Input() roundedCorners = false;

  public isLoading: boolean = false;
  public showNoResults: boolean = false;
  public inputInFocus: boolean = false;
  public searchInputValue: string = '';
  public searchTerms: BehaviorSubject<string> = new BehaviorSubject<string>(this.searchInputValue);

  public currentResultProducts: GlobalSearchResults | undefined;
  public renderItemsProducts = new Subject<GlobalSearchResult[]>();
  public renderItemsProducts$: Observable<GlobalSearchResult[]> = this.renderItemsProducts.asObservable();
  public currentResultCategories: GlobalSearchResults | undefined;
  public renderItemsCategories = new Subject<GlobalSearchResult[]>();
  public renderItemsCategories$: Observable<GlobalSearchResult[]> = this.renderItemsCategories.asObservable();

  @ViewChild('hostViewProducts', { read: ViewContainerRef }) hostViewProducts: ViewContainerRef;
  protected currentViewProducts: ComponentRef<any>;

  @ViewChild('hostViewCategories', { read: ViewContainerRef }) hostViewCategories: ViewContainerRef;
  protected currentViewCategories: ComponentRef<any>;

  protected unsubscribe: Subject<void> = new Subject<void>();

  @HostListener('window:click', ['$event.target']) onClick(btn: Element): void {
    if (!btn.closest('.navbar-search') && !btn.closest('.global-search-mobile-icon')) {
      this.globalSearchConfig.closeSubject.next();
    }
  }

  constructor(
    protected globalSearchConfig: GlobalSearchConfigService,
    protected detector: ChangeDetectorRef,
    protected router: Router,
    protected productAssortmentParentService: ProductAssortmentParentService
  ) { }

  ngOnInit(): void {
    this.searchTerms.pipe(
      takeUntil(this.unsubscribe),
      tap(_ => this.isLoading = true),
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(terms => forkJoin({
        terms: of(terms),
        resultProducts: this.globalSearchConfig.searchProducts(terms).pipe(first()),
        resultCategories: this.globalSearchConfig.searchCategories && !this.globalSearchConfig.searchCategoriesAssortment ? this.globalSearchConfig.searchProducts(terms, true).pipe(first()) : of(undefined),
        searchAssortments: this.globalSearchConfig.searchCategories && this.globalSearchConfig.searchCategoriesAssortment ? this.productAssortmentParentService.getSearchAssortments(terms).pipe(first(), catchError(() => of(undefined))) : of(undefined)
      })),
      switchMap(({ terms, resultProducts, resultCategories, searchAssortments }) => {
        if (this.globalSearchConfig.searchCategoriesAssortment) {
          return forkJoin({
            resultProducts: of(resultProducts),
            resultCategories: this.globalSearchConfig.searchCategoriesAssortments(terms, searchAssortments).pipe(first())
          });
        } else {
          return of({
            resultProducts,
            resultCategories
          });
        }
      })
    ).subscribe(({ resultProducts, resultCategories }) => {
      this.currentResultProducts = resultProducts;
      this.currentResultCategories = resultCategories;
      this.renderItemsProducts.next(resultProducts?.results || []);
      this.renderItemsCategories.next(resultCategories?.results || []);
      this.checkIfNoResults(resultProducts?.results || [], resultCategories?.results || []);
      this.isLoading = false;
    });

    this.globalSearchConfig.closeSubject.pipe(
      takeUntil(this.unsubscribe)
    ).subscribe(_ => this.clearResults());
  }

  ngAfterViewInit(): void {
    this.renderLayout(this.globalSearchConfig.getLayout())
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  renderLayout(layout: FastSearchLayout<GlobalSearchResult>) {
    this.detector.detectChanges();
    if (this.hostViewProducts) {
      this.currentViewProducts = this.hostViewProducts.createComponent(layout.getLayout());
      const typedComponent = this.currentViewProducts.instance as FastsearchLayoutComponent<GlobalSearchResult>;
      typedComponent.itemListRenderer = layout.getListItemComponent();
      typedComponent.itemDetailRenderer = layout.getDetailItemComponent();
      typedComponent.renderItems = this.renderItemsProducts;
      this.detector.detectChanges();
    }

    if (this.globalSearchConfig.searchCategories && this.hostViewCategories) {
      this.currentViewCategories = this.hostViewCategories.createComponent(layout.getLayout());
      const typedComponent = this.currentViewCategories.instance as FastsearchLayoutComponent<GlobalSearchResult>;
      typedComponent.itemListRenderer = layout.getListItemComponent();
      typedComponent.itemDetailRenderer = layout.getDetailItemComponent();
      typedComponent.renderItems = this.renderItemsCategories;
      this.detector.detectChanges();
    }
  }

  public search() {
    this.searchTerms.pipe(
      first(),
      tap(_ => this.isLoading = true),
      switchMap(terms => forkJoin({
        terms: of(terms),
        resultProducts: this.globalSearchConfig.searchProducts(terms).pipe(first()),
        resultCategories: this.globalSearchConfig.searchCategories && !this.globalSearchConfig.searchCategoriesAssortment ? this.globalSearchConfig.searchProducts(terms, true).pipe(first()) : of(undefined),
        searchAssortments: this.globalSearchConfig.searchCategories && this.globalSearchConfig.searchCategoriesAssortment ? this.productAssortmentParentService.getSearchAssortments(terms).pipe(first(), catchError(() => of(undefined))) : of(undefined)
      })),
      switchMap(({ terms, resultProducts, resultCategories, searchAssortments }) => {
        if (this.globalSearchConfig.searchCategoriesAssortment) {
          return forkJoin({
            resultProducts: of(resultProducts),
            resultCategories: this.globalSearchConfig.searchCategoriesAssortments(terms, searchAssortments).pipe(first())
          });
        } else {
          return of({
            resultProducts,
            resultCategories
          });
        }
      })
    ).subscribe(({ resultProducts, resultCategories }) => {
      this.currentResultProducts = resultProducts;
      this.currentResultCategories = resultCategories;
      this.renderItemsProducts.next(resultProducts?.results || []);
      this.renderItemsCategories.next(resultCategories?.results || []);
      this.checkIfNoResults(resultProducts?.results || [], resultCategories?.results || []);
      this.isLoading = false;
    });
  }

  public clearResults() {
    this.currentResultProducts = undefined;
    this.currentResultCategories = undefined;
    this.renderItemsProducts.next([]);
    this.renderItemsCategories.next([]);
    this.showNoResults = false;
  }

  public onFocusInput(): void {
    if (!this.globalSearchConfig.searchCategories && (this.currentResultProducts?.count || 0) === 0) {
      this.search();
    } else if (this.globalSearchConfig.searchCategories && (this.currentResultProducts?.count || 0) === 0 && (this.currentResultCategories?.count || 0) === 0) {
      this.search();
    }
  }

  public getInputButtonIcon(): string {
    if (this.isLoading) {
      return this.globalSearchConfig.inputLoadingIcon;
    }

    if ((this.currentResultProducts?.count || 0) <= 0 && (this.currentResultCategories?.count || 0) <= 0) {
      return this.globalSearchConfig.inputSearchIcon;
    } else if ((this.currentResultProducts?.count || 0) > 0 || (this.currentResultCategories?.count || 0) > 0) {
      return this.globalSearchConfig.inputClearIcon;
    } else {
      return '';
    }
  }

  public onInputButtonClick(): void {
    if (this.isLoading) {
      return;
    }

    if ((this.currentResultProducts?.count || 0) <= 0 && (this.currentResultCategories?.count || 0) <= 0) {
      this.search();
    } else if ((this.currentResultProducts?.count || 0) > 0 || (this.currentResultCategories?.count || 0) > 0) {
      this.clearResults();
      this.searchInputValue = '';
    }
  }

  protected checkIfNoResults(products: any[], categories: any[]): void {
    if ((!products || products.length === 0) && (!categories || categories.length === 0)) {
      this.showNoResults = true;
    } else {
      this.showNoResults = false;
    }
  }

  public keyPressedHandler(event, searchInput) {
    if (event.keyCode === 13) {
      //ENTER
      this.showMoreProducts(searchInput);
    }
  }

  public showMoreProducts({ value }) {
    this.router.navigate(['/view-more'], { queryParams: { search: 'DEFAULT__:__' + value, limit: 24, skuLevel: this.globalSearchConfig.skuLevel} });
    this.clearResults();
  }
}
