<div class="modal-header d-flex align-items-center border-0">
    <i class="fa-solid fa-circle-exclamation fa-3x text-warning mr-2"></i>
    <h5 class="m-0" translate>THIS_ITEM_HAS_BEEN_DISCONTINUED</h5>
    <button type="button" class="close align-self-start" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <i class="fa-solid fa-circle-xmark text-danger"></i>
    </button>
</div>

<div class="modal-body">
    @if (productReplace) {
        <b>{{'THIS_ITEM_HAS_BEEN_DISCONTINUED_BUT_HAS_RECEIVED_REPLACEMENT_PRODUCT' | translate: {id: productReplace.id} }}</b>
    } @else {
        <b translate>UNFORTUNATELY_THIS_ITEM_HAS_BEEN_DISCONTINUED</b>
    }

    <p class="mt-4" translate>{{'CONTACT_OTTO_OLSEN_REGARD_TO_FUTURE_SCANS' | translate: {customerName: selectedCustomer.name} }}</p>
</div>

<div class="modal-footer border-0">
    @if (productReplace) {
        <otto-olsen-availability-warehouses
            [availability]="availability"
            [isSales]="isSales"
            [unit]="unit"
            [decimals]="productReplace.extra.ar.antdec"
            [product]="productReplace"
            [productionDays]="
                productReplace?.extra.ar?.anskaffningssatt &&
                productReplace?.extra.ar?.anskaffningssatt * 1 === 12 &&
                productReplace?.extra.ar?.artgenomloppstid
            " 
            mobileModal="true"
            productReplace="true"/>
    }
    
</div>
