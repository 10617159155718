<div *ngIf="order">
  <div class="row">
    <div class="col-9">
      <a (click)="navigateBackwards()" class="btn btn-outline-secondary">
        <i class="fas fa-arrow-left"></i>
        <span class="pl-1">{{'BACK_TO_ORDER' | translate}}</span>
      </a>    
    </div>
    <div class="col-3">
      <otto-olsen-order-navigation-buttons></otto-olsen-order-navigation-buttons>
    </div>
  </div>
  
  <div class="d-flex flex-row justify-content-between align-items-center mb-4 mt-4">
    <div>
      <h2 *ngIf="order.extra.oh.ordstat !== 0">Ordre {{ order.id }}</h2>
      <h2 *ngIf="order.extra.oh.ordstat === 0">Tillbud {{ order.id }}</h2>
      <h6 *ngIf="order.extra.deliveryCustomer">{{ order.extra.deliveryCustomer.name }}</h6>
    </div>
    <div class="form-group">
      <lib-action-btn [item]="order" [config]="configService"></lib-action-btn>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col">
      <lib-gung-accordion #acc="gungAccordion" activeIds="ngb-panel-0">
        <lib-gung-accordion-item [collapsed]="false">
          <ng-template libGungAccordionHeader>{{'DETAILS_AND_CONDITIONS' | translate}}</ng-template>
          <ng-template libGungAccordionBody>
            <div class="row">
              <div class="col col-xs-12 col-md-6">
                <h5>{{'DETAILS' | translate}}</h5>
                <lib-gung-text-input-horizontal
                  renderStatic="true"
                  gungTranslate="ORDER_NUMBER"
                  [(ngModel)]="order.extra.oh.ordernr"
                >
                </lib-gung-text-input-horizontal>
                <lib-gung-text-input-horizontal
                  renderStatic="true"
                  gungTranslate="YOUR_REFERENCE_NO"
                  [(ngModel)]="order.extra.oh.kundbestnr"
                ></lib-gung-text-input-horizontal>
                <lib-gung-text-input-horizontal
                  renderStatic="true"
                  gungTranslate="CUSTOMER_REFERENCE"
                  [(ngModel)]="order.extra.oh.kundref2"
                ></lib-gung-text-input-horizontal>
                <lib-gung-text-input-horizontal
                  renderStatic="true"
                  gungTranslate="OUR_REFERENCE"
                  [(ngModel)]="order.extra.oh.vref"
                ></lib-gung-text-input-horizontal>

                <lib-gung-meta-output
                  [value]="order.extra.oh.ordstat"
                  [gungTranslate]="'ORDER_STATUS'"
                  metadataTable="x7"
                  metaOptionName="ordstatbeskr"
                  [horizontal]="true"
                ></lib-gung-meta-output>
                <lib-gung-date-output
                  [horizontal]="true"
                  [gungTranslate]="'ORDER_DATE'"
                  [value]="order.extra.oh.orddatum"
                >
                </lib-gung-date-output>
                <lib-gung-text-input-horizontal
                  renderStatic="true"
                  gungTranslate="USER"
                  [ngModel]="order.extra.oh.slutkundnamn2"
                >
                </lib-gung-text-input-horizontal>
              </div>
              <div class="col col-xs-12 col-md-6">
                <h5>{{'CONDITIONS' | translate}}</h5>
                <lib-gung-meta-output
                  [horizontal]="true"
                  [gungTranslate]="'PAYMENT_TERMS'"
                  [value]="order.extra.oh.betkod"
                  metadataTable="x2"
                  metaOptionName="betvillbeskr"
                >
                </lib-gung-meta-output>
                <lib-gung-meta-output
                  [horizontal]="true"
                  [gungTranslate]="'DELIVERY_TERMS'"
                  [value]="order.extra.oh.levvillkkod"
                  metadataTable="x2e"
                  metaOptionName="levvillkbeskr"
                >
                </lib-gung-meta-output>
                <lib-gung-meta-output
                  [horizontal]="true"
                  [gungTranslate]="'DELIVERY_METHOD'"
                  [value]="order.extra.oh.levsattkod"
                  metadataTable="x2f"
                  metaOptionName="levsattbeskr"
                >
                </lib-gung-meta-output>
                <div *ngIf="order.extra.deliveries">
                  <span class="delivery-tracking-header">{{'DELIVERY_TRACKING' | translate}}</span>
                  <div *ngFor="let delivery of order.extra.deliveries">
                    <a
                      *ngIf="delivery.fraktsendnummer"
                      href="https://sporing.bring.no/sporing/{{ delivery.fraktsendnummer }}?lang=no"
                      >{{ 'DELIVERY' | translate }} - {{ delivery.ordberlevdat | date: dateUtilService.dateFormat }}</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </lib-gung-accordion-item>
        <lib-gung-accordion-item>
          <ng-template libGungAccordionHeader>{{'SHIPPING_INFORMATION' | translate}}</ng-template>
          <ng-template libGungAccordionBody>
            <div class="row">
              <div class="col col-12">
                <h5>{{'ADDRESS' | translate}}</h5>
              </div>
              <div class="col col-xs-12 col-md-6">
                <lib-gung-text-input-horizontal
                  renderStatic="true"
                  gungTranslate="ADDRESS1"
                  [(ngModel)]="order.extra.oh.ordlevadr2"
                ></lib-gung-text-input-horizontal>
                <lib-gung-text-input-horizontal
                  renderStatic="true"
                  gungTranslate="ADDRESS2"
                  [(ngModel)]="order.extra.oh.ordlevadr3"
                ></lib-gung-text-input-horizontal>
              </div>
              <div class="col col-xs-12 col-md-6">
                <lib-gung-text-input-horizontal
                  renderStatic="true"
                  gungTranslate="CITY"
                  [(ngModel)]="order.extra.oh.ordlevadr4"
                ></lib-gung-text-input-horizontal>
                <lib-gung-text-input-horizontal
                  *ngIf="order.extra.oh.ftgpostnr"
                  renderStatic="true"
                  gungTranslate="ZIP"
                  [(ngModel)]="order.extra.oh.ftgpostnr"
                >
                </lib-gung-text-input-horizontal>
                <lib-gung-meta-output
                  [horizontal]="true"
                  [gungTranslate]="'COUNTRY'"
                  [value]="order.extra.oh.ordlevadrlandskod"
                  metadataTable="xr"
                  metaOptionName="land"
                >
                </lib-gung-meta-output>
              </div>
            </div>
          </ng-template>
        </lib-gung-accordion-item>
        <lib-gung-accordion-item>
          <ng-template libGungAccordionHeader>{{'ADDITIONAL_INFORMATION' | translate}}</ng-template>
          <ng-template libGungAccordionBody>
            <div class="form-group row">
              <label for="oh.editext" class="col-form-label text-right-sm text-muted col-xs-12 col-sm-4">{{'MESSAGE' | translate}}</label>
              <div class="col">
                <textarea
                  class="form-control"
                  type="text"
                  name="editext"
                  id="oh.editext"
                  disabled="true"
                  [(ngModel)]="order.extra.oh.editext"
                ></textarea>
              </div>
            </div>
            <div class="form-group row" *ngIf="isSales">
              <label for="oh.edit" class="col-form-label text-right-sm text-muted col-xs-12 col-sm-4">{{'INTERNAL_TEXT' | translate}}</label>
              <div class="col">
                <textarea
                  class="form-control"
                  type="text"
                  name="edit"
                  id="oh.edit"
                  disabled="true"
                  [(ngModel)]="order.extra.oh.edit"
                ></textarea>
              </div>
            </div>
          </ng-template>
        </lib-gung-accordion-item>
      </lib-gung-accordion>
    </div>
  </div>

  <div class="row">
    <div class="col-12 table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th class="text-right">{{'ROW' | translate}}</th>
            <th>{{'PRODUCT_ID' | translate}}</th>
            <th>{{'NAME' | translate}}</th>
            <th class="text-right">{{'ESTIMATED_DELIVERY_DATE' | translate}}</th>
            <th class="text-right">{{'QUANTITY' | translate}}</th>
            <th class="text-right">{{'QUANTITY_DELIVERED' | translate}}</th>
            <th class="text-right">{{'QUANTITY_BACKORDER' | translate}}</th>
            <th class="text-right">{{'UNIT' | translate}}</th>
            <th class="text-right">{{'UNIT_PRICE' | translate}}</th>
            <th class="text-right">{{'DISCOUNT' | translate}}</th>
            <th class="text-right">{{'TOTAL_PRICE' | translate}}</th>
            <th *ngIf="order.extra.oh.ordstat === 0 && isSales"></th>
          </tr>
        </thead>
        <tbody *ngIf="order && products">
          <tr *ngFor="let row of order.rows" [ngClass]="row.extra.orp.ordradst === 90 ? 'deleted' : ''">
            <td class="text-right">
              <span>{{ row.extra.orp.ordradnr }}</span>
              
            </td>
            <td>
              <a
                [routerLink]="[
                  products[row.productId]?.extra.assortmentParentPath
                    ? '/articles/' + products[row.productId].extra.assortmentParentPath
                    : '/product/',
                  row.productId
                ]"
                >{{ row.productId }}</a
              >
              <p
              *ngIf="
                order.billingCustomerId &&
                products[row.productId]?.extra._customerCrossReferences &&
                products[row.productId]?.extra._customerCrossReferences[order.deliveryCustomerId]
              "
                class="small m-0"
              >
              {{ products[row.productId].extra._customerCrossReferences[order.deliveryCustomerId].referenceId }}
            </p>
            </td>
            <td>
              <a
                [routerLink]="[
                  products[row.productId]?.extra.assortmentParentPath
                    ? '/articles/' + products[row.productId].extra.assortmentParentPath
                    : '/product/',
                  row.productId
                ]"
              >
                <span>
                  <!-- {{ row.extra.orp.artbeskr }} -->
                  {{ products[row.productId] | productName }}
                </span>
              </a>
              <div *ngIf="row?.extra?.orp?.editext || row?.extra?.orp?.edit">
                <b>{{'NOTE' | translate}}</b>: <span style="white-space: pre-line">{{ row.extra.orp.edit || row.extra.orp.editext }}</span>
              </div>
            </td>
            <td class="text-right">
              {{ row.extra.orp.ordberlevdat | date: dateUtilService.dateFormat }}
            </td>
            <td class="text-right">
              {{ row.quantity }}
            </td>
            <td class="text-right">
              {{ row.extra.orp.ordlevantal }}
            </td>
            <td class="text-right">
              {{ row.extra.orp.ordrestant }}
            </td>
            <td class="text-right">
              {{ row.extra.orp.enhetskod | translate }}
            </td>
            <td class="text-right">
              <lib-price [price]="row.extra.orp.vb_pris" [currency]="order.extra.oh.valkod"></lib-price>
            </td>
            <td class="text-right"><span [class.blur]="hidePrice">{{ row.extra.orp.rabatt1 | number: '1.0-2' }}%</span></td>
            <td class="text-right">
              <lib-price [price]="row.extra.orp.vbordradsum" [currency]="order.extra.oh.valkod"></lib-price>
            </td>
            <td *ngIf="isOrderEditable(order, isSales)">
              <gung-jeeves-edit-orderrow-modal (orderRowChange)="orderUpdated($event)" [orderRow]="row">
              </gung-jeeves-edit-orderrow-modal>
            </td>
          </tr>
          <tr>
            <td colspan="11" class="text-right">
              Sum: <lib-price [price]="totalPrice" [currency]="order.extra.oh.valkod"></lib-price>
            </td>
          </tr>
          <tr>
            <td colspan="11" class="text-right">
              Total Inc. VAT: <lib-price [price]="order.extra.oh.ohordsuminklmoms" [currency]="order.extra.oh.valkod"></lib-price>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col-12" *ngIf="isOrderEditable(order, isSales)">
      <button class="btn btn-primary btn-block" (click)="addRow()">{{'ADD_ROW' | translate}}</button>
    </div>
  </div>
</div>
