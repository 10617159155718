<div class="contianer">
  <div class="row">
    <div class="col-12 text-center">
      <h5 class="mt-0">Hovedmeny</h5>
    </div>
  </div>
  <div *ngIf="router.url === '/mobile'" class="customer-info">
    <span class="font-weight-bold">Kunde: {{ customer.name }}</span>
    <span class="">Bruker: {{ user.email }}</span>
  </div>
  <div class="row">
    <div class="col-12 d-flex justify-content-center">
      <button type="button" class="btn bg-primary text-white border-primary d-flex btn-scanner-menu w-100 m-3 p-2" [routerLink]="'/barcode-scanner'">
        <i class="w-25 fas fa-barcode"></i>
        <div class="w-75 d-inline-block btn-text"><span translate >BARCODE_SCANNER</span></div>
        
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-12 d-flex justify-content-center">
      <button type="button" class="btn text-primary border-primary btn-scanner-menu w-100 m-3 p-2 d-flex" [routerLink]="'/mobile/orders'">
        <i class="w-25 fas fa-file-invoice"></i>
        <div class="w-75 d-inline-block btn-text"><span translate>PREVIOUS_ORDERS</span></div>
      </button>
    </div>
  </div>
  <!-- <div class="row">
    <div class="col-12 d-flex justify-content-center">
      <button type="button" class="btn text-primary border-primary btn-scanner-menu w-100 m-3 p-2 d-flex" [routerLink]="'/carts'">
        <i class="w-25 fas fa-cart-plus"></i>
        <div class="w-75 d-inline-block btn-text"><span translate>SAVE_CART</span></div>
      </button>
    </div>
  </div> -->
  <div class="row">
    <div class="col-12 d-flex justify-content-center">
      <button type="button" class="btn text-primary border-primary btn-scanner-menu w-100 m-3 p-2 d-flex" (click)="exitScannermode()">
        <i class="w-25 fa-solid fa-display"></i>
        <div class="w-75 d-inline-block btn-text"><span translate>EXIT_SCANNER_MODE</span></div>
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-12 d-flex justify-content-center">
      <button type="button" class="btn text-primary border-primary btn-scanner-menu w-100 m-3 p-2 d-flex">
        <i class="w-25 fas fa-lock mr-2"></i>
        <div class="w-75 d-inline-block btn-text"><span translate>RESET_PASSWORD</span></div>
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-12 d-flex justify-content-center">
      <button type="button" class="btn btn-dark border btn-scanner-menu w-100 m-3 p-2 d-flex" (click)="logout()">
        <i class="w-25 fas fa-sign-out-alt mr-2"></i>
        <div class="w-75 d-inline-block btn-text"><span translate>LOGOUT</span></div>
      </button>
    </div>
  </div>
</div>
<otto-olsen-scanner-footer-menu></otto-olsen-scanner-footer-menu> 
