import { Pipe, PipeTransform } from '@angular/core';
import { gungGetPropertyOfObject } from 'gung-common';
import { Assortment, Product } from 'gung-standard';

export const fnProductName = (product: Product | Assortment, customPath?: string, ...args: unknown[]) => {
  const name = product?.extra?.pim?.Visningsnavn || product?.extra?.ar?.artbeskr || product?.name || (typeof product === 'string' && product) || '-';
  if (customPath) {
    return gungGetPropertyOfObject(customPath, product) || name;
  }
  return name;
}

@Pipe({
  name: 'productName',
  standalone: true
})
export class ProductNamePipe implements PipeTransform {
  /*
  EXAMPLES USAGE:
    - HTML:
        productName: {{ item.product | productName}}
        productNameWithCustomPath: {{ item.product | productName : 'extra.ar.artbeskr'}}
    - TS:
        import { fnProductName } from '../../../../pipes/product-name.pipe';
        name: fnProductName(product)
  */

  transform(product: Product | Assortment, customPath?: string, ...args: unknown[]): string {
    return fnProductName(product, customPath, ...args);
  }
}
