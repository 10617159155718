import { Injectable } from '@angular/core';
import { OrderRow, OrderToBasketService } from 'gung-standard';
import { first, switchMap, forkJoin, of, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenOrderToBasketService extends OrderToBasketService {
  public async addOrderToBasket(orderId: string): Promise<void> {
    const excluded = this.getExcludedProductIds();
    this.orderService
      .getOrder(orderId)
      .pipe(
        first(),
        switchMap(order =>
          forkJoin({
            order: of(order),
            blockedProducts: this.productService.getBlockedProducts(order.rows.filter(row => row.extra.orp.ordradst !== 90).map(row => row.productId)).pipe(first())
          })
        ),
        map(({ order, blockedProducts }) => {
          this.modalService
            .openConfirmYesNoModal(undefined, this.translationService.instant('LOAD_ORDER_CART_CONFIRMATION'), {
              size: 'sm'
            })
            .then(result => {
              const blocked: string[] = [];
              const orderRows: OrderRow[] = [];
              if (result) {
                this.cartService.clearCart();

                for (const productId of Object.keys(blockedProducts)) {
                  if (excluded.includes(productId)) {
                    continue;
                  }

                  if (!blockedProducts[productId]) {
                    const orderRow = order.rows.find(row => row.productId === productId);
                    if (!!orderRow && orderRow.extra.orp.ordradnrstrpos === 0) {
                      orderRows.push(orderRow);
                    }
                  } else {
                    blocked.push(productId);
                  }
                }
              }
              if (blocked.length > 0) {
                this.modalService
                  .openConfirmYesNoModal(
                    this.translationService.instant('EXCLUDED_PRODUCTS'),
                    this.translationService.instant('EXCLUDED_PRODUCTS_MESSAGE', { products: blocked.join(', ') }),
                    null,
                    'OK',
                    null
                  )
                  .then(proceed => {
                    if (proceed) {
                      this.addRows(orderRows);
                    }
                  });
              } else {
                this.addRows(orderRows);
               
              }
            });
        })
      )
      .toPromise();
  }
}
