import { Component, Inject, Input, Optional } from '@angular/core';
import { AdyenOrderType, AdyenPaymentAuthorisedResponse, AdyenService } from '../../services/adyen/adyen.service';
import { Router } from '@angular/router';
import { CheckoutStepComponent } from '../checkout/steps/base/checkout-step.component';
import { CheckoutObject } from '../../models/checkout';
import { AdyenPaymentCompletedEvent, AdyenPaymentFailedEvent } from '../adyen-payment/adyen-payment.component';
import { CartService } from '../../services/cart/cart.service';
import { first } from 'rxjs';

@Component({
  selector: 'lib-adyen-payment-step',
  templateUrl: './adyen-payment-step.component.html',
  styleUrls: ['./adyen-payment-step.component.scss']
})
export class AdyenPaymentStepComponent extends CheckoutStepComponent {
  @Input()
  public checkout: CheckoutObject;
  payByLink: boolean = false;
  isAdvancedFlow: boolean = false;
  adyenOrderType: AdyenOrderType = AdyenOrderType.ADYEN_ORDER;

  constructor(
    @Optional()
    @Inject('environment')
    protected environment: { [s: string]: any },
    protected adyenService: AdyenService,
    protected router: Router,
    protected cartService: CartService
  ) {
    super();
  }

  ngOnInit(): void {
    // When we don't pay with adyen , we don't need to do anything
    if (!this.adyenService.isEnabled() || !this.adyenService.shouldUseAdyenStep(this.checkout)) {
      this.stepDone.emit(this.checkout);
      return;
    }
    this.payByLink = this.adyenService.shouldUsePayByLink(this.checkout);
    this.isAdvancedFlow = this.adyenService.shouldUseAdvancedFlow();
  }

  protected onPaymentCompleted(event: AdyenPaymentCompletedEvent): void {
    if (event.data.resultCode === 'Authorised') {
      this.cartService.clearCart();

      try {
        this.adyenService
          .onPaymentCompleted(event)
          .pipe(first())
          .subscribe({
            next: (response: AdyenPaymentAuthorisedResponse) => {
              this.cartService.clearCart();
              this.router.navigateByUrl('payment-successful-adyen/' + event.merchantReference);
            },
            error: e => {
              console.error('Error on onPaymentCompleted', e);
              this.router.navigateByUrl('order-failure-adyen');
            }
          });
      } catch (e) {
        console.error('Error on onPaymentCompleted', e);
        this.router.navigateByUrl('order-failure-adyen');
      }
    } else {
      this.router.navigateByUrl('payment-failure-adyen');
    }
  }

  protected onPaymentFailed(event: AdyenPaymentFailedEvent) {
    this.router.navigateByUrl('payment-failure-adyen');
  }

  public isExternalPaymentStep(): boolean {
    return true;
  }
}
