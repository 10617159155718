<div class="dashboard-card scrollable-content card">
  <div class="card-body dashboard-card-content">
    <div class="card-title">
      <div class="d-flex justify-content-between align-items-center">
        <span class="title" translate>SALES_SUMMARY</span>
        <div class="btn-group">
          <select
            class="form-control group-on-select shadow-sm"
            [(ngModel)]="currentGroupOn"
            (change)="chooseGroup(currentGroupOn)"
          >
            <option translate [ngValue]="salesSummaryEntity.PRODUCT">PRODUCTS</option>
          </select>
          <select
            class="form-control timespan-select shadow-sm"
            [(ngModel)]="currentTimespan"
            (change)="changeTimespan(this.currentTimespan)"
          >
            <option translate [ngValue]="timespan.Week">THIS_WEEK</option>
            <option translate [ngValue]="timespan.Month">THIS_MONTH</option>
            <option translate [ngValue]="timespan.Year">THIS_YEAR</option>
            <option translate [ngValue]="timespan.LastWeek">LAST_WEEK</option>
            <option translate [ngValue]="timespan.LastMonth">LAST_MONTH</option>
            <option translate [ngValue]="timespan.LastYear">LAST_YEAR</option>
          </select>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <table
        class="table"
        mat-table
        [dataSource]="dataSource"
        matSort
        matSortActive="totalAmount"
        matSortDirection="asc"
        matSortStart="desc"
        matSortDisableClear
      >
        <ng-container matColumnDef="name">
          <th mat-header-cell mat-sort-header *matHeaderCellDef style="width: 50%">
            <span translate>NAME</span>
          </th>
          <td mat-cell *matCellDef="let element">
            <a [routerLink]="(element.entity === 'PRODUCT' ? '/product/' : '/customers/') + element.id"
              ><span class="group-name text-primary">{{ element | productName }}</span></a
            >
            <br />
            <span class="group-id text-secondary">{{ element.id }} </span>
            <span
              class="open-details text-primary"
              *ngIf="element.entity === 'PRODUCT' && allowProductDetails"
              (click)="seeDetails(element)"
              >(<span translate>PRODUCT_DETAILS</span>)</span
            >
            <div class="details" *ngIf="showDetails[element.id]">
              <div class="float-left mr-2">
                <img
                  class="card-img-top img-fluid mx-auto d-block"
                  *ngIf="showDetails[element.id].img; else noimage"
                  src="https://d27ahaa1qqlr90.cloudfront.net/fit-in/50x50/filters:fill(white):background_color(white):format(jpeg)/{{
                    details[element.id].img
                  }}"
                />
                <ng-template #noimage>
                  <img
                    class="card-img-top img-fluid w-100"
                    src="https://d27ahaa1qqlr90.cloudfront.net/fit-in/50x50/images/no-image.jpg"
                    alt="NO IMAGE"
                    class="img-fluid mx-auto d-block"
                  />
                </ng-template>
              </div>
              <span class="b">Color: </span><span translate>{{ details[element.id].color }}</span
              ><br />
              <span class="b">Size: </span><span translate>{{ details[element.id].size }}</span>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="totalQuantity">
          <th mat-header-cell mat-sort-header *matHeaderCellDef arrowPosition="before" style="width: 25%">
            <div class="d-flex flex-column">
              <div translate>PRODUCT_QUANTITY</div>
              <div class="previous-period-header" *ngIf="showPreviousTimeSpan">({{ getPreviousTimeSpanLabel() }})</div>
            </div>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.totalQuantity | number: '1.0-0' }}
            <div class="previous-period-values" *ngIf="showPreviousTimeSpan">
              ({{ previousTimespanSalesSummaryGroups[element.id].totalQuantity | number: '1.0-0' }})
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="totalAmount">
          <th mat-header-cell mat-sort-header *matHeaderCellDef arrowPosition="before" style="width: 25%">
            <div class="d-flex flex-column">
              <div translate>TOTAL_VALUE</div>
              <div class="previous-period-header" *ngIf="showPreviousTimeSpan">({{ getPreviousTimeSpanLabel() }})</div>
            </div>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.totalAmount | number: '1.0-0' }}
            <div class="previous-period-values" *ngIf="showPreviousTimeSpan">
              ({{ previousTimespanSalesSummaryGroups[element.id].totalAmount | number: '1.0-0' }})
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
    <mat-paginator [pageSize]="7" [pageSizeOptions]="[7]"></mat-paginator>
  </div>
</div>
