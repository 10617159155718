import { Component } from '@angular/core';
import { JeevesOrderActionConfigService } from 'gung-standard-jeeves';
import { GungModalService, LocationConfigService, Order, gungCheckAndDownloadFile } from 'gung-standard';
import { HttpClient } from '@angular/common/http';
import { OttoOlsenOrderNavigationService } from '../../services/otto-olsen-order-navigation.service';
import { ListItemRendererComponent } from 'gung-list';
import { OrderType } from '../../services/otto-olsen-order-list-config.service';

@Component({
  selector: 'otto-olsen-order-card-list',
  templateUrl: './otto-olsen-order-card-list.component.html',
  styleUrls: ['./otto-olsen-order-card-list.component.scss']
})
export class OttoOlsenOrderCardListComponent extends ListItemRendererComponent<Order[]> {
  public trackingUrl = ['https://sporing.bring.no/sporing/', '?lang=no'];
  adjustedScroll = false;
  public orderType = OrderType;

  constructor(
    public configService: JeevesOrderActionConfigService,
    protected locationConfigService: LocationConfigService,
    private http: HttpClient,
    private gungModalService: GungModalService,
    public orderNavigationService: OttoOlsenOrderNavigationService
  ) {
    super();
  }

  ngAfterViewInit() {

  }

  checkCoc(orderNumber, despatchNumber, element) {
    this.addRemoveSpinner(element.target);

    const filename = 'DESPATCH-' + despatchNumber;
    const url = `json/otto-despatches-external/get-cuc/${orderNumber}/${despatchNumber}`;
    gungCheckAndDownloadFile(this.http, url, filename).then(resp => {
      if (!resp) {
        this.gungModalService.openConfirmYesNoModal('ERROR', 'FILE_NOT_FOUND', {}, 'OK', null).then(_ => { });
      }
      this.addRemoveSpinner(element.target);
    });
  }

  checkDeliveryNote(ordernr, ordlevanvisnrad2, { target }) {
    this.addRemoveSpinner(target);

    const filename = 'DELIVERYNOTE-' + ordlevanvisnrad2;
    const url = `json/otto-despatches-external/get-despatch/${ordernr}/${ordlevanvisnrad2}`;
    gungCheckAndDownloadFile(this.http, url, filename)
      .then(resp => {
        if (!resp) {
          this.gungModalService.openConfirmYesNoModal('ERROR', 'FILE_NOT_FOUND', {}, 'OK', null).then(_ => { });
        }
        this.addRemoveSpinner(target);
      })
      .catch(_ => this.addRemoveSpinner(target));
  }

  addRemoveSpinner(htmlElement: HTMLElement) {
    if (!htmlElement) {
      return;
    }
    const spinnerHtml = '<i class="fa fa-spinner fa-spin ml-1"></i>';
    const oemHtml: string = htmlElement.innerHTML;
    if (oemHtml.includes(spinnerHtml)) {
      // remove spinner
      htmlElement.innerHTML = oemHtml.slice(0, -spinnerHtml.length);
      if (htmlElement instanceof HTMLInputElement) {
        (htmlElement as HTMLInputElement).disabled = false;
      }
    } else {
      // add spinner
      htmlElement.innerHTML += spinnerHtml;
      if (htmlElement instanceof HTMLInputElement) {
        (htmlElement as HTMLInputElement).disabled = true;
      }
    }
  }

  public getOrderIds(orders: Order[]): string[] {
    return orders.map(o => o.id);
  }
}
