<div class="table-responsive-md" *ngIf="mappedData; else loading">
  <table class="table table-striped">
    <thead class="thead-sticky">
      <tr>
        <th
          class="pointer text-nowrap text-left"
          (click)="sortColumn(dynamicColumns[dynamicColumns.length - 1])"
          translate
        >
          PRODUCT
          <i
            *ngIf="dynamicColumns[dynamicColumns.length - 1].sort"
            class="fas"
            [ngClass]="{
              'fa-sort-amount-down': dynamicColumns[dynamicColumns.length - 1].sort === 'asc',
              'fa-sort-amount-up': dynamicColumns[dynamicColumns.length - 1].sort === 'desc'
            }"
          ></i>
        </th>
        <th
          *ngFor="let headerKey of dynamicColumns"
          class="pointer text-center"
          (click)="sortColumn(headerKey)"
          translate
          [class.d-none]="!headerKey.isDisplay && !headerKey.isDisplayList && !headerKey.isDisplayGrid"
        >
          {{ headerKey.translationKey | translate }}
          <i
            *ngIf="headerKey.sort"
            class="fas"
            [ngClass]="{
              'fa-sort-amount-down': headerKey.sort === 'asc',
              'fa-sort-amount-up': headerKey.sort === 'desc'
            }"
          ></i>
        </th>
        <!-- <th
          *ngIf="!isAnonymous"
          class="text-nowrap text-right pointer"
          (click)="sortColumn(dynamicColumns[dynamicColumns.length - 2])"
          translate
        >
          AVAILABILITY
          <i
            *ngIf="dynamicColumns[dynamicColumns.length - 2].sort"
            class="fas"
            [ngClass]="{
              'fa-sort-amount-down': dynamicColumns[dynamicColumns.length - 2].sort === 'asc',
              'fa-sort-amount-up': dynamicColumns[dynamicColumns.length - 2].sort === 'desc'
            }"
          ></i>
        </th> -->
        <th class="text-center" translate>PRICE</th>
        <th class="text-nowrap text-center pointer"
          (click)="sortColumn(dynamicColumns[dynamicColumns.length - 2])"
          translate>
          AVAILABILITY
          <i
            *ngIf="dynamicColumns[dynamicColumns.length - 2].sort"
            class="fas"
            [ngClass]="{
              'fa-sort-amount-down': dynamicColumns[dynamicColumns.length - 2].sort === 'asc',
              'fa-sort-amount-up': dynamicColumns[dynamicColumns.length - 2].sort === 'desc'
            }"
          ></i>
        </th>
        <th *ngIf="!isAnonymous"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of mappedData; index as i">
        <td [attr.data-label]="'PRODUCT' | translate">
          <a [routerLink]="['./', data.product.id]" class="product-link text-nowrap">
            <span class="d-block font-weight-bold text-nowrap">
              {{ data.product?.extra.ar.artnr || data.product?.id }}
            </span>
            <b
              *ngIf="
                selectedCustomer &&
                data.product.extra._customerCrossReferences &&
                data.product.extra._customerCrossReferences[selectedCustomer.id]
              "
              class="d-block small"
            >
              {{ data.product.extra._customerCrossReferences[selectedCustomer.id].referenceId }}
            </b>
          </a>
          <a [routerLink]="['./', data.product.id]" class="product-link text-nowrap">
            {{ data.product | productName }}
          </a>
        </td>
        <td
          [attr.data-label]="dynamicColumns[i].translationKey | translate"
          *ngFor="let value of data.product.dynamicCollumnsValues; let i = index"
          class="text-center text-nowrap"
          [class.d-none]="
            !dynamicColumns[i].isDisplay && !dynamicColumns[i].isDisplayList && !dynamicColumns[i].isDisplayGrid
          "
        >
          <span>{{ value }}</span>
        </td>
        <!-- <td *ngIf="!isAnonymous" [attr.data-label]="'AVAILABILITY' | translate" class="text-right text-nowrap">
          <otto-olsen-availability
            [availability]="data.availability"
            [unit]="data.product.extra.ar?.enhetskod"
            [decimals]="data.product?.extra.ar.antdec"
            [productionDays]="
              data.product?.extra.ar?.anskaffningssatt &&
              data.product?.extra.ar?.anskaffningssatt * 1 === 12 &&
              data.product?.extra.ar?.artgenomloppstid
            "
          ></otto-olsen-availability>
        </td> -->
        <td [attr.data-label]="'PRICE' | translate" width="1%">
          <div
            class=""
            *ngIf="
              isAnonymous ||
                (!(
                  data.product.extra.pim &&
                  data.product.extra.pim.pricerequest &&
                  data.product.extra.pim.pricerequest !== 'false'
                ) &&
                  !(data.product.extra.ar.itemtypecd1 === 0 || data.product.extra.ar.itemtypecd1 === 7))
            "
          >
          
            <div *ngIf="!isAnonymous && data.price" class="text-center">
              <otto-olsen-price-list-display [price]="data.price" [product]="data.product"></otto-olsen-price-list-display>              
            </div>
           
         
          </div>

        </td>
        <td class="" width="1%" [attr.data-label]="'' | translate">
          <div
            class=""
            *ngIf="
              isAnonymous ||
                (!(
                  data.product.extra.pim &&
                  data.product.extra.pim.pricerequest &&
                  data.product.extra.pim.pricerequest !== 'false'
                ) &&
                  !(data.product.extra.ar.itemtypecd1 === 0 || data.product.extra.ar.itemtypecd1 === 7));
              else priceRequest
            "
          >
            <!-- <span *ngIf="data.price" class="d-block text-center">
              <lib-price [price]="data.price" type="GROSS"></lib-price>
              <span *ngIf="data.price.customerDiscountPercent !== 0">(-{{ data.price.customerDiscountPercent }}%)</span>
              <span
                *ngIf="
                  data.price.customerDiscountPercent === 0 &&
                  Number(data.product.extra.ar.artlistpris) !== Number(data.price.backendPriceLevel.price)
                "
                >({{ 'NET_PRICE' | translate }})</span
              >
            </span> -->
          
            <otto-olsen-buy-btn
              name="buy-btn-package-size"
              class="height-30 ml-auto"
              style="width: 150px"
              [id]="data.product?.id"
              [minimumOrderQuantity]="data.product?.firstStepAmount || data.product?.packageSize"
              [stepAmount]="data.product?.packageSize"
              [decimals]="data.product?.extra.ar.antdec"
              [activeInB2B]="data?.product?.extra?.pim?.activeInB2B"
              [product]="data?.product"
              [originalSize]="data.product?.originalSize"
              [unit]="data.product?.extra.ar?.enhetskod"
            >
            </otto-olsen-buy-btn>
            <div class="text-center" *ngIf="!isAnonymous">
              <otto-olsen-availability
            [availability]="data.availability"
            [unit]="data.product.extra.ar?.enhetskod"
            [decimals]="data.product?.extra.ar.antdec"
            [productionDays]="
              data.product?.extra.ar?.anskaffningssatt &&
              data.product?.extra.ar?.anskaffningssatt * 1 === 12 &&
              data.product?.extra.ar?.artgenomloppstid
            "
          ></otto-olsen-availability>
            </div>
            
          </div>
          <ng-template #priceRequest>
            <a
              href="mailto:{{ requestPriceEmail }}?subject=Forespørsel {{ data.product.id }}"
              target="_blank"
              class="request-price-email"
            >
              <button type="button" class="btn btn-block btn-sm btn-primary ml-auto" style="width: 150px" translate>
                ASK_PRICE
              </button>
            </a>
          </ng-template>
        </td>
        <td *ngIf="!isAnonymous" class="fit-column" width="1%">
          <otto-olsen-favourite-product-icon-only
            class="favourite-element"
            (click)="$event.preventDefault(); $event.stopPropagation()"
            [id]="data.product.id"
          ></otto-olsen-favourite-product-icon-only>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<ng-template #loading>
  <i class="fa fa-spinner fa-spin fa-7x"></i>
</ng-template>
