import { ChangeDetectorRef, Component, ComponentFactoryResolver, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import {
  ProductsInCartComponent,
  CartService,
  NavbarCartService,
  SaveCartModalConfigService,
  PriceService,
  ProductService,
  BarcodeScannerConfigService,
  GungModalService,
  AvailabilityService,
  CartRow,
  BaseViewConfigService,
  ProductInputQuantityConfigService,
  Product,
  AuthService,
  Availability,
  SimplifiedAvailability
} from 'gung-standard';
import { forkJoin, of, filter, first, mergeMap } from 'rxjs';
import { OttoOlsenProductAssortmentParentService } from '../../../services/otto-olsen-product-assortment-parent.service';
import { OttoOlsenScannerSearchComponent } from '../otto-olsen-scanner-search/otto-olsen-scanner-search.component';
import { OttoOlsenBarcodeScannerModalService } from '../../../services/otto-olsen-barcode-scanner-modal.service';
import { DateUtilService } from 'gung-common';
import { fnProductName } from '../../../pipes/product-name.pipe';

@Component({
  selector: 'otto-olsen-products-in-cart',
  templateUrl: './otto-olsen-products-in-cart.component.html',
  styleUrls: ['./otto-olsen-products-in-cart.component.scss']
})
export class OttoOlsenProductsInCartComponent extends ProductsInCartComponent implements OnInit {
  public number = n => Number(n);
  roles: string[];

  constructor(
    protected cartService: CartService,
    protected navbarCartService: NavbarCartService,
    protected saveCartModalConfigService: SaveCartModalConfigService,
    protected priceService: PriceService,
    protected productService: ProductService,
    protected modalService: NgbModal,
    protected translateService: TranslateService,
    protected barcodeScannerConfigService: BarcodeScannerConfigService,
    protected componentFactoryResolver: ComponentFactoryResolver,
    protected changeDetectorRef: ChangeDetectorRef,
    gungModalService: GungModalService,
    protected availabilityService: AvailabilityService,
    protected productAssortmentParentService: OttoOlsenProductAssortmentParentService,
    protected router: Router,
    protected baseViewConfigService: BaseViewConfigService,
    protected productInputQuantityConfigService: ProductInputQuantityConfigService,
    protected authService: AuthService, protected ottoOlsenBarcodeScannerModalService: OttoOlsenBarcodeScannerModalService,
    protected dateUtilService: DateUtilService
  ) {
    super(
      cartService,
      navbarCartService,
      saveCartModalConfigService,
      priceService,
      productService,
      modalService,
      translateService,
      barcodeScannerConfigService,
      componentFactoryResolver,
      changeDetectorRef,
      gungModalService,
      baseViewConfigService
    );
  }

  ngOnInit(): void {
    this.authService.getRoles().pipe(first()).subscribe(roles => {
      this.roles = roles;
    });

    this.cartService
      .getCurrentCart()
      .pipe(
        mergeMap(productsInCart => {
          const idsToGetPrice = [];
          productsInCart = JSON.parse(JSON.stringify(productsInCart));

          if (this.productsInCart) {
            // Get if product details is open
            productsInCart = productsInCart.map(p => {
              const prod = this.productsInCart.find(c => c.productId === p.productId);
              if (!!prod) {
                p.extra._showDetails = prod.extra._showDetails;
              }
              return p;
            });
          }
          for (const productInCart of productsInCart) {
            const index = this.porductPrices.findIndex(p => p.productId === productInCart.productId);
            if (index < 0) {
              idsToGetPrice.push(productInCart.productId);
            }
          }
          return forkJoin([
            of(productsInCart),
            this.priceService.getCurrentCustomerPrices(idsToGetPrice).pipe(first()),
            this.availabilityService.getAvailabilities(idsToGetPrice).pipe(first()),
            this.productAssortmentParentService.postProductAssortmentParentByProductIds(idsToGetPrice).pipe(
              filter(assortmentParent => !!assortmentParent),
              first()
            )
          ]);
        }),
        mergeMap(([productsInCart, prices, availabilities, productAssortmentParent]) => {
          productsInCart.map(p => {
            p.extra.price = prices.find(price => price.productId === p.productId);
            p.extra.availabilities = availabilities.find(av => av.productId === p.productId);
            const tempPath = productAssortmentParent && productAssortmentParent[p.productId];
            const _path = (tempPath && tempPath.map(t => t.id)) || [''];
            if (_path.length <= 1) {
              _path.splice(0, 1, '/product');
            } else {
              _path.splice(0, 1, '/articles');
            }
            if (!_path.includes(p.productId)) {
              _path.push(p.productId);
            }
            p.extra._path = _path;
          });
          const productIds = productsInCart.map(p => p.productId);
          return forkJoin([of(productsInCart), this.productService.getFullProductsByIds(productIds).pipe(first())]);
        }),
        mergeMap(([productsInCart, products]) => {
          productsInCart.map(p => {
            p.extra.product = products.find(allP => allP.id === p.productId);
          });
          return of(productsInCart);
        })
      )
      .subscribe(productsInCart => {
        this.productsInCart = productsInCart.reverse();
        this.amountFurniture = 0;
        this.calculateTotals();
      });
    const sub = this.navbarCartService.getContents().subscribe(contents => {
      this.cartContents = contents;
      this.cartTotalRows = contents.rows.length;
      this.cartTotalItems = contents.rows.map(row => row.cartRow.qty).reduce((acc, curr) => acc + curr, 0);
    });

    this.subs.push(sub);
  }

  clickDetails(product: CartRow) {
    product.extra._showDetails = !product.extra._showDetails;
  }

  trackByProductsInCart(index: number, item: CartRow) {
    return item.productId + item.productPartialId;
  }

  searchProduct() {
    this.modalService.open(OttoOlsenScannerSearchComponent, { size: 'md' });
  }

  goToDetails(product) {
    const assortmentParentPath = product.extra.product?.extra?.assortmentParentPath;
    if (!assortmentParentPath || assortmentParentPath === undefined) {
      this.router.navigate([
        '/mobile-product-detail/' + '/' + product.productId
      ]);
    } else {
      this.router.navigate([
        '/mobile-product/' + product.extra.product.extra.assortmentParentPath + '/' + product.productId
      ]);
    }

  }

  add(product: Product, availability: Availability | SimplifiedAvailability, qty: number, qtyToAdd: number) {
    if (!this.productInputQuantityConfigService.getAllowPurchaseOverAvailability(qty + qtyToAdd, availability, product, this.roles)) {
      return;
    }
    this.cartService.addToCart(product.id, qtyToAdd, undefined, undefined, undefined, undefined, undefined, fnProductName(product));
  }

  subtract(product: Product, availability: Availability | SimplifiedAvailability, qty: number, qtyToSubtract: number) {
    this.cartService.subtractFromCart(product.id, qtyToSubtract);
  }

  remove(product: Product, qtyToSubtract: number) {
    this.cartService.subtractFromCart(product.id, qtyToSubtract);
  }

  openErrorModal(product: Product, availability: Availability, qty: number) {
    const qtyToAdd = qty - availability.currentStock;
    this.ottoOlsenBarcodeScannerModalService.openErrorModal(qtyToAdd, availability.currentStock, availability, product);
  }

  getavailabelDate(availabilities: Availability, qty: number) {
    for (const date in availabilities.extra.availabilities) {
      if (Object.prototype.hasOwnProperty.call(availabilities.extra.availabilities, date)) {
        const stock = availabilities.extra.availabilities[date];
        if (stock > qty) {
          return this.dateUtilService.getFormattedDateString(this.dateUtilService.parseDate(date, 'yyMMdd'), 'dd.MM.yyyy');
        }
      }
    }
  }
}
