import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Cart, CartService, CartsService, SelectedCustomerService, AuthService, GungFlowService, CartRow, ProductService, NavigationConfig } from 'gung-standard';
import { Observable, first, map, forkJoin, switchMap, of } from 'rxjs';
const version = 'V2.0-20211015';
@Injectable({
  providedIn: 'root'
})
export class OttoOlsenCartService extends CartsService {

  constructor(
    protected http: HttpClient,
    protected cartService: CartService,
    protected selectedCustomerService: SelectedCustomerService,
    protected gungFlowService: GungFlowService,
    protected authService: AuthService,
    protected productService: ProductService,
    @Optional()
    @Inject('environment')
    protected environment: NavigationConfig,
  ) {
    super(http, cartService, selectedCustomerService, gungFlowService, environment);
  }

  getCarts(): Observable<Cart[]> {
    const headers = {
      maxAge: '-1'
    };


    return this.selectedCustomerService.getSelectedCustomer().pipe(
      switchMap((selectedCustomer) => {
        return this.http.get<Cart[]>(`json/carts/otto-olsen/by-user-and-customer-id/${selectedCustomer.id}?frontendVersion=ANGULAR2`, { headers }).pipe(
          first(),
          map(carts =>
            carts
              .filter(cart => cart.extra.tag !== 'QUOTE')
              .map(cart => {
                if (cart.version !== version) {
                  cart = this.potentiallyMapFromOldStructure(cart);
                }
                if (!cart.data.rows && !!cart.extra.rows) {
                  cart.data.rows = cart.extra.rows;
                  delete cart.extra.rows;
                }
                return cart;
              })
          )
        );
      })
    );
  }

  getSaveCartExtra(): Observable<any> {
    return this.authService.getCurrentUser().pipe(
      first(),
      switchMap(user => {
        const extra = {
          created: {
            by: user.name,
            at: new Date().getTime()
          }
        };
        return of(extra);
      })
    )
  }

  saveCart(name: string, extra?: any): Observable<Cart> {
    const url = 'json/carts';
    return forkJoin({
      customer: this.selectedCustomerService.getSelectedCustomer().pipe(first()),
      rows: this.cartService.getCurrentCart().pipe(first()),
      currentFlow: this.gungFlowService.getSelectedFlow().pipe(first()),
      saveExtra: this.getSaveCartExtra().pipe(first()),
    }).pipe(
      switchMap(({ customer, rows, currentFlow, saveExtra }) => forkJoin({
        customer: of(customer),
        rows: of(rows),
        currentFlow: of(currentFlow),
        saveExtra: of(saveExtra),
        products: this.productService.getProductsByIds(rows.map(r => r.productId)).pipe(first())
      })),
      switchMap(({ customer, rows, currentFlow, saveExtra, products }) => {
        const filteredRows: CartRow[] = [];
        for (const product of products.filter(p => p.extra?.ar?.webarttext !== 'configure')) {
          const row = rows.find(r => r.productId === product.id);
          filteredRows.push(row);
        }
        return this.http.post<Cart>(url, { name, version, customerId: customer.id, data: { rows: filteredRows }, extra: { ...extra, ...saveExtra }, flowId: currentFlow.id });
      })
    );
  }
}
