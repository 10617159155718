import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { first, switchMap, map, mergeMap, filter } from 'rxjs';
import { SearchRequest, SearchResult } from 'gung-list';
import {
  ProductService,
  GungFlowService,
  AuthService,
  Product,
  AssortmentService,
  Assortment,
  SelectedCustomerService,
  GungBase
} from 'gung-standard';
import { OttoOlsenProductAssortmentParentService } from './otto-olsen-product-assortment-parent.service';
import { User } from 'gung-standard/lib/state/auth/types';
import { ProductConcept } from './otto-olsen-product-list-config.service';
import { ProductExtended } from '../components/otto-olsen-product-details/otto-olsen-product-details.component';

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenProductService extends ProductService {
  constructor(
    protected http: HttpClient,
    protected gungFlowService: GungFlowService,
    protected authService: AuthService,
    protected selectedCustomerService: SelectedCustomerService,
    protected ottoOlsenProductAssortmentParentService: OttoOlsenProductAssortmentParentService,
    private assortmentService: AssortmentService
  ) {
    super(http, gungFlowService, authService, selectedCustomerService);
  }

  public getProductsByAssortment(assortmentId: string): Observable<Product[]> {
    return super.getProductsByAssortment(assortmentId).pipe(
      first(),
      switchMap(products => this.mergeAssortmentsIntoProducts(products))
    );
  }

  public getProductsByIds(productIds: string[]): Observable<Product[]> {
    return super.getProductsByIds(productIds).pipe(
      first(),
      switchMap(products => this.mergeAssortmentsIntoProducts(products))
    );
  }

  public getFullProductsByIds(productIds: string[]): Observable<Product[]> {
    return super.getFullProductsByIds(productIds).pipe(
      first(),
      switchMap(products => this.mergeAssortmentsIntoProducts(products))
    );
  }

  public getProduct(productId: string, noCache?: boolean): Observable<Product> {
    return super.getProduct(productId, noCache).pipe(
      first(),
      switchMap(product => this.mergeAssortmentsIntoProducts([product])),
      map(products => products[0])
    );
  }

  public getPagedProducts(searchRequest: SearchRequest): Observable<SearchResult<Product>> {
    return super.getPagedProducts(searchRequest).pipe(
      first(),
      map(res => {
        return {
          ...res,
          items: res.items.map(product => this.productMap(product))
        };
      }),
      mergeMap(res => {
        const ids = res.items.map(p => p.id);
        return forkJoin([
          of(res),
          // this.ottoOlsenProductAssortmentParentService.getProductAssortmentParentPathByCustomerAssortment()
          //   .pipe(
          //     filter(assortmentParent => !!assortmentParent),
          //     first()
          //   )
          this.ottoOlsenProductAssortmentParentService
            .postProductAssortmentParentByProductIds(ids /* , searchRequest.assortment */)
            .pipe(
              filter(assortmentParent => !!assortmentParent),
              first()
            )
        ]);
      }),
      switchMap(([res, productAssortmentParent]) => {
        if (productAssortmentParent && Object.keys(productAssortmentParent).length !== 0) {
          res.items.map(p => {
            if (productAssortmentParent[p.id]) {
              const paths = productAssortmentParent[p.id].map(path => path.id);
              paths.shift();
              p.extra.assortmentParentPath = paths.join('/');
            }
            return p;
          });
        }
        return of(res);
      })
    );
  }

  private mergeAssortmentsIntoProducts(protuctsToMap: Product[]): Observable<Product[]> {
    return of(protuctsToMap).pipe(
      first(),
      switchMap(products => {
        const ids = products.filter(p => p !== null).map(p => p.id);
        return forkJoin([
          of(products),
          // this.ottoOlsenProductAssortmentParentService.getProductAssortmentParentPathByCustomerAssortment()
          //   .pipe(
          //     filter(assortmentParent => !!assortmentParent),
          //     first()
          //   )
          this.ottoOlsenProductAssortmentParentService.postProductAssortmentParentByProductIds(ids).pipe(
            filter(assortmentParent => !!assortmentParent),
            first()
          )
        ]);
      }),
      switchMap(([products, productAssortmentParent]) => {
        const assortmentIds: string[] = [];

        if (productAssortmentParent && Object.keys(productAssortmentParent).length !== 0) {
          products.map(p => {
            if (!p) {
              return;
            }
            if (productAssortmentParent[p.id]) {
              const paths = productAssortmentParent[p.id].map(path => path.id);
              paths.shift();
              p.extra.assortmentParentPath = paths.join('/');

              const assortmentPaths = paths.filter(path => path.startsWith('s')); // assortment
              if (assortmentPaths.length > 0) {
                const productAssortmentId = assortmentPaths[assortmentPaths.length - 1]; // get last
                p.extra.parentAssortmentId = productAssortmentId;
                assortmentIds.push(productAssortmentId);
              }
            }
            return p;
          });
        }
        return of({ products, uniqueAssortmentIds: Array.from(new Set(assortmentIds)) });
      }),
      switchMap(data =>
        forkJoin({
          products: of(data.products),
          assortments:
            data.uniqueAssortmentIds && data.uniqueAssortmentIds.length > 0
              ? forkJoin(
                  data.uniqueAssortmentIds.map(assortmentId =>
                    this.assortmentService.getAssortment(assortmentId).pipe(first())
                  )
                )
              : of([] as Assortment[])
        })
      ),
      map(({ products, assortments }) => {
        for (const product of products) {
          if (!product) {
            continue;
          }

          if (
            !product.extra.images ||
            product.extra.images.length === 0 ||
            product.extra.images[0].s3Uri === 'images/no-image.jpg'
          ) {
            const assortment = assortments.find(assort => assort.id === product.extra.parentAssortmentId);

            if (assortment && assortment.extra.images && assortment.extra.images.length > 0) {
              product.extra.images = assortment.extra.images;
            }
          }
        }

        return products;
      })
    );
  }

  getSearchAssortments(searchTerm: string, assortmentId?: string): Observable<SearchAssortments> {
    let requestAssortment;
    if (!!assortmentId) {
      requestAssortment = of({ assortment: assortmentId });
    } else {
      requestAssortment = this.authService.getCurrentUser().pipe(first());
    }
    return requestAssortment.pipe(
      switchMap((user: User) => {
        const url = `/json/otto/search-assortments/${user.assortment}?query=${searchTerm}`;
        let headers = new HttpHeaders();
        headers = headers.set('maxAge', '1800');
        return this.http.get<SearchAssortments>(url, { headers });
      })
    );
  }

  getProductByLength(
    productId: string,
    quantity: number,
    length: number,
    konfigartnr: string
  ): Observable<{ itemno: string; itemprice: number }> {
    const url = `json/otto-olsen/calculate-length-config-product`;

    return this.selectedCustomerService.getSelectedCustomer().pipe(
      switchMap(customer => {
        const body = {
          templateItem: konfigartnr,
          length,
          quantity,
          customerId: customer.id,
          baseItem: productId
        };

        return this.http.post<{ itemno: string; itemprice: number }>(url, body);
      })
    );
  }

  protected productMap(product: ProductExtended): ProductExtended {
    product = super.productMap(product);
    // Product package size
    if (
      product.extra.ar?.q_jis_fast_pakke_strl &&
      product.extra.ar.artfsgforp &&
      Number(product.extra.ar.artfsgforp) > 0
    ) {
      product.packageSize = Number(product.extra.ar.artfsgforp);
    }
    // Product original size without cutting
    if (product.extra.ar?.artfsgforp && !isNaN(Number(product.extra.ar.artfsgforp))) {
      product.originalSize = Number(product.extra.ar.artfsgforp);
    }
    if (product.extra.ar?.q_salesbatchsize && !isNaN(Number(product.extra.ar.q_salesbatchsize))) {
      product.packageSize = Number(product.extra.ar.q_salesbatchsize);
    }
    if (product.extra.ar?.q_salesmoq && !isNaN(Number(product.extra.ar.q_salesmoq))) {
      product.firstStepAmount = Number(product.extra.ar.q_salesmoq);
    }
    return product;
  }
}

export interface SearchAssortments {
  assortments: { assortment: Assortment; path: GungBase[] }[];
  concepts: { concept: ProductConcept; path: GungBase[] }[];
}
