import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService, Availability, CartService, Product } from 'gung-standard';
import { filter, first } from 'rxjs';

@Component({
  selector: 'otto-olsen-barcode-scanner-error-modal',
  templateUrl: './otto-olsen-barcode-scanner-error-modal.component.html',
  styleUrl: './otto-olsen-barcode-scanner-error-modal.component.scss'
})
export class OttoOlsenBarcodeScannerErrorModalComponent {
  @Input()
  public qtyToAdd: number;

  @Input()
  public currentQty: number;

  @Input()
  public currentStock: number;

  @Input()
  public unit: string = 'stk';

  @Input()
  public availability: Availability;

  @Input()
  public product: Product;

  @Input()
  public endOfLife: boolean;

  public isSales = false;

  constructor(
    public activeModal: NgbActiveModal,
    protected authService: AuthService,
    protected cartService: CartService
  ) {
    this.authService.getRoles().pipe(filter(roles => !roles), first()).subscribe(roles => this.isSales = roles.indexOf('SALES') > -1);
  }

  reduceStock() {
    this.cartService.setProductQuantity(this.product.id, this.currentStock);
    this.activeModal.close(this.currentStock);
  }

  delete() {
    this.cartService.setProductQuantity(this.product.id, 0);
    this.activeModal.close(0);
  }

  keepQuantiy() {
    this.cartService.setProductQuantity(this.product.id, this.currentQty + this.qtyToAdd);
    this.activeModal.close(this.currentQty + this.qtyToAdd);
  }
}
