<div class="row" [ngClass]="{ 'mb-4': mappedData && mappedData.length > 0 }">
  <div *ngFor="let data of mappedData" class="col-12 col-sm-6 col-md-4 col-lg-3 mb-2">
    <div class="card product-card" [routerLink]="['./', data.product.id]">
      <div class="card-body">
        <div class="image-wrapper cursor-pointer">
          <img
            *ngIf="data.product.extra.images && data.product.extra.images[0] as image; else noimage"
            class="img-fluid mx-auto d-block"
            src="https://d27ahaa1qqlr90.cloudfront.net/fit-in/250x250/filters:fill(white):background_color(white):etag({{
              image.s3ETag
            }}):format(jpeg)/{{ image.s3Uri }}"
            alt="{{ image.description }}"
          />
          <ng-template #noimage>
            <img
              class="img-fluid w-100"
              src="https://d27ahaa1qqlr90.cloudfront.net/fit-in/300x300/images/no-image.jpg"
              alt="NO IMAGE"
              class="img-fluid mx-auto d-block"
            />
          </ng-template>
        </div>
        <div class="product-text px-3">
          <div>{{ data.product.id }}</div>
          <b
            *ngIf="
              selectedCustomer &&
              data.product.extra._customerCrossReferences &&
              data.product.extra._customerCrossReferences[selectedCustomer.id]
            "
            class="d-block small"
          >
            {{ data.product.extra._customerCrossReferences[selectedCustomer.id].referenceId }}
          </b>
          <div class="text-truncate cursor-pointer" translate>{{ data.product | productName }}</div>
          <div class="small">
            <span
              class="d-block text-truncate"
              *ngFor="let value of data.product?.dynamicCollumnsValues; let i = index"
            >
              <!-- <b translate>{{ dynamicColumns[i].translationKey }}:</b>  -->{{ value }}
            </span>
          </div>
          <div
            class=""
            *ngIf="
              isAnonymous ||
                (!(
                  data.product.extra.pim &&
                  data.product.extra.pim.pricerequest &&
                  data.product.extra.pim.pricerequest !== 'false'
                ) &&
                  !(data.product.extra.ar.itemtypecd1 === 0 || data.product.extra.ar.itemtypecd1 === 7))
            "
          >
          
            <div *ngIf="!isAnonymous && data.price" class="text-center">
              <otto-olsen-price-list-display [price]="data.price" [product]="data.product"></otto-olsen-price-list-display>              
            </div>
           
         
          </div>
          <otto-olsen-availability
            [availability]="data.availability"
            [isSales]="isSales"
            *ngIf="!isAnonymous && includeAvailability"
            [unit]="data.product.extra.ar?.enhetskod"
            [decimals]="data.product?.extra.ar.antdec"
            [productionDays]="data.product?.extra.ar?.anskaffningssatt && (data.product?.extra.ar?.anskaffningssatt * 1) === 12 && data.product?.extra.ar?.artgenomloppstid"
            (click)="$event.preventDefault(); $event.stopPropagation()"
          ></otto-olsen-availability>
          <div class="product-card-button py-2 px-4 text-center">
            <div
              *ngIf="
                isAnonymous ||
                  (!(
                    data.product.extra.pim &&
                    data.product.extra.pim.pricerequest &&
                    data.product.extra.pim.pricerequest !== 'false'
                  ) &&
                    !(data.product.extra.ar.itemtypecd1 === 0 || data.product.extra.ar.itemtypecd1 === 7));
                else priceRequest
              "
            >
              <otto-olsen-buy-btn
                name="buy-btn-package-size"
                [id]="data.product?.id"
                [unit]="data.product.extra?.ar?.enhetskod"
                [minimumOrderQuantity]="data.product?.firstStepAmount || data.product?.packageSize"
                [stepAmount]="data.product?.packageSize"
                [originalSize]="data.product?.originalSize"
                [decimals]="data.product?.extra.ar.antdec"
                [activeInB2B]="data?.product?.extra?.pim?.activeInB2B"
                [product]="data?.product"
                (click)="$event.preventDefault(); $event.stopPropagation()"
              ></otto-olsen-buy-btn>
            </div>
            <ng-template #priceRequest>
              <a
                href="mailto:{{ requestPriceEmail }}?subject=Forespørsel {{ data.product.id }}"
                target="_blank"
                class="request-price-email"
              >
                <button type="button" class="btn btn-block btn-sm btn-primary" translate>ASK_PRICE</button>
              </a>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
