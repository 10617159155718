import { Component } from '@angular/core';
import { HomeAssortmentNewTemplateComponent } from 'gung-standard';

@Component({
  selector: 'otto-olsen-home-assortment-new-template',
  templateUrl: './otto-olsen-home-assortment-new-template.component.html',
  styleUrl: './otto-olsen-home-assortment-new-template.component.scss'
})
export class OttoOlsenHomeAssortmentNewTemplateComponent extends HomeAssortmentNewTemplateComponent {

}
