import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { OttoOlsenBarcodeScannerErrorModalComponent } from '../components/otto-olsen-barcode-scanner/otto-olsen-barcode-scanner-error-modal/otto-olsen-barcode-scanner-error-modal.component';
import { Availability, Product } from 'gung-standard';
import { OttoOlsenBarcodeScannerEndOfLifeModalComponent } from '../components/otto-olsen-barcode-scanner/otto-olsen-barcode-scanner-end-of-life-modal/otto-olsen-barcode-scanner-end-of-life-modal.component';

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenBarcodeScannerModalService {
  public modalRef: NgbModalRef;

  constructor(
    protected modalService: NgbModal,
  ) { }

  openErrorModal(qtyToAdd: number, currentQty: number, availability: Availability, product: Product, endOfLife = false) {
    this.modalRef = this.modalService.open(OttoOlsenBarcodeScannerErrorModalComponent, {
      size: 'md',
      backdrop: 'static'
    });

    this.modalRef.componentInstance.qtyToAdd = qtyToAdd;
    this.modalRef.componentInstance.currentQty = currentQty;
    this.modalRef.componentInstance.currentStock = availability.currentStock;
    this.modalRef.componentInstance.unit = product?.extra?.ar?.enhetskod;
    this.modalRef.componentInstance.availability = availability;
    this.modalRef.componentInstance.product = product;
    this.modalRef.componentInstance.endOfLife = endOfLife;

    this.modalRef.componentInstance.delegate = this;
    this.modalRef.result.then(result => {
      // console.log('result', result);
    });
  }

  openEndOfLifeModal(product: Product, productReplace?: Product, availability?: Availability) {
    this.modalRef = this.modalService.open(OttoOlsenBarcodeScannerEndOfLifeModalComponent, {
      size: 'md',
      backdrop: 'static'
    });

    this.modalRef.componentInstance.product = product;
    this.modalRef.componentInstance.productReplace = productReplace;
    this.modalRef.componentInstance.availability = availability;

    this.modalRef.componentInstance.delegate = this;
    return this.modalRef.result
  }
}
