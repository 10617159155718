<div class="row">
    <div class="col-12">
        @if(!productReplace) {
            <h5 [class.d-inline]="mobileModal" translate>AVAILABILITY</h5>
        } @else {
            <h5 [class.d-inline]="mobileModal" translate>STOCK_STATUS_REPLACEMENT_ITEM</h5>
        }
        @if(mobileModal) {
            <b class="ml-1 small font-weight-bold">({{'FOR_INFORMATION_ONLY' | translate}})</b>
        }
    </div>
    <div *ngFor="let stock of (warehouseStockSorted | keyvalue); let isFirst = first" class="col-12">
        <i class="fa-solid fa-box mr-1" class="{{stock.value.availability.class}}"></i>
        <b>{{stock.value.warehouses}}</b>:&nbsp;<span [innerHTML]="stock.value.availability.value | safeHtml">{{ stock.value.availability.value }}</span>
        <span *ngIf="isFirst && stock.value.availability.addicionalInfo" class="addicional-info">
            &nbsp;(<span [innerHtml]="stock.value.availability.addicionalInfo"></span>)
        </span>
        <span *ngIf="!mobileModal && stock.value.stockId === '30'" (click)="openModal()" class="ml-1 cursor-pointer text-info border-info border-bottom" translate>MORE_INFO</span>
    </div>
    <div class="col-12 mt-2" *ngIf="!mobileModal && warehouseStockSorted?.length > 1">
        <p>
            * {{'PRODUCT_DETAILS_AVAILABILITY_WAREHOUSE_DESCRIPTION' | translate : {Warehouse: warehouseStockSorted[0]?.warehouses } }}
            <br>
            <span class="small ml-1">&nbsp; {{'PRODUCT_DETAILS_AVAILABILITY_WAREHOUSE_DESCRIPTION_2' | translate }}</span>
        </p>
    </div>
</div>