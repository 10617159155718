<div *ngIf="product != null; else loading">
  <div class="modal-body">
    <div class="">
      <!-- DESCRIPTION -->
      <div class="row row-header">
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="col-xs-12 col-sm-6">
          <div class="row">
            <div class="col-12 mb-2" *ngIf="!!product.extra.images">
              <ngb-carousel
                #myCarousel
                [interval]="-1"
                [showNavigationIndicators]="false"
                [showNavigationArrows]="false"
              >
                <ng-template
                  ngbSlide
                  *ngFor="let image of product.extra.images; let i = index"
                  [id]="imageIdString + i"
                >
                  <div class="square">
                    <div class="content">
                      <div class="d-flex justify-content-center align-items-flex-start">
                        <img
                          class="img-fluid"
                          src="https://d27ahaa1qqlr90.cloudfront.net/fit-in/320x320/filters:fill(white):background_color(white):etag({{
                            image.s3ETag
                          }}):format(jpeg)/{{ image.s3Uri }}"
                          alt="{{ image.description }}"
                        />
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-carousel>
            </div>
            <div class="col-12" *ngIf="!!product.extra.images && product.extra.images.length > 1">
              <div class="scolling-images">
                <a class="scroll-left" (click)="scrollImagesLeft()" *ngIf="product.extra.images.length > 4">
                  <i class="fa fa-2x fa-caret-left"></i>
                </a>
                <div class="scroll-container row" #scrollContainer>
                  <div
                    class="col-3 d-flex justify-content-center align-items-center"
                    [ngClass]="{ 'pr-0': index == product.extra.images.length - 1 }"
                    *ngFor="let image of product.extra.images; let i = index"
                  >
                    <img
                      (click)="select(i)"
                      class="img-fluid"
                      src="https://d27ahaa1qqlr90.cloudfront.net/fit-in/75x75/filters:fill(white):background_color(white):etag({{
                        image.S3ETag
                      }}):format(jpeg)/{{ image.s3Uri }}"
                      alt="{{ image.description }}"
                    />
                  </div>
                </div>
                <a class="scroll-right" (click)="scrollImagesRight()" *ngIf="product.extra.images.length > 4">
                  <i class="fa fa-2x fa-caret-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-6 pt-10p">
          <h1>{{ product | productName }} - {{ product.id }}</h1>
          <!-- <p>{{ product.extra.pim[lang+'.b2bDescription'] }}</p> -->
        </div>
      </div>
      <div class="row row-specification">
        <div class="col-12">
          <lib-gung-tabset>
            <!-- ARTICLE_INFORMATION -->
            <lib-gung-tab [title]="'ARTICLE_INFORMATION' | translate">
              <ng-template libGungTabContent>
                <div class="row specification">
                  <div class="col-12 col-md-6">
                    <div class="row border-bottom">
                      <div class="col-xs-12 col-sm-6">
                        <span class="font-weight-bold" translate>NUMBER</span>
                      </div>
                      <div class="col-xs-12 col-sm-6">
                        {{ product.id }}
                      </div>
                    </div>
                    <div class="row border-bottom">
                      <div class="col-xs-12 col-sm-6">
                        <span class="font-weight-bold" translate>DESCRIPTION</span>
                      </div>
                      <div class="col-xs-12 col-sm-6">
                        <!-- {{ product.description }} -->
                        <div *ngIf="product?.description" [innerHTML]="product.description | safeHtml"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </lib-gung-tab>
            <!-- TECHNICAL_INFROMATION -->
            <lib-gung-tab [title]="'TECHNICAL_INFROMATION' | translate">
              <ng-template libGungTabContent>
                <div class="row specification">
                  <div class="col-12 col-md-6" *ngIf="product.extra.pim && product.extra.pim.countryOfOrigin">
                    <div class="row border-bottom">
                      <div class="col-xs-12 col-sm-6">
                        <span class="font-weight-bold" translate>CABEL_LENGTH</span>
                      </div>
                      <div class="col-xs-12 col-sm-6">
                        {{ product.extra.pim.cableLength }}
                      </div>
                    </div>
                    <div class="row border-bottom">
                      <div class="col-xs-12 col-sm-6">
                        <span class="font-weight-bold" translate>CABLE_CONNECTION</span>
                      </div>
                      <div class="col-xs-12 col-sm-6">
                        {{ product.extra.pim.cableConnection.description }}
                      </div>
                    </div>
                    <div class="row border-bottom">
                      <div class="col-xs-12 col-sm-6">
                        <span class="font-weight-bold" translate>SUSPENSION</span>
                      </div>
                      <div class="col-xs-12 col-sm-6">
                        {{ product.extra.pim.suspension.description }}
                      </div>
                    </div>
                    <div class="row border-bottom">
                      <div class="col-xs-12 col-sm-6">
                        <span class="font-weight-bold" translate>LIGHTS</span>
                      </div>
                      <div class="col-xs-12 col-sm-6">
                        {{ product.extra.pim.numberOfLights }}
                      </div>
                    </div>
                    <div class="row border-bottom">
                      <div class="col-xs-12 col-sm-6">
                        <span class="font-weight-bold" translate>TYPE_OF_SWITCH</span>
                      </div>
                      <div class="col-xs-12 col-sm-6">
                        {{ product.extra.pim.typeOfSwitch.description }}
                      </div>
                    </div>
                    <div class="row border-bottom">
                      <div class="col-xs-12 col-sm-6">
                        <span class="font-weight-bold" translate>POSITION_OF_SWITCH</span>
                      </div>
                      <div class="col-xs-12 col-sm-6">
                        {{ product.extra.pim.positionOfSwitch.description }}
                      </div>
                    </div>
                    <div class="row border-bottom">
                      <div class="col-xs-12 col-sm-6">
                        <span class="font-weight-bold" translate>IP_CLASS</span>
                      </div>
                      <div class="col-xs-12 col-sm-6">
                        {{ product.extra.pim.ipClass.description }}
                      </div>
                    </div>
                    <div class="row border-bottom">
                      <div class="col-xs-12 col-sm-6">
                        <span class="font-weight-bold" translate>SAFETY_CLASS</span>
                      </div>
                      <div class="col-xs-12 col-sm-6">
                        {{ product.extra.pim.safetyClass.description }}
                      </div>
                    </div>
                    <div class="row border-bottom">
                      <div class="col-xs-12 col-sm-6">
                        <span class="font-weight-bold" translate>SOCKET</span>
                      </div>
                      <div class="col-xs-12 col-sm-6">
                        {{ product.extra.pim.socket.description }}
                      </div>
                    </div>
                    <div class="row border-bottom">
                      <div class="col-xs-12 col-sm-6">
                        <span class="font-weight-bold" translate>WATTAGE</span>
                      </div>
                      <div class="col-xs-12 col-sm-6">
                        {{ product.extra.pim.wattage.description }}
                      </div>
                    </div>
                    <div class="row border-bottom">
                      <div class="col-xs-12 col-sm-6">
                        <span class="font-weight-bold" translate>DATA_LIGHT_SOURCES</span>
                      </div>
                      <div class="col-xs-12 col-sm-6">
                        {{ product.extra.pim.dataLightSources.description }}
                      </div>
                    </div>
                    <div class="row border-bottom">
                      <div class="col-xs-12 col-sm-6">
                        <span class="font-weight-bold" translate>DATA_TRANSFORMATOR</span>
                      </div>
                      <div class="col-xs-12 col-sm-6">
                        {{ product.extra.pim.transformerType.description }}
                      </div>
                    </div>
                    <div class="row border-bottom">
                      <div class="col-xs-12 col-sm-6">
                        <span class="font-weight-bold" translate>START_EXTRA</span>
                      </div>
                      <div class="col-xs-12 col-sm-6">
                        {{ product.extra.pim.startExtra.description }}
                      </div>
                    </div>
                    <div class="row border-bottom">
                      <div class="col-xs-12 col-sm-6">
                        <span class="font-weight-bold" translate>CONNECTING_CABLE_LENGTH</span>
                      </div>
                      <div class="col-xs-12 col-sm-6">
                        {{ product.extra.pim.connectingCableLength }}
                      </div>
                    </div>
                    <div class="row border-bottom">
                      <div class="col-xs-12 col-sm-6">
                        <span class="font-weight-bold" translate>LENGTH_BETWEEN_LIGHT_SOURCES</span>
                      </div>
                      <div class="col-xs-12 col-sm-6">
                        {{ product.extra.pim.lengthBetweenLightSources }}
                      </div>
                    </div>
                    <div class="row border-bottom">
                      <div class="col-xs-12 col-sm-6">
                        <span class="font-weight-bold" translate>LIGHT_SOURCE_COLOR</span>
                      </div>
                      <div class="col-xs-12 col-sm-6">
                        {{ product.extra.pim.lightSourceColor.description }}
                      </div>
                    </div>
                    <div class="row border-bottom">
                      <div class="col-xs-12 col-sm-6">
                        <span class="font-weight-bold" translate>PAIRED_TO_MAXIMUM</span>
                      </div>
                      <div class="col-xs-12 col-sm-6">
                        {{ product.extra.pim.pairedToMaximum.description }}
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6" *ngIf="product.extra.pim && product.extra.pim.countryOfOrigin">
                    <div class="row border-bottom">
                      <div class="col-xs-12 col-sm-6">
                        <span class="font-weight-bold" translate>LIGHT_SOURCE_INCL</span>
                      </div>
                      <div class="col-xs-12 col-sm-6">
                        {{ product.extra.pim.lightSourceIncl.description }}
                      </div>
                    </div>
                    <div class="row border-bottom">
                      <div class="col-xs-12 col-sm-6">
                        <span class="font-weight-bold" translate>MULTIFUNCTION</span>
                      </div>
                      <div class="col-xs-12 col-sm-6">
                        {{ product.extra.pim.multiFunction }}
                      </div>
                    </div>
                    <div class="row border-bottom">
                      <div class="col-xs-12 col-sm-6">
                        <span class="font-weight-bold" translate>TYPE_OF_MULTIFUNCTION</span>
                      </div>
                      <div class="col-xs-12 col-sm-6">
                        {{ product.extra.pim.typeOfMultifunction.description }}
                      </div>
                    </div>
                    <div class="row border-bottom">
                      <div class="col-xs-12 col-sm-6">
                        <span class="font-weight-bold" translate>OUTDOOR</span>
                      </div>
                      <div class="col-xs-12 col-sm-6">
                        {{ product.extra.pim.outdoor }}
                      </div>
                    </div>
                    <!-- DIMENSIONS -->
                    <div class="row border-bottom">
                      <div class="col-xs-12 col-sm-6">
                        <span class="font-weight-bold" translate>CODE</span>
                      </div>
                      <div class="col-xs-12 col-sm-6">
                        <!-- {{ product.extra.pim. }} -->
                      </div>
                    </div>
                    <div class="row border-bottom">
                      <div class="col-xs-12 col-sm-6">
                        <span class="font-weight-bold" translate>QTY_PER_UNIT_MEASURE</span>
                      </div>
                      <div class="col-xs-12 col-sm-6">
                        <!-- {{ product.extra.pim. }} -->
                      </div>
                    </div>
                    <div class="row border-bottom">
                      <div class="col-xs-12 col-sm-6">
                        <span class="font-weight-bold" translate>CUBAGE</span>
                      </div>
                      <div class="col-xs-12 col-sm-6">
                        <!-- {{ product.extra.pim. }} -->
                      </div>
                    </div>
                    <div class="row border-bottom">
                      <div class="col-xs-12 col-sm-6">
                        <span class="font-weight-bold" translate>DEPTH</span>
                      </div>
                      <div class="col-xs-12 col-sm-6">
                        <!-- {{ product.extra.pim. }} -->
                      </div>
                    </div>
                    <div class="row border-bottom">
                      <div class="col-xs-12 col-sm-6">
                        <span class="font-weight-bold" translate>HEIGHT</span>
                      </div>
                      <div class="col-xs-12 col-sm-6">
                        <!-- {{ product.extra.pim. }} -->
                      </div>
                    </div>
                    <div class="row border-bottom">
                      <div class="col-xs-12 col-sm-6">
                        <span class="font-weight-bold" translate>WIDTH_LENGTH</span>
                      </div>
                      <div class="col-xs-12 col-sm-6">
                        <!-- {{ product.extra.pim. }} -->
                      </div>
                    </div>
                    <div class="row border-bottom">
                      <div class="col-xs-12 col-sm-6">
                        <span class="font-weight-bold" translate>WEIGHT</span>
                      </div>
                      <div class="col-xs-12 col-sm-6">
                        {{ product.extra.pim.netWeight }} ({{ product.extra.pim.grossWeight }})
                      </div>
                    </div>
                    <!-- LIGHT_SOURCE_INFORMATION -->
                    <div class="row border-bottom">
                      <div class="col-xs-12 col-sm-6">
                        <span class="font-weight-bold" translate>ERP_LABEL</span>
                      </div>
                      <div class="col-xs-12 col-sm-6">
                        {{ product.extra.pim.erpLabel.description }}
                      </div>
                    </div>
                    <div class="row border-bottom">
                      <div class="col-xs-12 col-sm-6">
                        <span class="font-weight-bold" translate>ENERGY_CLASS_INC_LIGHT_SOURCE</span>
                      </div>
                      <div class="col-xs-12 col-sm-6">
                        {{ product.extra.pim.energyClassInclLightSource.description }}
                      </div>
                    </div>
                    <div class="row border-bottom">
                      <div class="col-xs-12 col-sm-6">
                        <span class="font-weight-bold" translate>COLOR_TEMPERATURE</span>
                      </div>
                      <div class="col-xs-12 col-sm-6">
                        {{ product.extra.pim.colorTemperature.description }}
                      </div>
                    </div>
                    <div class="row border-bottom">
                      <div class="col-xs-12 col-sm-6">
                        <span class="font-weight-bold" translate>SCATTERING_ANGLE</span>
                      </div>
                      <div class="col-xs-12 col-sm-6">
                        {{ product.extra.pim.scatteringAngle }}
                      </div>
                    </div>
                    <div class="row border-bottom">
                      <div class="col-xs-12 col-sm-6">
                        <span class="font-weight-bold" translate>LUMEN</span>
                      </div>
                      <div class="col-xs-12 col-sm-6">
                        {{ product.extra.pim.lumen }}
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </lib-gung-tab>
          </lib-gung-tabset>
        </div>
      </div>
      <div
        *ngIf="product.extra.pim && product.extra.pim.files && product.extra.pim.files.length > 0"
        class="row row-files"
      >
        <div class="col-12">
          <span class="font-weight-bold" translate>FILES</span>
        </div>
        <div class="col-12 table-responsive">
          <table class="table table-hover">
            <thead>
              <tr>
                <th translate>NAME</th>
                <th class="text-right" translate>DOWNLOAD_DOCUMENT</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let document of product.extra.pim.files">
                <td>
                  {{ document.docdescr }}
                </td>
                <td class="text-right">
                  <a
                    target="_NEW"
                    [href]="
                      'https://cdn2.gung.io/markslojd/' +
                      document.pdmdummyuniqueid +
                      document.documentext
                    "
                  >
                    {{ document.pdmdummyuniqueid }}{{ document.documentext }}
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row row-products">
        <div class="col-12">
          <span class="font-weight-bold" translate>RELATED_PRODUCTS</span>
        </div>
        <div class="col-xs-12 col-sm-12">
          <div *ngIf="!product.extra.pim || !product.extra.pim.relatedProducts; else pimRelatedProductsFound">
            <p translate>NO_PRODUCT_RELATED_PRODUCTS_FOUND</p>
          </div>
          <ng-template #pimRelatedProductsFound>
            <lib-product-list-by-ids [productIds]="product.extra.pim.relatedProducts.split(',')">
            </lib-product-list-by-ids>
          </ng-template>
        </div>
      </div>
      <div class="row row-products">
        <div class="col-12">
          <span class="font-weight-bold" translate>SIMILAR_PRODUCTS</span>
        </div>
        <div class="col-xs-12 col-sm-12">
          <div *ngIf="!product.extra.pim || !product.extra.pim.similarProducts; else pimSimilarProductsFound">
            <p translate>NO_PRODUCT_SIMILAR_PRODUCTS_FOUND</p>
          </div>
          <ng-template #pimSimilarProductsFound>
            <lib-product-list-by-ids [productIds]="product.extra.pim.similarProducts.split(',')">
            </lib-product-list-by-ids>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #loading> LOADING... </ng-template>
