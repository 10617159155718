import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { JeevesOrderActionConfigService } from 'gung-standard-jeeves';
import { LocationConfigService, GungModalService } from 'gung-standard';
import { OttoOlsenOrderCardListComponent } from '../otto-olsen-order-card-list/otto-olsen-order-card-list.component';
import { OttoOlsenOrderNavigationService } from '../../services/otto-olsen-order-navigation.service';
import { DateUtilService } from 'gung-common';

@Component({
  selector: 'otto-olsen-order-mobile-card-list',
  templateUrl: './otto-olsen-order-mobile-card-list.component.html',
  styleUrls: ['./otto-olsen-order-mobile-card-list.component.scss']
})
export class OttoOlsenOrderMobileCardListComponent extends OttoOlsenOrderCardListComponent {
  constructor(
    public configService: JeevesOrderActionConfigService,
    protected locationConfigService: LocationConfigService,
    http: HttpClient,
    gungModalService: GungModalService,
    public orderNavigationService: OttoOlsenOrderNavigationService,
    public dateUtilService: DateUtilService
  ) {
    super(configService, locationConfigService, http, gungModalService, orderNavigationService);
  }
}
