import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService, Availability, Customer, Product, SelectedCustomerService } from 'gung-standard';
import { filter, first } from 'rxjs';

@Component({
  selector: 'otto-olsen-barcode-scanner-end-of-life-modal',
  templateUrl: './otto-olsen-barcode-scanner-end-of-life-modal.component.html',
  styleUrl: './otto-olsen-barcode-scanner-end-of-life-modal.component.scss'
})
export class OttoOlsenBarcodeScannerEndOfLifeModalComponent {
  @Input()
  public product: Product;

  @Input()
  public productReplace?: Product;

  @Input()
  public availability: Availability;

  public unit: string = 'stk';
  public isSales = false;
  public selectedCustomer: Customer;

  constructor(
    public activeModal: NgbActiveModal,
    protected authService: AuthService,
    protected selectedCustomerService: SelectedCustomerService
  ) {
    this.authService.getRoles().pipe(filter(roles => !roles), first()).subscribe(roles => this.isSales = roles.indexOf('SALES') > -1);
    this.selectedCustomerService.getSelectedCustomer().pipe(first()).subscribe(selectedCustomer => this.selectedCustomer = selectedCustomer);
  }

  ngOnInit() {
    this.unit = this.productReplace?.extra?.ar?.enhetskod;
  }
}
