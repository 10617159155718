<div class="row">
  <div class="col-12 col-md-6 product-title">
    {{ product | productName }}
  </div>
</div>
<div class="row section mb-3" *ngFor="let section of sections; let i = index">
  <div class="col-12 col-md-4 p-5">
    <img
      *ngIf="product.extra.images && product.extra.images[i + 1] as image; else noimage"
      class="card-img-top img-fluid mx-auto d-block"
      style="width: auto"
      src="https://d27ahaa1qqlr90.cloudfront.net/fit-in/300x300/filters:fill(white):background_color(white):etag({{
        image.s3ETag
      }}):format(jpeg)/{{ image.s3Uri }}"
      alt="{{ image.description }}"
    />
    <ng-template #noimage>
      <img
        class="card-img-top img-fluid w-100"
        src="https://d27ahaa1qqlr90.cloudfront.net/fit-in/300x300/images/no-image.jpg"
        alt="NO IMAGE"
        class="img-fluid mx-auto d-block"
      />
    </ng-template>
  </div>
  <div class="col-12 col-md-8 pt-5">
    <h2 class="mt-0 text-primary">{{ section.title || 'Section ' + (i + 1) }}</h2>
    <ul>
      <li *ngIf="section.bullet1">{{ section.bullet1 }}</li>
      <li *ngIf="section.bullet2">{{ section.bullet2 }}</li>
      <li *ngIf="section.bullet3">{{ section.bullet3 }}</li>
      <li *ngIf="section.bullet4">{{ section.bullet4 }}</li>
    </ul>
    <span>{{ section.description }}</span>
    <div *ngIf="section.link1" class="d-block mt-1">
      <a class="text-primary" href="{{ section.link1 }}" target="_blank">{{ section.link1_text || 'link' }}</a>
    </div>
    <div *ngIf="section.link2" class="d-block">
      <a class="text-primary" href="{{ section.link2 }}" target="_blank">{{ section.link2_text || 'link' }}</a>
    </div>
    <div class="mt-2" *ngIf="section?.documents && section?.documents.length > 0">
      <div class="table-responsive">
        <table class="table table-borderless table-striped table-sm">
          <thead>
            <tr>
              <th scope="col" translate>DOCUMENT</th>
              <th scope="col" translate>DOWNLOAD_DOCUMENT</th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let doc of section?.documents">
              <td translate>
                {{ doc.name }}
                <i title="Internal" *ngIf="doc.extra?.permission === 'Internal'" class="fa-solid fa-house-lock"></i>
              </td>
              <td>
                <a href="{{ doc.url }}" target="_blank" translate>DOWNLOAD</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div *ngIf="sections.length - 1 === i" class="col-12">
    <div class="bg-primary p-1"></div>
  </div>
</div>
<div class="row">
  <div class="col-12 col-md-6 mt-2" *ngIf="documents?.length > 0">
    <div class="table-responsive">
      <table class="table table-borderless table-striped table-sm">
        <thead>
          <tr>
            <th scope="col" translate>DOCUMENT</th>
            <th scope="col" translate>DOWNLOAD_DOCUMENT</th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let doc of documents">
            <td translate>
              {{ doc.name }}
            </td>
            <td>
              <a href="{{ doc.url }}" target="_blank" translate>DOWNLOAD</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="col-12">
    <!-- <p>{{ product.description }}</p> -->
    <div *ngIf="product?.description" [innerHTML]="product.description | safeHtml"></div>
  </div>
  <div class="col-12 text-center h3">
    Kontakt oss [<a href="mailto:{{ email }}">{{ email }}</a
    >] | telefon [<a href="tel:{{ phone }}">{{ phone }}</a
    >]
  </div>
</div>
