import { DecimalPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { format, parse } from 'date-fns';
import {
  AvailabilityHelperService,
  AuthService,
  STOCK_STATUS,
  Availability,
  Product,
  AvailabilityDisplay,
  MetadataService
} from 'gung-standard';
import { DateUtilService } from 'gung-common';
import { Observable, of } from 'rxjs';
import { GungAnonymousConfigService } from 'gung-standard';

export interface OttoOlsenAvailabilityDisplay extends AvailabilityDisplay {
  tooltip?: string;
  addicionalInfo?: string;
}

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenAvailabilityHelperService extends AvailabilityHelperService {
  enoughMaterialInStock = false;
  firstFutureDate;
  firstFutureDateKey;
  constructor(
    protected authService: AuthService,
    protected translateService: TranslateService,
    protected dateUtilService: DateUtilService,
    private decimalPipe: DecimalPipe,
    protected gungAnonymousConfigService: GungAnonymousConfigService,
    protected metadataService: MetadataService
  ) {
    super(authService, translateService, dateUtilService, gungAnonymousConfigService, metadataService);
  }

  protected getValueFromStatusAndTooltip(
    status: STOCK_STATUS,
    isSales: boolean,
    availability: Availability,
    unit?: string,
    details?: boolean,
    decimals?: number,
    productionDays?: number
  ): { value: string; addicionalInfo?: string; tooltip?: string } {
    if (!availability) {
      return { value: '' };
    }
    let value: string;
    const tooltip: string = undefined;
    let addicionalInfo = '';
    decimals = decimals || 0;

    let hasConfirmedPurchaseRows = false;
    if (availability.extra._hasConfirmedPurchaseRows) {
      hasConfirmedPurchaseRows = true;
    }

    if (status === STOCK_STATUS.IN_STOCK) {
      // Change display stock text
      const stock = this.getValueNumber(parseFloat(availability.currentAvailability.toString()), decimals);
      if (details && this.enoughMaterialInStock) {
        // Production product detail view
        value = `${availability.extra.availabilities[this.firstFutureDateKey]} ${this.translateService
          .instant(unit || 'PCS')
          .toLowerCase()}. - ${format(this.firstFutureDate, 'dd/MM')}`;
      } else if (details) {
        value = this.translateService.instant('STOCK_STATUS_BOLD', {
          stock,
          unit: this.translateService.instant(unit || 'PCS').toLowerCase()
        });
      } else if (!productionDays) {
        value = `${stock} ${this.translateService.instant(unit || 'PCS').toLowerCase()}`;
      } else if (this.enoughMaterialInStock) {
        // Production product list view
        value = `${availability.extra.availabilities[this.firstFutureDateKey]} ${this.translateService
          .instant(unit || 'PCS')
          .toLowerCase()}. - ${format(this.firstFutureDate, 'dd/MM')}`;
      } else {
        // Production product list view
        value = `${stock} ${this.translateService.instant(unit || 'PCS').toLowerCase()}. - ${format(
          new Date(),
          'dd/MM'
        )}`;
      }

      const futureDate = Object.keys(availability.extra.availabilities).filter(
        key =>
          availability.extra.availabilities[key] > 0 &&
          this.firstFutureDateKey != key &&
          (details || availability.extra.availabilities[key] !== 100000) &&
          format(new Date(), 'yyMMdd') !== key &&
          parse(key, 'yyMMdd', new Date()) > new Date()
      );

      /*
        For instock product only show future that comes from confirmed purchases
      */
      if (hasConfirmedPurchaseRows || !details) {
        for (const firstDate of futureDate.slice(0, 1)) {
          const futureStock = availability.extra.availabilities[firstDate];
          // Change display date format
          const dFirstDate = parse(firstDate, 'yyMMdd', new Date());

          if (!!productionDays) {
            const sFirstDateShort = format(dFirstDate, 'dd/MM');
            addicionalInfo += `<br><span class="warn">
              ${this.getValueNumber(futureStock, decimals)} ${this.translateService
                .instant(unit || 'PCS')
                .toLowerCase()}. - ${sFirstDateShort}
              </span>`;
            break;
          }

          if (futureStock !== 100000 && !details) {
            const sFirstDateShort = format(dFirstDate, 'dd/MM');
            addicionalInfo +=
              '<br><span class="warn">(' +
              this.getValueNumber(futureStock, decimals) +
              ' ' +
              this.translateService.instant(unit || 'PCS').toLowerCase() +
              ' ' +
              sFirstDateShort +
              ') </span>';
          }

          if (details) {
            const sFirstDate = this.dateUtilService.getFormattedDateString(dFirstDate);
            if (futureStock !== 100000) {
              addicionalInfo +=
                '<span class="warn">' +
                this.translateService.instant('AVAILABILITY_ESTIMATED_DATE', {
                  qty: this.getValueNumber(futureStock, decimals),
                  unit: unit || 'PCS',
                  date: sFirstDate
                }) +
                '</span>';
            } else if (futureDate.length < 2) {
              // Don't show if purchase date => futureDate.length < 2
              addicionalInfo +=
                '<span class="warn">' +
                this.translateService.instant('AVAILABILITY_ESTIMATED_DATE_SHORT', { date: sFirstDate }) +
                '</span>';
            }
          } /* else {
            const sFirstDate = format(dFirstDate, 'dd/MM');
            value += '<br><span class="warn">(' + this.getValueNumber(futureStock, decimals) + ' ' + this.translateService.instant(unit || 'PCS').toLowerCase() + ' ' + sFirstDate + ') </span>';
          } */
        }
      } else if (details && !this.enoughMaterialInStock) {
        const today = this.dateUtilService.getFormattedDateString(new Date());
        addicionalInfo +=
          '<span class="warn">' +
          this.translateService.instant('AVAILABILITY_DEVIATIONS_MAY_OCCUR') +
          '</span>';
      }
    } else if (status === STOCK_STATUS.INCOMING) {
      if (productionDays && productionDays > 0) {
        value = '';
      } else {
        if (details) {
          value = this.translateService.instant('STOCK_STATUS', {
            stock: 0,
            unit: this.translateService.instant(unit || 'PCS').toLowerCase()
          });
        } else {
          value = this.translateService.instant('NOT_IN_STOCK');
        }
      }

      const futureDate = Object.keys(availability.extra.availabilities).filter(
        key =>
          availability.extra.availabilities[key] > 0 &&
          (details || availability.extra.availabilities[key] !== 100000) &&
          format(new Date(), 'yyMMdd') !== key
      );
      for (const firstDate of futureDate.slice(0, 1)) {
        // Change display date format
        const dFirstDate = parse(firstDate, 'yyMMdd', new Date());

        if (hasConfirmedPurchaseRows) {
          // const sFirstDate = format(new Date(), 'yyMMdd');
          if (availability.extra.availabilities[firstDate] === 100000) {
            continue;
          }
        }

        const futureStock = availability.extra.availabilities[firstDate];

        if (futureStock !== 100000 && !!productionDays) {
          if (productionDays < 0) {
            addicionalInfo += `<br>`;
          }
          const sFirstDateShort = format(dFirstDate, 'dd/MM');
          addicionalInfo += `<span class="warn">
            ${this.getValueNumber(futureStock, decimals)} ${this.translateService
              .instant(unit || 'PCS')
              .toLowerCase()}. - ${sFirstDateShort}
            </span>`;
          break;
        }

        if (futureStock !== 100000 && !details) {
          const sFirstDateShort = this.dateUtilService.getFormattedDateString(dFirstDate, 'dd/MM');
          addicionalInfo +=
            '<br><span class="warn">(' +
            this.getValueNumber(futureStock, decimals) +
            ' ' +
            this.translateService.instant(unit || 'PCS').toLowerCase() +
            ' ' +
            sFirstDateShort +
            ')<span>';
        }

        if (details) {
          const sFirstDate = this.dateUtilService.getFormattedDateString(dFirstDate);
          if (hasConfirmedPurchaseRows) {
            const qty = availability.extra.availabilities[firstDate] - availability.currentAvailability;
            const unitTranslated = this.translateService.instant(unit);
            addicionalInfo +=
              '<span class="text-dark">' +
              this.translateService.instant('AVAILABILITY_ESTIMATED_DATE', {
                date: sFirstDate,
                qty,
                unit: unitTranslated
              }) +
              '</span>';
          } else {
            addicionalInfo +=
              '<span class="text-dark">' +
              this.translateService.instant('AVAILABILITY_ESTIMATED_DATE_CONTACT_US', { date: sFirstDate }) +
              '</span>';
          }
        } /* else {
          const futureStock = availability.extra.availabilities[firstDate];
          const sFirstDate = format(dFirstDate, 'dd/MM');
          value += '<br><span class="warn">(' + this.getValueNumber(futureStock, decimals) + ' ' + this.translateService.instant(unit || 'PCS').toLowerCase() + ' ' + sFirstDate + ')<span>';
        } */
      }
    }
    if (status === STOCK_STATUS.OUT_OF_STOCK) {
      // value = this.translateService.instant('NOT_IN_STOCK');
      value = this.translateService.instant('STOCK_STATUS', {
        stock: 0,
        unit: this.translateService.instant(unit || 'PCS').toLowerCase()
      });
    }
    return { value, addicionalInfo, tooltip };
  }

  public getAvailabilityDisplay(
    availability: Availability,
    product: Product = null,
    unit?: string,
    details?: boolean,
    decimals?: number,
    productionDays?: number
  ): Observable<OttoOlsenAvailabilityDisplay> {
    if (!availability) {
      return of({
        value: '',
        class: {
          'd-none': true
        }
      });
    }
    const sales = this.roles.indexOf('SALES') > -1;

    this.checkIfEnoughMaterialInStock(availability, productionDays, details);

    const status: STOCK_STATUS =
      availability.currentAvailability > 0 || this.enoughMaterialInStock
        ? STOCK_STATUS.IN_STOCK
        : availability.maxFutureStock > 0
          ? STOCK_STATUS.INCOMING
          : STOCK_STATUS.OUT_OF_STOCK;

    const value = this.getValueFromStatusAndTooltip(
      status,
      sales,
      availability,
      unit,
      details,
      decimals,
      productionDays
    );
    // const value = this.getValueFromPurchase(status, sales, availability, unit, details, decimals);

    const classVal = this.classSelector(status);
    return of({
      value: value.value,
      class: classVal,
      addicionalInfo: value.addicionalInfo,
      tooltip: value.tooltip
    });
  }

  getValueNumber(value: number, decimals: number = 0): string {
    if (value) {
      return this.decimalPipe.transform(+value, '1.0-' + decimals);
      // return (+value).toFixed(decimals);
    }
    return value.toString();
  }

  getValueFromPurchase(
    status: STOCK_STATUS,
    isSales: boolean,
    availability: Availability,
    unit?: string,
    details?: boolean,
    decimals?: number
  ): { value: string; tooltip?: string } {
    if (!availability) {
      return { value: '' };
    }
    let value: string;
    const tooltip: string = undefined;
    decimals = decimals || 0;

    const hasConfirmedPurchaseRows = availability.extra?._hasConfirmedPurchaseRows;

    if (status === STOCK_STATUS.IN_STOCK) {
      // Change display stock text
      const stock = this.getValueNumber(parseFloat(availability.currentAvailability.toString()), decimals);
      if (details) {
        value = this.translateService.instant('STOCK_STATUS', {
          stock,
          unit: this.translateService.instant(unit || 'PCS').toLowerCase(),
        });
      } else {
        value = `${stock} ${this.translateService.instant(unit || 'PCS').toLowerCase()}`;
      }

      /*
        For instock product only show future that comes from confirmed purchases
      */
      if (hasConfirmedPurchaseRows) {
        const futurePurchaseDate = (availability.extra.avdata as any[]).filter(
          av => av.planlstradtyp === 'B' && av.ordrstatbeskr === 'Bekreftet Bestilling'
        );
        for (const purchase of futurePurchaseDate.slice(0, 1)) {
          const futureStock: number = purchase.ordrestant;
          // Change display date format
          const dFirstDate = new Date(purchase.ordberlevdat);
          if (details) {
            const sFirstDate = this.dateUtilService.getFormattedDateString(dFirstDate);
            if (futureStock !== 100000) {
              value +=
                '<span class="text-dark">' +
                this.translateService.instant('AVAILABILITY_ESTIMATED_DATE', {
                  qty: this.getValueNumber(futureStock, decimals),
                  unit: unit || 'PCS',
                  date: sFirstDate
                }) +
                '</span>';
            } else {
              value +=
                '<span class="text-dark">' +
                this.translateService.instant('AVAILABILITY_ESTIMATED_DATE_SHORT', { date: sFirstDate }) +
                '</span>';
            }
          } else {
            const sFirstDate = format(dFirstDate, 'dd/MM');
            value +=
              '<br>(+' +
              this.getValueNumber(futureStock, decimals) +
              ' ' +
              this.translateService.instant(unit || 'PCS').toLowerCase() +
              ' ' +
              sFirstDate +
              ')';
          }
        }
      }
    } else if (status === STOCK_STATUS.INCOMING) {
      value = this.translateService.instant('NOT_IN_STOCK');
      if (hasConfirmedPurchaseRows) {
        const futurePurchaseDate = (availability.extra.avdata as any[]).filter(
          av => av.planlstradtyp === 'B' && av.ordrstatbeskr === 'Bekreftet Bestilling'
        );
        for (const purchase of futurePurchaseDate.slice(0, 1)) {
          const futureStock: number = purchase.ordrestant;
          // Change display date format
          const dFirstDate = new Date(purchase.ordberlevdat);
          if (details) {
            const sFirstDate = this.dateUtilService.getFormattedDateString(dFirstDate);
            if (futureStock !== 100000) {
              value +=
                '<span class="text-dark">' +
                this.translateService.instant('AVAILABILITY_ESTIMATED_DATE', {
                  qty: this.getValueNumber(futureStock, decimals),
                  unit: unit || 'PCS',
                  date: sFirstDate
                }) +
                '</span>';
            } else {
              value +=
                '<span class="text-dark">' +
                this.translateService.instant('AVAILABILITY_ESTIMATED_DATE_SHORT', { date: sFirstDate }) +
                '</span>';
            }
          } else {
            const sFirstDate = format(dFirstDate, 'dd/MM');
            value +=
              '<br>(+' +
              this.getValueNumber(futureStock, decimals) +
              ' ' +
              this.translateService.instant(unit || 'PCS').toLowerCase() +
              ' ' +
              sFirstDate +
              ')';
          }
        }
      }
    } else if (status === STOCK_STATUS.OUT_OF_STOCK) {
      value = this.translateService.instant('NOT_IN_STOCK');
    }
    return { value, tooltip };
  }

  classSelector(classStatus: STOCK_STATUS): { [className: string]: boolean } {
    switch (classStatus) {
      case STOCK_STATUS.IN_STOCK:
        return { available: true };
      case STOCK_STATUS.OUT_OF_STOCK:
        return { unavailable: true };
      default:
        return { warn: true };
    }
  }

  checkIfEnoughMaterialInStock(availability: Availability, productionDays: number, details?: boolean) {
    this.enoughMaterialInStock = false;

    if (availability.currentAvailability === 0 && availability.maxFutureStock > 0 && productionDays > 0) {
      if (!availability.extra._componentsAvailable) {
        return;
      }

      const today = format(new Date(), 'yyMMdd');
      let allHasAvailabilityToday = true;
      const components = availability.extra._componentsAvailable;
      for (const avKey of Object.keys(components)) {
        const todayAv = Object.keys(components[avKey].extra.availabilities).find(a => a === today);
        if (!todayAv || components[avKey].extra.availabilities[todayAv] <= 0) {
          allHasAvailabilityToday = false;
        }
      }

      this.enoughMaterialInStock = allHasAvailabilityToday;
      if (!this.enoughMaterialInStock) {
        return;
      }

      const futureDate = Object.keys(availability.extra.availabilities).filter(
        key =>
          availability.extra.availabilities[key] > 0 &&
          (details || availability.extra.availabilities[key] !== 100000) &&
          format(new Date(), 'yyMMdd') !== key &&
          parse(key, 'yyMMdd', new Date()) > new Date()
      );
      this.firstFutureDateKey = futureDate[0];
      this.firstFutureDate = parse(futureDate[0], 'yyMMdd', new Date());
    }
  }

  getBusinessDatesCount(startDate, endDate, productionDays) {
    let count = 0;
    const curDate = new Date(startDate.getTime());
    while (curDate <= endDate) {
      const dayOfWeek = curDate.getDay();
      if (dayOfWeek !== 0 && dayOfWeek !== 6) count++;
      curDate.setDate(curDate.getDate() + 1);
    }
    return count <= productionDays;
  }
}
