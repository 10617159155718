<div class="row" *ngIf="!loadingComponent && minDate; else loading">
  <div class="col-12 col-md-4">
    <div [formGroup]="form">
      <lib-meta-select-input formControlName="levsattkod" table="x2f" metaId="levsattkod" metaDisplay="levsattbeskr"
        [optionsFilterFunction]="optionsFilterDeliveryMethod.bind(this, selectedCustomer)"
        (ngModelChange)="onDeliveryMethodChanged($event)">
      </lib-meta-select-input>
      <div *ngIf="form.get('levsattkod').invalid && (form.get('levsattkod').dirty || form.get('levsattkod').touched)"
        class="alert alert-danger">
        <span translate>FORM_ERROR_REQUIRED</span>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-4" *ngIf="!isUser">
    <div [formGroup]="form">
      <lib-meta-select-input formControlName="betkod" table="x2" metaId="betkod" metaDisplay="betvillbeskr">
      </lib-meta-select-input>
    </div>
  </div>
  <div class="col-12 col-md-4" *ngIf="!isUser">
    <div [formGroup]="form">
      <lib-meta-select-input formControlName="saljare" table="salj" metaId="saljare" metaDisplay="saljarenamn">
      </lib-meta-select-input>
    </div>
  </div>
  <div class="col-12 col-md-4" *ngIf="!checkout.extra.isExportCustomer">
    <fieldset [disabled]="isUser || undefined">
      <div [formGroup]="form">
        <lib-meta-select-input formControlName="levvillkkod" table="x2e" metaId="levvillkkod"
          metaDisplay="levvillkbeskr">
        </lib-meta-select-input>
      </div>
    </fieldset>
  </div>
  <div class="col-12 col-md-4">
    <div class="form-group" [formGroup]="form">
      <label for="partDelivery" class="control-label" translate>PART_DELIVERY</label>
      <select name="partDelivery" id="partDelivery" class="form-control" formControlName="dellevtillaten"
        (change)="handlePartDeliveryChange()">
        <option [value]="entry.id" *ngFor="let entry of partDeliveryEntries">
          {{ entry.name | translate }}
        </option>
      </select>
    </div>
  </div>
  <div *ngIf="checkout.extra.oh.dellevtillaten == '10'" class="col-12 col-md-4">
    <div>
      <otto-olsen-delivery-date-picker *ngIf="minDate" (selectedDate)="dateSelected($event)"
        [initialDate]="checkout.extra.oh.ordberlevdat" [deliveryMethod]="checkout.extra.procargs.levsattkod"
        [minInitialDate]="minDate"></otto-olsen-delivery-date-picker>
    </div>
    <div *ngIf="form.get('dellevtillaten').value === '0'" translate>PART_DELIVERY_MESSAGE</div>
  </div>
  <div class="col-12 col-md-4" *ngIf="!isUser">
    <div class="form-group" [formGroup]="form">
      <label for="ordstat " class="control-label" translate>ORDER_TYPE</label>
      <select name="ordstat " id="ordstat " class="form-control form-select" formControlName="ordstat">
        <option [value]="0" translate><span></span>OTTO_OLSEN_QUOTATION</option>
        <option [value]="13" translate>OTTO_OLSEN_ORDER</option>
      </select>
    </div>
  </div>
  <div *ngIf="isFreight && !noFreight" class="col-12 mt-2">
    <div class="alert alert-warning text-center" role="alert">
      <span translate>OTTO_FREIGHT_COST</span>
    </div>
  </div>
  <div class="col-12" *ngIf="showFreeShippingAlert">
    <div class="alert alert-success text-center" role="alert">
      <span>{{ freeShippingMessage }}</span>
    </div>
  </div>

  <div *ngIf="deliveryMethod22" class="col-12 mt-2">
    <div class="alert alert-warning text-center" role="alert">
      <span translate>OTTO_OLSEN_PICK_TWO_HOURS_CONFIRMATION</span>
    </div>
  </div>
  <!-- -------------------- -->
  <!-- --- CART listing --- -->
  <!-- -------------------- -->
  <div class="col-12">
    <div *ngIf="mappedData; else loading">
      <div class="table-head clearfix">
        <div class="table-col c-image"></div>
        <div class="table-col c-notes"></div>
        <div class="table-col c-qty">
          <b translate>QUANTITY</b>
        </div>
        <div class="table-col c-availability">
          <b translate>AVAILABILITY</b>
        </div>
        <div class="table-col c-price">
          <b translate>PRICE</b>
        </div>
        <div class="table-col c-discount" *ngIf="!isUser">
          <b translate>DISCOUNT_PERCENT</b>
        </div>
        <div class="table-col c-total">
          <b translate>TOTAL</b>
        </div>
        <div class="table-col c-delivery">
          <b translate>DELIVERY</b>
        </div>
        <div class="table-col c-options"></div>
      </div>
      <div class="table-body">
        <div class="table-line clearfix" *ngFor="let row of mappedData; index as index; trackBy: trackByFn">
          <div class="product-name" style="font-size: 1rem">
            <div>
              <a class="border-0" [routerLink]="[
                  getProductLink(row)
            ]"><b>{{ row.product.id }}</b> - {{ row.product | productName }} <span
                  *ngIf="isConfigureProduct(row.product)">{{row.product?.extra?.ar?.artbeskrspec}}</span></a>
            </div>
            <div class="d-block">
              <b *ngIf="
                  selectedCustomer &&
                  row.product.extra._customerCrossReferences &&
                  row.product.extra._customerCrossReferences[selectedCustomer.id]
                " class="small">
                {{ row.product.extra._customerCrossReferences[selectedCustomer.id].referenceId }}
              </b>
              <a class="ml-2" (click)="openCrossReferenceEditor(row)">
                <i class="fas fa-edit"></i>
              </a>
            </div>
          </div>
          <div class="line-cols">
            <div class="table-col c-image">
              <a class="border-0" [routerLink]="[
                getProductLink(row)
              ]">
                <img class="img-fluid" *ngIf="!!row.product.extra.images[0]" src="https://cdn1.gung.io/fit-in/64x64/filters:fill(white):background_color(white):format(jpeg)/{{
                  row.product.extra.images[0].s3Uri
                }}" alt="" />
              </a>
            </div>
            <div class="table-col c-notes">
              <button class="btn btn-primary btn-sm" (click)="editNote(row.cartRow)">
                <span translate style="font-size: 1rem">NOTES</span>
              </button>
            </div>
            <div class="table-col c-qty">
              <b translate class="mobile-label">QUANTITY</b>
              <div *ngIf="
                  isAnonymous ||
                    (!(
                      row.product.extra.pim &&
                      row.product.extra.pim.pricerequest &&
                      row.product.extra.pim.pricerequest !== 'false'
                    ) &&
                      !(row.product.extra.ar.itemtypecd1 === 0 || row.product.extra.ar.itemtypecd1 === 7));
                  else priceRequest
                ">
                <div>
                  <otto-olsen-buy-btn name="buy-btn-package-size" [id]="row.product.id" [checkoutCart]="true"
                    [minimumOrderQuantity]="row.product?.firstStepAmount || row.product?.packageSize"
                    [stepAmount]="row.product?.packageSize" [originalSize]="row.product?.originalSize"
                    [productPartialId]="row.productPartialId" [decimals]="row.product?.extra.ar.antdec"
                    [activeInB2B]="row?.product?.extra?.pim?.activeInB2B" [product]="row.product"
                    [availability]="row.cartRow?.extra?._availability_reference" [disabled]="isDisableInput(row)">
                  </otto-olsen-buy-btn>
                  <span class="pl-1">
                    {{ row.product.extra.ar?.enhetskod }}
                  </span>
                </div>

                <span
                  *ngIf="row.product?.originalSize && row.product?.extra.ar?.q_jis_kaltillegg_artnr && isInteger(row)"
                  class="text-center">
                  <i class="fas fa-exclamation-triangle text-danger"></i>
                  <span>{{
                    'FEE_ADDED_LOT_SIZE'
                    | translate : { value: row.product?.originalSize + ' ' + row.product.extra.ar?.enhetskod }
                    }}</span>
                </span>
              </div>
              <ng-template #priceRequest>
                <div class="d-flex flex-row align-items-center">
                  <input type="number" class="form-control text-right px-1" [value]="row.cartRow.qty" [disabled]="true"
                    style="width: 130px;" />
                  <span class="pl-2">
                    {{ row.product.extra.ar?.enhetskod }}
                  </span>
                </div>
                <!--<a href="mailto:{{ requestPriceEmail }}?subject=Forespørsel {{ row.product.id }}" target="_blank"
                  class="request-price-email">
                  <button type="button" class="btn btn-primary" translate>ASK_PRICE</button>
                </a>-->
              </ng-template>
            </div>
            <div class="table-col c-availability">
              <b translate class="mobile-label">AVAILABILITY</b>
              <otto-olsen-availability [availability]="row.cartRow?.extra?._availability_reference"
                [unit]="row.product?.extra._templateProduct?.extra?.ar?.enhetskod || row.product?.extra.ar?.enhetskod"
                [decimals]="row.product?.extra.ar.antdec" [productionDays]="
                  row.product?.extra.ar?.anskaffningssatt &&
                  row.product?.extra.ar?.anskaffningssatt * 1 === 12 &&
                  row.product?.extra.ar?.artgenomloppstid
                "></otto-olsen-availability>
            </div>
            <div class="table-col c-price">
              <b translate class="mobile-label">PRICE</b>
              <input type="number" class="form-control text-right px-1"
                [value]="parseFloatFixed(row.overridePrice || row.originalPrice, 2)"
                [placeholder]="row.originalPrice | number : '1.2-2'"
                (blur)="updateOverridePrice(row, parseFloatFixed($event.target.value, 2))"
                [disabled]="isUser || isDisableInput(row) || undefined" [class.input-blur]="hidePrice" />
            </div>
            <div class="table-col c-discount" *ngIf="!isUser">
              <b translate class="mobile-label">DISCOUNT_PERCENT</b>
              <input type="number" class="form-control discount-control text-right px-1" [value]="
                  parseFloatFixed(row.discountPercen, 2) ||
                  parseFloatFixed(row.cartRowPriceElement.cartRowDiscountPercent, 2) ||
                  0
                " (blur)="updateDiscountPercentage(row, $event.target.value)" [disabled]="isDisableInput(row)"
                placeholder="%" [class.input-blur]="hidePrice" />
            </div>
            <div class="table-col c-total">
              <b translate class="mobile-label">TOTAL</b>
              <lib-price [price]="row.cartRowPriceElement" type="cartRowTotalPriceInclRowDiscount"></lib-price>
            </div>
            <div class="table-col c-delivery">
              <b translate class="mobile-label">DELIVERY</b>
              <div class="fit-datepicker">
                <div *ngIf="!partDeliveriesForbidden">
                  <otto-olsen-request-delivery-date-picker class="request-delivery-date" [label]="null"
                    [initialDate]="row.deliveryDate ? dateUtilService.parseDate(row.deliveryDate) : row.minDate"
                    (selectedDate)="updateDeliveryDateWithWarnings(row, $event, false)" [minInitialDate]="row.minDate"
                    [deliveryMethod]="row.deliveryMethod" [currentselectedDate]="row.deliveryDate"
                    (clicked)="wasClicked = $event;"></otto-olsen-request-delivery-date-picker>
                </div>
                <div *ngIf="partDeliveriesForbidden">
                  <lib-delivery-date-picker *ngIf="selectedDeliveryDate" [initialDate]="selectedDeliveryDate"
                    [currentselectedDate]="selectedDeliveryDate" [label]="null"
                    [disabled]="true"></lib-delivery-date-picker>
                </div>
              </div>
            </div>
            <div class="table-col c-options text-right clearfix">
              <ng-container *ngTemplateOutlet="rowBtns; context: { row }"></ng-container>
            </div>
          </div>
          <div class="">
            <div *ngIf="row.cartRow.extra.warning && !warningWasActiveBefore[row.productId]"
              class="d-flex alert alert-danger p-1 mt-1">
              <span translate>DELIVERY_DATE_CHANGED_DUE_TO_STOCK</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-template #rowBtns let-row="row">
      <button *ngIf="enableDuplicateRow" type="button" class="btn btn-secondary btn-sm mr-1"
        (click)="duplicateProduct(row, index)">
        <i class="fas fa-copy fa-2x"></i>
      </button>
      <button class="btn btn-light delete-button" (click)="removeRow(row)">
        <i class="fas fa-trash-alt fa-2x"></i>
      </button>
      <button *ngIf="favouriteIcon && row.product?.extra?.ar?.webarttext !== 'configure'"
        class="btn btn-light delete-button ml-2" (click)="favouriteIcon.toggle()">
        <i class="fa-star fa-2x" [ngClass]="favouriteIcon.isFavourite ? 'fas' : 'far'"></i>
      </button>
      <lib-favourite-product-icon #favouriteIcon [id]="row.product.id" class="d-none"></lib-favourite-product-icon>
    </ng-template>
  </div>
</div>

<ng-template #loadingData translate> LOADING <i class="fa fa-spinner fa-spin fa-2x"></i> </ng-template>
<ng-template #loading>
  <div class="progress mt-5" style="margin-left: -1rem; margin-right: -1rem">
    <div class="progress-bar progress-bar-striped progress-bar-animated bg-primary" role="progressbar"
      aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style="width: 75%"></div>
  </div>
</ng-template>
