import { Injectable } from '@angular/core';
import {
  ProductExportListConfigService,
  AuthService,
  ProductService,
  Product,
  SelectedCustomerService,
  GungFlowService,
  GungModalService,
  ProductExportService,
  ProductSortService,
  ProductListConfigService,
  ProductExportActionConfigService,
  AssortmentService,
  ProductNameFilter,
  CloudPimFiltersService,
  CurrentAssortmentService,
  ProductListV2ConfigService,
  BaseViewConfigService
} from 'gung-standard';
import { Observable, of, forkJoin, from, toArray } from 'rxjs';
import { SelectionAction, ExportSelection, ListLayout, GridLayoutComponent, ConfigBaseFilter } from 'gung-list';
import { mergeMap, first, map, switchMap, filter } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { OttoOlsenProductExportCardComponent } from '../components/otto-olsen-product-export-card/otto-olsen-product-export-card.component';

enum ExportSelectionDataType {
  Xlsx,
  Csv,
  Img,
  ProductInfo,
  ProductStock
}

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenProductExportListConfigService extends ProductExportListConfigService {

  filters: ConfigBaseFilter<Product>[] = [];
  constructor(
    protected authService: AuthService,
    protected productService: ProductService,
    protected productExportService: ProductExportService,
    private selectedCustomerService: SelectedCustomerService,
    private gungFlowService: GungFlowService,
    protected http: HttpClient,
    private gungModalService: GungModalService,
    protected productSortService: ProductSortService,
    protected productListConfigService: ProductListConfigService,
    protected productExportActionConfigService: ProductExportActionConfigService,
    protected cloudPimFiltersService: CloudPimFiltersService,
    protected assortmentService: AssortmentService,
    protected productListV2ConfigService: ProductListV2ConfigService,
    protected baseViewConfigService: BaseViewConfigService

  ) {
    super(
      productService,
      authService,
      productExportService,
      productSortService,
      productListConfigService,
      productExportActionConfigService,
      productListV2ConfigService,
      baseViewConfigService
    );
    this.getFiltersFromAssortment();
  }

  getItems(): Observable<Product[]> {
    return this.assortmentService.getUserAssortment().pipe(
      first(),
      filter(assortment => !!assortment),
      switchMap(assortment => {
        return this.productService.getProductsByAssortmentExpanded(assortment.id).pipe(first());

        /* old verison
        return this.productService.getProductsByAssortment(assortment.id).pipe(
          first(),
          map(products=>{
            return products.filter(product => product.productType!=='concept');
          })
        ); */
      })
    );
  }

  getLayouts(): ListLayout<Product>[] {
    return [
      {
        getIconClass: () => 'fa fa-th',
        getListItemComponent: () => OttoOlsenProductExportCardComponent,
        getListLayoutComponent: () => GridLayoutComponent,
        getName: () => 'Invoice grid'
      }
    ];
  }

  getSelectionActions(): Observable<SelectionAction<Product>[]> {
    const ooCustomActions = [
      {
        label: 'EXPORT_XLSX',
        performAction: (selection: ExportSelection<Product>) => {
          const itemList = Object.values(selection.selectedItems);
          this.downloadFile('generate-product-excel-from-products-ids', itemList, ExportSelectionDataType.Xlsx);
          return of();
        }
      },
      {
        label: 'EXPORT_STOCKLIST',
        performAction: (selection: ExportSelection<Product>) => {
          const itemList = Object.values(selection.selectedItems);
          this.downloadFile('generate-product-pdf-from-products-ids', itemList, ExportSelectionDataType.ProductStock);
          return of();
        }
      },
      {
        label: 'EXPORT_PRODUCT_INFO',
        performAction: (selection: ExportSelection<Product>) => {
          const itemList = Object.values(selection.selectedItems);
          this.downloadFile('generate-product-pdf-from-products-ids', itemList, ExportSelectionDataType.ProductInfo);
          return of();
        }
      },
      {
        label: 'EXPORT_IMG',
        performAction: (selection: ExportSelection<Product>) => {
          const itemList = Object.values(selection.selectedItems);
          this.downloadFile('generate-product-image-zip-from-products-ids', itemList, ExportSelectionDataType.Img);
          return of();
        }
      },
      {
        label: 'EXPORT_PRICAT',
        performAction: (selection: ExportSelection<Product>) => {
          const itemList = Object.values(selection.selectedItems);
          this.downloadFile('generate-product-csv-from-products-ids', itemList, ExportSelectionDataType.Csv);
          return of();
        }
      }
    ]
    return forkJoin([
      this.productExportActionConfigService.getSelectionActions().pipe(first()),
      this.productExportActionConfigService.getDynamicSelectionActions().pipe(first())
    ]).pipe(
      mergeMap(([staticActions, dynamicActions]) => {
        const res = [];
        //res.push(...staticActions);
        res.push(...dynamicActions);
        res.push(...ooCustomActions);
        return res;
      }),
      toArray()
    );
  }

  private buildExportRequest(
    exportSelectionDataType: ExportSelectionDataType,
    itemList: Product[]
  ): Observable<{ isValid: boolean; body: any }> {
    // build request body
    return this.getExportRequestBodyByType(exportSelectionDataType, itemList).pipe(
      first(),
      switchMap((data: any) => {
        return forkJoin({
          data: of(data),
          modalOutput: from(this.gungModalService.openConfirmExportModal(data.recipient)).pipe(first())
        });
      }),
      switchMap(result => {
        let isValid = false;
        if (result.modalOutput && result.modalOutput.email) {
          result.data.recipient = result.modalOutput.email;
          isValid = true;
        }

        return of({ isValid, body: result.data });
      })
    );
  }

  private getExportRequestBodyByType(
    exportSelectionDataType: ExportSelectionDataType,
    itemList: Product[]
  ): Observable<any> {
    return forkJoin([
      this.authService.getCurrentUser().pipe(first()),
      this.selectedCustomerService.getSelectedCustomer().pipe(first()),
      this.gungFlowService.getSelectedFlow().pipe(first())
    ]).pipe(
      map(([user, customer, flow]) => {
        let requestBody: any = {
          ids: itemList.map(x => x.id),
          recipient: user.email,
          customerId: customer.id,
          flowId: flow.id
        };

        // Product Info and Stock has two additional parameters
        // template and params
        if (
          [ExportSelectionDataType.ProductInfo, ExportSelectionDataType.ProductStock].includes(exportSelectionDataType)
        ) {
          const templateValue =
            exportSelectionDataType === ExportSelectionDataType.ProductInfo
              ? 'PRODUCT_INFORMATION_EXPORT'
              : 'PRODUCT_STOCK_EXPORT';
          const paramsValue = {
            stockId: user.managedMultistockIds[0],
            customerId: customer.id,
            flowId: flow.id
          };

          requestBody = {
            ...requestBody,
            template: templateValue,
            params: paramsValue
          };
        }

        return requestBody;
      })
    );
  }

  exportProducts(service: string, body: any): Observable<{ status: boolean; message: string }> {
    return this.http.post<{ status: string }>('download/' + service, body).pipe(
      first(),
      map(resp => {
        return {
          status: resp.status === 'OK',
          message: resp.status
        };
      })
    );
  }

  downloadFile(service: string, itemList: Product[], type: ExportSelectionDataType) {
    this.buildExportRequest(type, itemList)
      .pipe(
        first(),
        mergeMap((request: { isValid: boolean; body: any }) => {
          if (request.isValid) {
            return this.exportProducts(service, request.body);
          }

          return of();
        })
      )
      .subscribe();
  }

  getFilters(): ConfigBaseFilter<Product>[] {
    return this.filters;
  }

  getFiltersFromAssortment() {
    return this.authService
      .getCurrentUser()
      .pipe(
        filter(user => !!user),
        first(),
        mergeMap(user => {
          return this.assortmentService.getAssortmentRecursive(user.assortment).pipe(first());
        })
      ).subscribe( result => {
        this.filters = this.cloudPimFiltersService.getFilters(result);
      });
  }
}
