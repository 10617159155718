<div class="row" [ngClass]="{ 'mt-4 mb-4': mappedData && mappedData.length > 0 }">
  <div *ngFor="let data of mappedData" class="col-6 col-md-4 col-lg-3 mb-2">
    <div class="card product-card">
      <div class="card-body">
        <otto-olsen-favourite-product-icon-only
          class="text-right m-2"
          [id]="data.product.id"
        ></otto-olsen-favourite-product-icon-only>
        <div
          class="image-wrapper"
          [routerLink]="[
            data.product.extra.assortmentParentPath
              ? '/articles/' + data.product.extra.assortmentParentPath
              : '/product/',
            data.product.id
          ]"
        >
          <img
            class="img-fluid"
            *ngIf="data.product.extra && data.product.extra.images && data.product.extra.images[0]"
            src="https://d27ahaa1qqlr90.cloudfront.net/fit-in/250x250/filters:etag({{
              data.product.extra.images[0].s3ETag
            }})/{{ data.product.extra.images[0].s3Uri }}"
            alt=""
          />
        </div>
        <div class="product-text pb-1">
          <div>
            {{ data.product.id }}
            <b
              *ngIf="
                selectedCustomer &&
                data.product.extra._customerCrossReferences &&
                data.product.extra._customerCrossReferences[selectedCustomer.id]
              "
              class="d-block small"
            >
              {{ data.product.extra._customerCrossReferences[selectedCustomer.id].referenceId }}
            </b>
          </div>
          <div class="text-truncate" translate>{{ data.product | productName }}</div>
          <div translate *ngIf="data.price" class="font-weight-bold">
            <!-- {{ data.price.customerNetPrice.value | gungCurrency: data.price.customerNetPrice.currencyCode}} -->
            <lib-price [price]="data.price" type="NET"></lib-price>
          </div>
          <lib-availability [availability]="data.availability" [isSales]="isSales" *ngIf="includeAvailability">
          </lib-availability>
          <div class="product-card-button">
            <lib-buy-btn [id]="data.product.id"></lib-buy-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="findError" class="col-12 mb-2 text-center">
    {{ errorMessage }}
  </div>
</div>
