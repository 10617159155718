<div class="table-responsive gung-responsive" *ngIf="mappedData; else loading">
  <table class="table table-striped">
    <thead class="sticky-table-header">
      <tr>
        <th translate>PRODUCT</th>
        <ng-container *ngFor="let headerKey of dynamicColumns">
          <th *ngIf="headerKey.isDisplayList" class="fit-column">{{ headerKey.translationKey | translate }}</th>
        </ng-container>
        <th translate>VARESPESIFIKASJON</th>
        <th translate>AVAILABILITY</th>
        <th class="buy-btn-width-150" translate>PRICE</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of mappedData; index as i">
        <td [attr.data-label]="'PRODUCT' | translate">
          <a [routerLink]="['./', data.product.id]" class="product-link">
            <span class="d-block font-weight-bold text-nowrap">{{
              data.product?.extra?.ar?.artnr || data.product?.id
            }}</span>
            {{ data.product | productName }}
          </a>
        </td>
        <td
          [attr.data-label]="dynamicColumns[i].translationKey | translate"
          *ngFor="let value of data.product.dynamicCollumnsValues; let i = index"
          class="text-right text-nowrap"
        >
          <span>{{ value }}</span>
        </td>
        <td [attr.data-label]="'VARESPESIFIKASJON' | translate">
          <span>{{ data.product?.extra?.ar?.artbeskrspec }}</span>
        </td>
        <td [attr.data-label]="'AVAILABILITY' | translate">
          <lib-availability [availability]="data.availability"></lib-availability>
        </td>
        <td [attr.data-label]="'PRICE' | translate" class="buy-btn-width-150">
          <span *ngIf="data.price" class="d-block text-center">
            <lib-price [price]="data.price" type="GROSS"></lib-price>
            <span *ngIf="data.price.customerDiscountPercent !== 0">(-{{ data.price.customerDiscountPercent }}%)</span>
          </span>
          <div *ngIf="data.product.productType === 'concept'; else buybtn">
            <button
              type="button"
              translate
              class="btn btn-sm btn-default btn-block btn-primary"
              [routerLink]="['./', data.product.id]"
            >
              SHOW_PRODUCTS
            </button>
          </div>
          <ng-template #buybtn>
            <otto-olsen-buy-btn
              name="buy-btn-package-size"
              [id]="data.product?.id"
              [minimumOrderQuantity]="data.product?.firstStepAmount || data.product?.packageSize"
              [stepAmount]="data.product?.packageSize"
              [decimals]="data.product?.extra.ar.antdec"
              [originalSize]="data.product?.originalSize"
              [unit]="data.product?.extra.ar?.enhetskod"
              [activeInB2B]="data.product?.extra?.pim?.activeInB2B"
              [product]="data.product"
              [availability]="data.availability"
            >
            </otto-olsen-buy-btn>
          </ng-template>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<ng-template #loading>
  <i class="fa fa-spinner fa-spin fa-7x"></i>
</ng-template>
