import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfigBaseFilter } from 'gung-list';
import {
  Assortment,
  AssortmentRecursiveExtended,
  AssortmentService,
  AuthService,
  CloudPimFiltersService,
  GungBase,
  ItemProperties,
  ItemTypeFilterPathField,
  MetadataService,
  PimNumberFilter,
  Product
} from 'gung-standard';
import { first } from 'rxjs';

export class OttoOlsenItemTypeFilterPathField extends ItemTypeFilterPathField {
  constructor(
    protected filterDisplayName: string,
    protected filterTitle: string,
    protected path: string,
    protected notFoundString: string,
    protected translateService?: TranslateService,
    protected metadataService?: MetadataService,
    protected tableName?: string,
    protected valueName?: string,
    protected multiValues?: boolean,
    protected isRange?: boolean,
    protected translationPath?: string,
    protected splitString?: string,
    protected showUndefined?: boolean
  ) {
    super(
      filterDisplayName,
      filterTitle,
      path,
      notFoundString,
      translateService,
      metadataService,
      tableName,
      valueName,
      multiValues,
      isRange,
      translationPath,
      splitString
    );
  }

  getOptionIds(item: GungBase): string[] {
    this.itemValue = undefined;
    if (item.hasOwnProperty('product')) {
      item = (item as any).product;
    }
    if (item === undefined || item === null) {
      return this.showUndefined ? [this.notFoundString] : [];
    }
    let valuesAsArray = [];
    const splitPath = ['extra', ...this.path.split('.')];
    const reducedValue = this.getReduceValueObject(splitPath, item);

    if (reducedValue === undefined || reducedValue === null) {
      return this.showUndefined ? [this.notFoundString] : [];
    } else if (!this.multiValues) {
      if (reducedValue && reducedValue.properties && reducedValue.properties.sorting && reducedValue.code) {
        valuesAsArray = [reducedValue.code];
      } else if (Array.isArray(reducedValue)) {
        valuesAsArray = reducedValue.map(v => {
          let res = v;
          if (v.hasOwnProperty('description')) {
            res = v.description || this.showUndefined ? [this.notFoundString] : [];
          }
          return res;
        });
      } else if (reducedValue.description) {
        valuesAsArray = [reducedValue.description];
      } else {
        if (!!this.splitString) {
          valuesAsArray = [...reducedValue.split(this.splitString)];
        } else {
          valuesAsArray = [reducedValue];
        }
      }
    } else {
      if (Array.isArray(reducedValue)) {
        valuesAsArray = reducedValue.map(v => {
          let res = v;
          if (v.hasOwnProperty('description')) {
            res = v.description || this.showUndefined ? [this.notFoundString] : [];
          }
          return res;
        });
      } else if (reducedValue.description) {
        valuesAsArray = [reducedValue.description];
      } else {
        valuesAsArray = [reducedValue];
      }
    }
    this.itemValue = reducedValue;
    const mappedValues = valuesAsArray.map(value => {
      if (typeof value === 'string' || value instanceof String) {
        value = value.replace(/^\s+|\s+$/g, '');
      }
      if (!value) {
        return this.showUndefined ? [this.notFoundString] : [];
      } else {
        if (this.valueName && typeof value === 'object' && Object.keys(value).indexOf(this.valueName) >= 0) {
          return value[this.valueName];
        } else {
          return value === '-' ? [] : value;
        }
      }
    });
    return [...new Set<any>(mappedValues)];
  }
}

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenCloudPimFiltersService extends CloudPimFiltersService {
  showUndefined = false;

  constructor(
    protected translateService: TranslateService,
    protected assortmentService: AssortmentService,
    protected metadataService: MetadataService,
    protected authService: AuthService
  ) {
    super(translateService, assortmentService, metadataService);
    this.authService
    .getRoles()
    .pipe(first())
    .subscribe(
      roles =>
        (this.showUndefined = roles.indexOf('SALES') > -1 && roles.indexOf('ADMIN') > -1)
    );
  }

  getFilters(
    assortment: Assortment,
    multiValues?: boolean,
    assortmentLeaf?: AssortmentRecursiveExtended
  ): ConfigBaseFilter<Product>[] {
    const filters: ConfigBaseFilter<Product>[] = [];
    if (!!assortment && (assortment.extra.itemProperties || assortment.extra.skuProperties)) {
      let properties: ItemProperties[] = assortment.extra.itemProperties || assortment.extra.skuProperties;
      if (assortmentLeaf && this.assortmentService.isGroupedAssortment(assortmentLeaf)) {
        properties = assortmentLeaf.extra.itemProperties;
      }
      for (const property of properties) {
        if (property.isFilter) {
          const isRange = property.filterType && property.filterType === 'RANGE';

          const field = property.path.split('.')[1];
          const translationKey = property.translationKey;
          const displayName = property.displayName;

          // Check if translation exists
          const undefinedString = 'NO_' + translationKey + '_DEFINED';
          const undefinedTranslation =
            this.translateService.instant(undefinedString) === undefinedString ? 'UNDEFINED' : undefinedString;

          if (property.type === 'number') {
            filters.push(new PimNumberFilter(translationKey, field, multiValues));
          } else if (property.type === 'metadata') {
            if (property.metaReference) {
              filters.push(
                new OttoOlsenItemTypeFilterPathField(
                  displayName,
                  translationKey,
                  property.path,
                  undefinedTranslation,
                  this.translateService,
                  this.metadataService,
                  property.metaReference.table,
                  property.metaReference.field,
                  multiValues,
                  isRange,
                  undefined,
                  this.splitString,
                  this.showUndefined
                )
              );
            } else if (property.metadata && property.metadata.split('.').length === 2) {
              const splitmeta = property.metadata.split('.');
              const metaTable = splitmeta[0];
              const metaField = splitmeta[1];
              filters.push(
                new OttoOlsenItemTypeFilterPathField(
                  displayName,
                  translationKey,
                  property.path,
                  undefinedTranslation,
                  this.translateService,
                  this.metadataService,
                  metaTable,
                  metaField,
                  multiValues,
                  isRange,
                  undefined,
                  this.splitString,
                  this.showUndefined
                )
              );
            } else {
              let translationPath = property.filterValueField || 'description';
              if (translationPath.indexOf('.') > -1) {
                const splittedPath = translationPath.split('.');
                if (translationPath.indexOf('translateProperties') > -1) {
                  splittedPath.splice(splittedPath.indexOf('translateProperties') + 1, 0, this.currentLang);
                }
                translationPath = splittedPath.join('.');
              }
              filters.push(
                new OttoOlsenItemTypeFilterPathField(
                  displayName,
                  translationKey,
                  property.path,
                  undefinedTranslation,
                  this.translateService,
                  this.metadataService,
                  null,
                  null,
                  multiValues,
                  isRange,
                  translationPath,
                  this.splitString,
                  this.showUndefined
                )
              );
            }
          } else {
            filters.push(
              new OttoOlsenItemTypeFilterPathField(
                displayName,
                translationKey,
                property.path,
                undefinedTranslation,
                this.translateService,
                null,
                null,
                null,
                multiValues,
                isRange,
                undefined,
                this.splitString,
                this.showUndefined
              )
            );
          }
        }
      }
    }

    return filters;
  }
}
