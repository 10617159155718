import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GungModalService } from 'gung-standard';
import { Observable } from 'rxjs';
import { OttoOlsenCheckoutWrapperComponent } from '../components/checkout/otto-olsen-checkout-wrapper/otto-olsen-checkout-wrapper.component';
import { OttoOlsenCheckoutConfigService } from '../services/otto-olsen-checkout-config.service';

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class OttoOlsenCartCanDeactivate implements CanDeactivate<OttoOlsenCheckoutWrapperComponent>  {
  constructor(
    protected gungModalService: GungModalService,
    protected translateService: TranslateService,
    protected checkoutConfig: OttoOlsenCheckoutConfigService
  ) {}

  canDeactivate(
    component: OttoOlsenCheckoutWrapperComponent
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (component.hasUnsavedChanges()) {
      return this.gungModalService
        .openConfirmYesNoModal(
          this.translateService.instant('LEAVE_CART_WARNING_TITLE'),
          this.translateService.instant('LEAVE_CART_WARNING_TEXT'),
          { size: 'md' },
          'YES',
          'NO'
        )
        .then(result => {
          if (result) {
            this.checkoutConfig.hasUnsavedChanges = false;
          }
          return result;
        });
    } else {
      return true;
    }
  }
}