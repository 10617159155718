{
  "name": "gung-b2b",
  "version": "1.0.0",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "node --max_old_space_size=10000 node_modules/@angular/cli/bin/ng build",
    "test": "ng test",
    "lint": "ng lint",
    "e2e": "ng e2e",
    "watch-libs": "concurrently \"ng build gung-standard --watch\" \"ng build gung-list --watch\" \"ng build gung-common --watch\"",
    "build-standard-libs": "ng build gung-common && ng build gung-list && ng build gung-dynamic-pdf && ng build gung-standard && ng build gung-dashboard",
    "build-libs": "ng build gung-common && ng build gung-list && ng build gung-dynamic-pdf && ng build gung-standard && ng build gung-dashboard && ng build gung-standard-jeeves",
    "build-libs-jeeves": "npm run build-libs",
    "build-libs-garp": "ng build gung-common && ng build gung-list && ng build gung-dynamic-pdf && ng build gung-standard && ng build gung-dashboard && ng build gung-standard-garp",
    "build-libs-bc": "ng build gung-common && ng build gung-list && ng build gung-dynamic-pdf && ng build gung-standard && ng build gung-dashboard && ng build gung-standard-bc",
    "build-libs-fortnox": "ng build gung-common && ng build gung-list && ng build gung-dynamic-pdf && ng build gung-standard && ng build gung-dashboard && ng build gung-standard-fortnox",
    "build-libs-m3": "ng build gung-common && ng build gung-list && ng build gung-dynamic-pdf && ng build gung-standard && ng build gung-dashboard && ng build gung-standard-m3",
    "build-libs-epicor": "ng build gung-common && ng build gung-list && ng build gung-dynamic-pdf && ng build gung-standard && ng build gung-dashboard && ng build gung-standard-epicor",
    "build-libs-m2m": "ng build gung-common && ng build gung-list && ng build gung-dynamic-pdf && ng build gung-standard && ng build gung-dashboard && ng build gung-standard-m2m",
    "build-libs-buyer-portal": "ng build gung-common && ng build gung-list && ng build gung-dynamic-pdf && ng build gung-standard && ng build gung-dashboard && ng build buyer-portal-base",
    "build-libs-all": "ng build gung-common && ng build gung-list && ng build gung-dynamic-pdf && ng build gung-standard && ng build gung-dashboard && ng build gung-standard-jeeves && ng build gung-standard-garp && ng build gung-standard-bc && ng build gung-standard-fortnox && ng build gung-standard-m3 && ng build gung-standard-m2m && ng build gung-standard-epicor",
    "build-hl-customer-1600": "ng lint hl-display & ng build hl-display --configuration customer-1600",
    "build-hl-sales-1210": "ng lint hl-display & ng build hl-display --configuration sales-1210",
    "build-hl-sales-1710": "ng lint hl-display & ng build hl-display --configuration sales-1710",
    "build-capstone": "ng lint ref & ng build ref -c production",
    "build-fortiva": "ng lint fortiva & ng build fortiva --configuration production",
    "build-pocketgrossisten": "ng build pocketgrossisten --configuration production",
    "build-cult": "ng build cult --configuration production",
    "build-colmec": "ng build colmec -c production",
    "compodoc": "npx compodoc -p tsconfig.json",
    "deploy-hl-sales": "bash ./projects/hl-display/deploy-sales.sh",
    "build-pim": "ng build gung-utils && ng build gung-common && ng build gung-list && ng build gung-list-material && ng build cloud-pim-base",
    "build-pim-markslojd": "npm run build-pim && ng build cloud-pim-markslojd --configuration production",
    "extract-translations-pim": "ngx-translate-extract --input ./**/cloud-pim-base/src/ ./**/gung-list/src/ ./**/gung-list-material/ --output ./projects/cloud-pim-base/src/lib/i18n/{en,se,no}.json --sort",
    "extract-translations-standard": "ngx-translate-extract --input ./**/gung-standard/src/ ./**/gung-list/src/ ./**/gung-dashboard/src --output ./i18n/standard.json --sort",
    "extract-translations-standard-jeeves": "ngx-translate-extract --input  ./**/gung-standard-jeeves/src/ --output ./i18n/standard-jeeves.json --sort"
  },
  "lint-staged": {
    "*.ts": [
      "eslint --fix"
    ],
    "*.html": [
      "eslint --fix"
    ]
  },
  "husky": {
    "hooks": {
      "pre-commit": "lint-staged"
    }
  },
  "private": true,
  "dependencies": {
    "@adyen/adyen-web": "^6.1.0",
    "@angular-devkit/core": "^18.0.3",
    "@angular-eslint/schematics": "^18.0.1",
    "@angular/animations": "^18.0.2",
    "@angular/cdk": "^18.0.2",
    "@angular/common": "^18.0.2",
    "@angular/compiler": "^18.0.2",
    "@angular/core": "^18.0.2",
    "@angular/forms": "^18.0.2",
    "@angular/localize": "^18.0.2",
    "@angular/material": "^18.0.2",
    "@angular/platform-browser": "^18.0.2",
    "@angular/platform-browser-dynamic": "^18.0.2",
    "@angular/router": "^18.0.2",
    "@asymmetrik/ngx-leaflet": "^18.0.1",
    "@asymmetrik/ngx-leaflet-markercluster": "^18.0.0",
    "@aws-amplify/ui-angular": "^5.0.16",
    "@fortawesome/fontawesome-free": "^6.4.0",
    "@fortawesome/fontawesome-pro": "^6.4.0",
    "@kolkov/angular-editor": "~2.1.0",
    "@ng-bootstrap/ng-bootstrap": "^17.0.0",
    "@ngrx/effects": "^18.0.0",
    "@ngrx/store": "^18.0.0",
    "@ngrx/store-devtools": "^18.0.0",
    "@ngx-translate/core": "^15.0.0",
    "@sentry/browser": "^5.30.0",
    "@stripe/stripe-js": "^4.9.0",
    "@types/leaflet": "^1.9.12",
    "@types/leaflet.markercluster": "^1.5.4",
    "amazon-cognito-auth-js": "^1.3.3",
    "angular-gridster2": "^18.0.1",
    "angular-oauth2-oidc": "^13.0.1",
    "angular-oauth2-oidc-jwks": "^13.0.1",
    "angulartics2": "^14.0.0",
    "aws-amplify": "^6.3.6",
    "bootstrap": "^5.3.3",
    "chart.js": "^3.8.2",
    "date-fns": "^3.6.0",
    "dm-fabric": "^5.1.17",
    "dot-object": "^2.1.4",
    "dynamsoft-javascript-barcode": "^9.6.42",
    "file-saver": "^2.0.5",
    "flag-icons": "^7.2.3",
    "font-awesome": "^4.7.0",
    "gung-ngsortgrid": "^18.0.1",
    "gung-pim-theme": "file:../gung-pim-theme",
    "gung-theme": "file:../gung-theme",
    "hammerjs": "^2.0.8",
    "html5-qrcode": "^2.2.1",
    "leaflet": "^1.9.4",
    "lodash-es": "^4.17.21",
    "ng-zorro-antd": "^18.0.0-beta.0",
    "ng2-charts": "^3.1.2",
    "ng2-pdf-viewer": "^10.0.0",
    "ngx-image-zoom": "^1.0.1",
    "ngx-indexed-db": "^11.0.2",
    "ngx-infinite-scroll": "^18.0.0",
    "ngx-mat-select-search": "^7.0.6",
    "ngx-owl-carousel-o": "^18.0.0",
    "ngx-scanner-qrcode": "^1.6.9",
    "odata-query": "^6.7.1",
    "pdfjs-dist": "^2.14.305",
    "rxjs": "^7.8.1",
    "tslib": "^2.6.3",
    "url-search-params-polyfill": "^8.1.1",
    "uuid": "^9.0.0",
    "zone.js": "~0.14.6"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.0.3",
    "@angular-eslint/builder": "^18.0.1",
    "@angular-eslint/eslint-plugin": "16.1.0",
    "@angular-eslint/eslint-plugin-template": "16.1.0",
    "@angular-eslint/template-parser": "^18.0.1",
    "@angular/cli": "^18.0.3",
    "@angular/compiler-cli": "^18.0.2",
    "@angular/language-service": "^18.0.2",
    "@aws-amplify/cli": "^12.12.2",
    "@compodoc/compodoc": "^1.1.21",
    "@types/file-saver": "^2.0.5",
    "@types/node": "^20.15.1",
    "@typescript-eslint/eslint-plugin": "^7.14.1",
    "@typescript-eslint/parser": "^7.14.1",
    "@vifraa/fastsite": "~1.2.10",
    "ajv": "^8.11.0",
    "concurrently": "^7.6.0",
    "eslint": "^8.17.0",
    "eslint-config-prettier": "^8.5.0",
    "eslint-plugin-deprecation": "^1.4.1",
    "eslint-plugin-import": "latest",
    "eslint-plugin-jsdoc": "latest",
    "eslint-plugin-prefer-arrow": "latest",
    "eslint-plugin-prettier": "^4.2.1",
    "husky": "^4.3.8",
    "lint-staged": "^10.5.4",
    "ng-packagr": "^18.0.0",
    "node-jq": "^4.3.1",
    "prettier": "^3.3.2",
    "prettier-eslint": "^16.3.0",
    "protractor": "~7.0.0",
    "slash": "^5.1.0",
    "ts-node": "^10.9.2",
    "typescript": "^5.4.5"
  }
}
