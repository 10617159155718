<div class="row align-items-start">
  <div class="col-6 div-btns flex-column">
    <button class="clean-cart-btn" (click)="clearCart()" translate>
      <i class="fas fa-trash-alt"></i>
      CLEAN_CART
    </button>
    <button type="button" class="btn btn-outline-primary btn-sm w-100" [disabled]="productsInCart.length === 0" (click)="saveCart()" *ngIf="productsInCart">
      {{ 'SAVE_CART' | translate }} 
    </button>
  </div>
  <div class="col-6 div-btns">
    <button class="confirm-order-btn btn" [routerLink]="'/checkout-confirm'" [disabled]="productsInCart.length === 0" translate>
      <i class="fas fa-file-plus"></i>
      CONFIRM_ORDER
    </button>
  </div>
</div>

<div class="col-12 mt-2 articles-sum">
  <div><span translate>NUMBER_ARTICLES</span>: {{ cartTotalItems }}</div>
  <div>
    <span translate>AMOUNT</span>:
    <lib-price [price]="cartContents.totalValue" [currency]="cartContents.currency"></lib-price>
  </div>
</div>

<div class="in-cart-container">
  <div class="row mt-2" *ngIf="productsInCart && productsInCart.length > 0 && !showProductDetails">
    <div class="col-12">
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th scope="col" style="width: 1%"></th>
              <th scope="col" class="px-1 py-2" translate>NAME</th>
              <th scope="col" class="text-right px-1 py-2" translate>QUANTITY</th>
              <th scope="col" class="text-right px-1 py-2" translate>PRICE</th>
              <th></th>
            </tr>
          </thead>
          <tbody *ngFor="let productInCart of productsInCart; trackBy: trackByProductsInCart" (click)="clickDetails(productInCart)">
            <tr class="border-bottom-0">
              <td rowspan="2" class="border-bottom-0" style="width: 1%; vertical-align: middle">
                <otto-olsen-availability
                  showOnlyIcons="true"
                  [isSales]="true"
                  [availability]="productInCart.extra.availabilities"
                >
                </otto-olsen-availability>
              </td>
              <td class="border-bottom-0">
                {{ productInCart.extra.product.id }}
              </td>
              <td class="border-bottom-0 text-right">
                {{ productInCart.qty }}
              </td>
              <td class="border-bottom-0 text-right">
                <lib-price [price]="productInCart.extra.price"></lib-price>
              </td>
              <td rowspan="2" class="border-bottom-0 text-right" style="vertical-align: middle">
                <i
                  class="fas fa-angle-down cursor-pointer"
                  *ngIf="!productInCart.extra._showDetails"
                ></i>
                <i
                  class="fas fa-angle-up cursor-pointer"
                  *ngIf="!!productInCart.extra._showDetails"
                ></i>
              </td>
            </tr>
            <tr class="border-top-0">
              <td colspan="5" class="border-bottom-0 pt-0">
                {{ productInCart.extra.product | productName }}
              </td>
            </tr>
            <tr class="border-top-0" #collapse="ngbCollapse" [ngbCollapse]="!productInCart.extra._showDetails" (ngbCollapseChange)="productInCart.extra._showDetails = $event">
              <td colspan="5" class="border-bottom-0 pt-0">
                <otto-olsen-mobile-product-details
                  productId="{{ productInCart.productId }}"
                ></otto-olsen-mobile-product-details>
              </td>
            </tr>
            <tr class="border-top-0" #collapse="ngbCollapse" [ngbCollapse]="!productInCart.extra._showDetails" (ngbCollapseChange)="productInCart.extra._showDetails = $event">
              <td colspan="5" class="border-bottom-0 pt-0">
                <div class="d-flex">
                  <button type="button" class="btn btn-primary" (click)="goToDetails(productInCart)" translate>
                    GO_TO_PRODUCTS
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div *ngIf="showProductDetails" class="product-detail">
    <div class="close-detail" (click)="showProductDetails = !showProductDetails"><i class="fas fa-times"></i></div>
    <ng-template libCustomHost></ng-template>
  </div>
</div>
<otto-olsen-scanner-footer-menu></otto-olsen-scanner-footer-menu>
