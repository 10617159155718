import { Component, EventEmitter, Output } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { isSameDay } from 'date-fns';
import { DeliveryDatePickerComponent } from 'gung-standard';

@Component({
  selector: 'otto-olsen-request-delivery-date-picker',
  templateUrl: './otto-olsen-request-delivery-date-picker.component.html',
  styleUrl: './otto-olsen-request-delivery-date-picker.component.scss'
})
export class OttoOlsenRequestDeliveryDatePickerComponent extends DeliveryDatePickerComponent {
  @Output()
  clicked? = new EventEmitter<boolean>();

  dateSelected(event: NgbDate, clicked?: boolean) {
    super.dateSelected(event);
    this.clicked.emit(clicked);
  }
}
