<div *ngIf="!view3DModel; else show3DModel" class="row">
  <div class="col-12 mb-2">
    <ng-container *ngIf="images as images; else noimage">
      <div class="text-center">
        <ngb-carousel #myCarousel [interval]="-1" [showNavigationIndicators]="false" [showNavigationArrows]="false">
          <ng-template ngbSlide *ngFor="let image of images; let i = index" [id]="imageIdString + i">
            <div class="square">
              <div class="content">
                <div class="d-flex justify-content-center align-items-flex-start" *ngIf="zoom">
                  <lib-ngx-image-zoom
                    class="img-fluid"
                    thumbImage="https://cdn1.gung.io/fit-in/400x400/filters:fill(white):background_color(white):etag({{
                      image.s3ETag
                    }}):format(jpeg)/{{ image.extra.s3UriEncoded }}"
                    fullImage="https://cdn1.gung.io/fit-in/1100x1100/filters:fill(white):background_color(white):etag({{
                      image.s3ETag
                    }}):format(jpeg)/{{ image.extra.s3UriEncoded }}"
                  >
                  </lib-ngx-image-zoom>
                </div>
                <div *ngIf="!zoom">
                  <img
                    class="img-fluid mx-auto d-block"
                    src="https://cdn1.gung.io/fit-in/400x400/filters:fill(white):background_color(white):etag({{ image.s3ETag }}):format(jpeg)/{{
                      image.extra.s3UriEncoded
                    }}"
                    alt="{{ image.description }}"
                  />
                </div>
                <div class="d-flex justify-content-center align-items-flex-start" *ngIf="openExternal">
                  <a
                    href="https://cdn1.gung.io/fit-in/{{
                      this.imageSizeZoom
                    }}/filters:fill(white):background_color(white):etag({{ image.s3ETag }}):format(jpeg)/{{
                      image.extra.s3UriEncoded
                    }}"
                    target="_blank"
                  >
                    <i class="fas fa-search-plus d-block"></i>
                  </a>
                </div>
              </div>
            </div>
          </ng-template>
          <!-- VIDEO -->
          <ng-template #videoSlide *ngIf="video && video.embed" ngbSlide [id]="imageIdString + images.length">
            <ng-container #videoYoutube *ngIf="video.provider === 'youtube'; else videoVimeo">
              <iframe
                [id]="imageIdString + images.length"
                width="100%"
                height="315"
                [src]="video.embed"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </ng-container>
            <ng-template #videoVimeo>
              <div style="padding: 56.25% 0 0 0; position: relative">
                <iframe
                  [id]="imageIdString + images.length"
                  [src]="video.embed"
                  style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
                  frameborder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowfullscreen
                >
                </iframe>
              </div>
              <script src="https://player.vimeo.com/api/player.js"></script>
            </ng-template>
          </ng-template>
          <!-- model3d -->
        </ngb-carousel>
      </div>
    </ng-container>
    <ng-template #noimage>
      <img
        class="img-fluid w-100"
        src="https://cdn1.gung.io/fit-in/400x400/images/no-image.jpg"
        alt="NO IMAGE"
        class="img-fluid mx-auto d-block"
      />
    </ng-template>
  </div>
  <div class="col-12 mb-3" *ngIf="(!!images && images.length > 1) || (!!images && !!video)">
    <div class="scolling-images">
      <a class="scroll-left" (click)="scrollImagesLeft()" *ngIf="images.length > 4">
        <i class="fa fa-2x fa-caret-left"></i>
      </a>
      <div class="scroll-container row justify-content-center" #scrollContainer>
        <div
          class="col-3 d-flex justify-content-center align-items-center"
          *ngFor="let image of images; let i = index"
          [ngClass]="{ 'pr-0': i == images.length - 1 }"
        >
          <img
            (click)="select(i)"
            class="img-fluid"
            src="https://cdn1.gung.io/fit-in/{{
              this.imageSizeThumbnail
            }}/filters:fill(white):background_color(white):etag({{ image.s3ETag }}):format(jpeg)/{{ image.extra.s3UriEncoded }}"
            alt="{{ image.description }}"
          />
        </div>
        <div
          class="col-3 d-flex justify-content-center align-items-center video-background cursor-pointer"
          *ngIf="video"
          [ngClass]="{ 'pr-0': i == images.length - 1 }"
          (click)="select(images.length - 1 + (video ? 1 : 0))"
        >
          <i class="fas fa-play-circle"></i>
        </div>
      </div>
      <a class="scroll-right" (click)="scrollImagesRight()" *ngIf="images.length > 4">
        <i class="fa fa-2x fa-caret-right"></i>
      </a>
    </div>
  </div>
  <div *ngIf="model3dFile" class="col-12 d-flex justify-content-center">
    <a (click)="toggleView3DModel()" class="d-flex align-items-center" translate>
      <i class="fab fa-unity fa-2x"></i>&nbsp;<span translate>VIEW_PRODUCT_AS_3D_MODEL</span>
    </a>
  </div>
</div>

<ng-template #show3DModel>
  <div class="row">
    <div class="col-12 mb-2 text-center">
      <otto-olsen-viewer3d
        #ottoOlsenViewer3d
        [modelFile]="model3dFile"
        width="100%"
        height="400px"
        [initLoad]="true"
      ></otto-olsen-viewer3d>
    </div>
    <div *ngIf="model3dFile" class="col-12 d-flex justify-content-center">
      <a (click)="toggleView3DModel()" class="d-flex align-items-center" translate>
        <i class="far fa-images fa-2x"></i>&nbsp;<span translate>VIEW_PRODUCT_IMAGES</span>
      </a>
    </div>
  </div>
</ng-template>
