<div class="row border-top">
  <div class="col-2 text-center">
    <img
      alt="{{ data | productName }}"
      class="img-fluid mx-auto"
      *ngIf="data.extra.images && data.extra.images[0] as image; else noimage"
      src="https://d27ahaa1qqlr90.cloudfront.net/fit-in/100x100/filters:fill(white):background_color(white):etag({{
        image.s3ETag
      }}):format(jpeg)/{{ image.s3Uri }}"
    />
    <ng-template #noimage>
      <img
        class="card-img-top img-fluid w-100"
        src="https://d27ahaa1qqlr90.cloudfront.net/fit-in/100x100/filters:fill(white):background_color(white)/images/no-image.jpg"
        alt="NO IMAGE"
        class="img-fluid mx-auto d-block"
      />
    </ng-template>
  </div>
  <div class="col-8">
    <h5>{{ data | productName }}</h5>
    <span>{{ data.id }}</span>
  </div>
  <div class="col-2">
    <div class="d-flex align-items-center h-100 w-100">
      <button [routerLink]="data._path" type="button" class="btn btn-primary text-capitalize btn-more" translate>
        READ_MORE_AND_ORDER
      </button>
    </div>
  </div>
</div>
