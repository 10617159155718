<div class="row">
  <div
    *ngFor="let concept of data"
    class="col-12 col-sm-6 col-md-3 col-lg-2 mb-2"
    [class.d-none]="isUser && !concept.extra.pim?.activeInB2B"
  >
    <div
      class="card card-assortment-tree h-100 card-border-top"
      [class.card-border-top-blue]="!concept.extra.pim?.activeInB2B"
    >
      <a *ngIf="isProductManager && !pimEditUrlHide" class="pim-edit" [href]="conceptPimUrl + concept.id" target="_blank" (click)="$event.stopPropagation()">
        <i class="fa-regular fa-pen-to-square"></i>
      </a>
      <!-- <i *ngIf="isUser && !concept.extra.pim?.activeInB2B" class="fas fa-store-slash activeInB2B text-primary" title="Not active in B2B"></i> -->
      <a
        [routerLinkActive]="'active-assortment'"
        [routerLink]="['./', concept.id]"
        queryParamsHandling="merge"
        class="border-0 h-100 d-flex flex-column"
      >
        <img
          *ngIf="concept.extra.images && concept.extra.images[0] as image; else noimage"
          class="card-img-top img-fluid mx-auto d-block"
          src="https://d27ahaa1qqlr90.cloudfront.net/fit-in/350x350/filters:fill(white):background_color(white):etag({{
            image.s3ETag
          }}):format(jpeg)/{{ image.s3Uri }}"
          alt="{{ image.description }}"
        />
        <ng-template #noimage>
          <img
            class="card-img-top img-fluid w-100"
            src="https://d27ahaa1qqlr90.cloudfront.net/fit-in/300x300/images/no-image.jpg"
            alt="NO IMAGE"
            class="img-fluid mx-auto d-block"
          />
        </ng-template>
        <div class="card-body d-flex">
          <div class="card-title w-100">
            <a
              [routerLinkActive]="'active-assortment'"
              [routerLink]="['./', concept.id]"
              queryParamsHandling="merge"
              class="d-flex align-items-center justify-content-between text-break border-0"
            >
              <span>{{ concept | productName }}</span>
              <i class="fa fa-angle-right align-self-end" aria-hidden="true" style="font-size: x-large"></i>
            </a>
          </div>
          <p class="card-text"></p>
        </div>
      </a>
    </div>
  </div>
</div>
<ng-template #loading> LOADING... </ng-template>
