import { Injectable } from '@angular/core';
import { ListLayout, ListLayoutMultipleComponent } from 'gung-list';
import {
  FavouriteProductListConfigService,
  ProductService,
  ProductListConfigService,
  AuthService,
  FavouriteProductsService,
  SelectedCustomerService,
  Customer,
  Product
} from 'gung-standard';
import { Observable, first, forkJoin, of, switchMap } from 'rxjs';
import { OttoOlsenFavouriteProductGridViewComponent } from '../components/otto-olsen-favourite-product-grid-view/otto-olsen-favourite-product-grid-view.component';
import { OttoOlsenFavouriteProductTreeTableViewComponent } from '../components/otto-olsen-favourite-product-tree-table-view/otto-olsen-favourite-product-tree-table-view.component';
import { fnProductName } from '../pipes/product-name.pipe';

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenFavouriteProductListConfigService extends FavouriteProductListConfigService {
  public selectedCustomer: Customer;

  constructor(
    protected productService: ProductService,
    protected productListConfigService: ProductListConfigService,
    protected authService: AuthService,
    protected favouritesService: FavouriteProductsService,
    private selectedCustomerService: SelectedCustomerService
  ) {
    super(productService, productListConfigService, authService, favouritesService);
  }

  getLayouts(): ListLayout<Product>[] {
    return [
      {
        getIconClass: () => 'fas fa-th',
        getListItemComponent: () => OttoOlsenFavouriteProductGridViewComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'ProductGrid'
      },
      {
        getIconClass: () => 'fa fa-list',
        getListItemComponent: () => OttoOlsenFavouriteProductTreeTableViewComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'ProductList'
      }
    ];
  }

  getSearchTerms(item: Product) {
    let referenceId;
    if (
      this.selectedCustomer &&
      item.extra._customerCrossReferences &&
      item.extra._customerCrossReferences[this.selectedCustomer.id]
    ) {
      referenceId = item.extra._customerCrossReferences[this.selectedCustomer.id].referenceId;
    }
    return [item.id, fnProductName(item), referenceId];
  }

  getItems(): Observable<Product[]> {
    return super.getItems().pipe(
      switchMap(products => {
        return forkJoin([of(products), this.selectedCustomerService.getSelectedCustomer().pipe(first())]);
      }),
      switchMap(([products, customer]) => {
        this.selectedCustomer = customer;
        return of(products);
      })
    );
  }
}
