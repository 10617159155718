import { Component, OnInit } from '@angular/core';
import {
  JeevesSalesCartListComponent,
  JeevesSalesCartListRow,
  JeevesUpdateKuarModalComponent
} from 'gung-standard-jeeves';
import {
  ProductService,
  CartService,
  PriceService,
  AvailabilityService,
  SelectedCustomerService,
  Availability,
  CartRow,
  CartRowPrice,
  Customer,
  Product,
  GungModalService,
  AuthService,
  mergeDeep,
  GungFlowService
} from 'gung-standard';
import { DateUtilService } from 'gung-common';
import { environment } from './../../../environments/environment';
import { OttoCheckoutStateServiceService } from '../../services/utils/otto-checkout-state-service.service';
import { HighlightSpanKind } from 'typescript';
import { NgbDate, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { filter, first } from 'rxjs';
import { ProductExtended } from '../otto-olsen-product-details/otto-olsen-product-details.component';
import { ProductTableRowExtended } from '../otto-olsen-product-pagination-list-card/otto-olsen-product-pagination-list-card.component';
import { format } from 'date-fns';
import { fnProductName } from '../../pipes/product-name.pipe';

export interface JeevesSalesCartListRowExtended extends JeevesSalesCartListRow {
  product: ProductExtended;
}

@Component({
  selector: 'otto-olsen-sales-cart-list',
  templateUrl: './otto-olsen-sales-cart-list.component.html',
  styleUrls: ['./otto-olsen-sales-cart-list.component.scss']
})
export class OttoOlsenSalesCartListComponent extends JeevesSalesCartListComponent implements OnInit {
  warningWasActiveBefore = {};
  public requestPriceEmail = environment.requestPriceEmail;

  public mappedData: JeevesSalesCartListRowExtended[];

  public partDeliveriesForbidden = false;
  get selectedDeliveryDate(): string {
    return this.ottoCheckoutState.selectedDeliveryDate;
  }
  markDisabled: (date: NgbDate, _current: { year: number; month: number }) => boolean;
  isAnonymous: boolean;
  selectedCustomer: Customer;

  enableDuplicateRow = false;
  firstTime = true;

  constructor(
    protected productService: ProductService,
    protected cartService: CartService,
    protected priceService: PriceService,
    protected availabilityService: AvailabilityService,
    protected selectedCustomerService: SelectedCustomerService,
    protected dateUtilService: DateUtilService,
    private gungModalService: GungModalService,
    public ottoCheckoutState: OttoCheckoutStateServiceService,
    private authService: AuthService,
    protected modalService: NgbModal,
    protected flowService: GungFlowService
  ) {
    super(productService, cartService, priceService, availabilityService, selectedCustomerService, dateUtilService, flowService);
    this.partDeliveriesForbidden = this.ottoCheckoutState.partDeliveriesForbidden;
    this.markDisabled = this._markDisabled.bind(this);
    this.authService
      .getHasRoles('ANONYMOUS')
      .pipe(first())
      .subscribe(isAnonymous => (this.isAnonymous = isAnonymous));
    this.selectedCustomerService
      .getSelectedCustomer()
      .pipe(first())
      .subscribe(customer => (this.selectedCustomer = customer));
  }

  ngOnInit() {
    this.updateData();
    this.cartService
      .getCurrentCart()
      .pipe(first())
      .subscribe(cart => {
        for (const row of cart) {
          this.warningWasActiveBefore[row.productId] = false;
          row.extra = mergeDeep(row.extra, { warning: false });
        }
      });
  }

  // true if the date should be marked as disabled
  public _markDisabled(date: NgbDate, _current: { year: number; month: number }) {
    if (!this.ottoCheckoutState.dates) {
      return false;
    }

    const foundDate = this.ottoCheckoutState.dates
      // first filter out all the invalid dates
      .filter(d => d.valid)
      .map(d => d.date)
      // find a matching date with the one to check
      .filter(
        d =>
          d.getFullYear() === date.year &&
          // js date object is zero indexed
          d.getMonth() + 1 === date.month &&
          d.getDate() === date.day
      );

    // return true if the date wasn't found, false if it was.
    return foundDate.length === 0;
  }

  public mapData(
    products: Product[],
    prices: CartRowPrice[],
    availabilities: Availability[],
    cartRows: CartRow[],
    customer: Customer
  ): JeevesSalesCartListRowExtended[] {
    return super.mapData(products, prices, availabilities, cartRows, customer).map(cr => {
      if (this.ottoCheckoutState.selectedDeliveryMethod) {
        cr.deliveryMethod = this.ottoCheckoutState.selectedDeliveryMethod;
      }
      if (!cr.cartRow.extra._calc_min_date) {
        // duplicateProduct

        cr.cartRow.extra._calc_min_date = cartRows.find(c => c.productId === cr.productId)?.extra?._calc_min_date;
      }
      this.qtyChangeSoChangeDate(cr);

      const parsedDate = this.dateUtilService.parseDate(cr.cartRow.extra._calc_min_date, 'yyyy-MM-dd');
      const nbgMinDate = new NgbDate(parsedDate.getFullYear(), parsedDate.getMonth() + 1, parsedDate.getDate());
      cr.minDate = nbgMinDate;

      const product: ProductExtended = cr.product;
      if (
        product.extra.ar.q_jis_fast_pakke_strl &&
        product.extra.ar.artfsgforp &&
        Number(product.extra.ar.artfsgforp) > 0
      ) {
        (product as ProductExtended).packageSize = Number(product.extra.ar.artfsgforp);
      }
      // Product original size without cutting
      if (product.extra.ar?.artfsgforp && !isNaN(Number(product.extra.ar.artfsgforp))) {
        product.originalSize = Number(product.extra.ar.artfsgforp);
      }
      if (product.extra.ar?.q_salesbatchsize && !isNaN(Number(product.extra.ar.q_salesbatchsize))) {
        product.packageSize = Number(product.extra.ar.q_salesbatchsize);
      }
      if (product.extra.ar?.q_salesmoq && !isNaN(Number(product.extra.ar.q_salesmoq))) {
        product.firstStepAmount = Number(product.extra.ar.q_salesmoq);
      }
      return {
        ...cr,
        product
      };
    });
  }

  editNote(row: CartRow): void {
    let noteInput = '';
    if (!!row.extra.orp) {
      noteInput = row.extra.orp.edit || row.extra.orp.editext || '';
    }

    this.gungModalService.openEditNoteModal(noteInput).then(
      (result: any) => {
        if (!!result && result.hasOwnProperty('note')) {
          this.cartService.setExtra(
            row.productId,
            {
              orp: {
                editext: result.note,
                edit: result.note
              }
            },
            row.targetStockId,
            row.productPartialId
          );
        }
      },
      reason => { }
    );
  }

  public isInteger(row) {
    return !Number.isInteger(row.quantity / row.product.originalSize);
  }

  public openCrossReferenceEditor(product: ProductTableRowExtended) {
    const modal = this.modalService.open(JeevesUpdateKuarModalComponent, { size: 'lg' });
    modal.componentInstance.product = product.product;
  }

  public updateDiscountPercentage(row: JeevesSalesCartListRow, value: any, totalDiscount?: boolean) {
    const oldVal = row.discountPercent;
    if (oldVal === value) {
      return;
    }
    // eslint-disable-next-line eqeqeq
    if (!value) {
      this.cartService.removeExtraField(row.productId, 'orp.rabatt1', row.targetStockId, row.productPartialId);
      if (totalDiscount) {
        this.cartService.removeExtraField(row.productId, 'orp.rabatt2', row.targetStockId, row.productPartialId);
        this.cartService.removeExtraField(row.productId, 'orp.rabatt3', row.targetStockId, row.productPartialId);
        this.cartService.removeExtraField(row.productId, 'orp.kundrabatt', row.targetStockId, row.productPartialId);
        this.cartService.removeExtraField(row.productId, 'orp.volymrabatt', row.targetStockId, row.productPartialId);
      }
      return;
    }

    const extra: any = {
      orp: {
        rabatt1: value
      }
    };

    if (totalDiscount) {
      extra.orp.rabatt2 = '0';
      extra.orp.rabatt3 = '0';
      extra.orp.kundrabatt = '0';
      extra.orp.volymrabatt = '0';
    }

    this.cartService.setExtra(row.productId, extra, row.targetStockId, row.productPartialId);
  }

  duplicateProduct(row: JeevesSalesCartListRow, index: number) {
    if (!row.cartRow.productPartialId) {
      // this.cartService.removeRow(row.related[0].cartRow);
      // this.cartService.addToCart(row.related[0].product.id, row.related[0].cartRow.qty, row.related[0].cartRow.targetStockId, row.related[0].product.id + '_' + 0);
      this.cartService.setProductPartialId(row.product.id, row.cartRow.targetStockId, row.product.id + '_' + 0);
    }
    const productPartialId = row.product.id + '_' + new Date().getTime();
    this.cartService.addToCart(
      row.product.id,
      1 || row.cartRow.qty,
      row.cartRow.targetStockId,
      productPartialId,
      index + 1,
      undefined,
      undefined,
      fnProductName(row.product)
    );

    this.updateData();
    // this.updateDataContext(row.related[0].product.id, row.related[0].cartRow.qty, false, productPartialId);
  }

  public updateQty(row: JeevesSalesCartListRow, value: any) {
    const newQty = Number.parseInt(value, 10);
    // check qty
    const oldQty = row.quantity;
    if (oldQty === newQty) {
      return;
    }
    // value has changed, propagate throught the cart
    this.cartService.setProductQuantity(row.productId, newQty, row.targetStockId, row.productPartialId);
  }

  qtyChangeSoChangeDate(row: JeevesSalesCartListRow) {
    this.qtyChangeSoChangeDate2(row);
    return;
    let min_date;
    if (row.cartRow?.extra?._availability_reference?.availabilities) {
      if (row.cartRow.extra._availability_reference.currentAvailability < row.cartRow.qty) {
        const availabilities = row.cartRow.extra._availability_reference.availabilities;

        for (const date of Object.keys(availabilities)) {
          if (availabilities[date] >= row.cartRow.qty) {
            const year = date.substring(0, 2);
            const month = date.substring(2, 4);
            const day = date.substring(4, 6);
            min_date = '20' + year + '-' + month + '-' + day;

            break;
          }
        }
      }
    }

    if (min_date && min_date > row.cartRow.extra.orp.ordberlevdat && !this.warningWasActiveBefore[row.productId]) {
      row.cartRow.extra = {
        ...row.cartRow.extra,
        warning: true
      };
      const parsedDate = this.dateUtilService.parseDate(min_date || row.cartRow.extra._calc_min_date, 'yyyy-MM-dd');
      const nbgNewMinDate = new NgbDate(parsedDate.getFullYear(), parsedDate.getMonth() + 1, parsedDate.getDate());
      this.updateDeliveryDate2(row, nbgNewMinDate, true);
    }
  }

  qtyChangeSoChangeDate2(row: JeevesSalesCartListRow) {
    if (row.cartRow?.extra?._availability_reference?.availabilities) {
      const availabilities = row.cartRow.extra._availability_reference.availabilities;
      const rowDeliveryDate = this.dateUtilService.parseDate(row.cartRow.extra.orp.ordberlevdat, 'yyyy-MM-dd');
      let min_date;
      for (const date of Object.keys(availabilities)) {
        const avDate = this.dateUtilService.parseDate(date, 'yyMMdd');
        if (availabilities[date] >= row.cartRow.qty) {
          if (avDate > rowDeliveryDate) {
            min_date = this.dateUtilService.getFormattedDateString(avDate, 'yyyy-MM-dd');
          } else if (rowDeliveryDate > avDate && !this.warningWasActiveBefore[row.productId]) {
            min_date = this.dateUtilService.getFormattedDateString(avDate, 'yyyy-MM-dd');
          }
          break;
        }
      }

      if (min_date) {
        row.cartRow.extra = mergeDeep(row.cartRow.extra, { warning: true });

        const parsedDate = this.dateUtilService.parseDate(min_date || row.cartRow.extra._calc_min_date, 'yyyy-MM-dd');
        const nbgNewMinDate = new NgbDate(parsedDate.getFullYear(), parsedDate.getMonth() + 1, parsedDate.getDate());
        this.updateDeliveryDate2(row, nbgNewMinDate, true);
      }
    }
  }

  updateDeliveryDate2(row: JeevesSalesCartListRow, selectedDeliveryDate, warning: boolean) {
    const oldVal = row.deliveryDate || row.minDate;

    let deliveryDate;

    if (selectedDeliveryDate instanceof NgbDate) {
      deliveryDate = format(
        new Date(selectedDeliveryDate.year, selectedDeliveryDate.month - 1, selectedDeliveryDate.day),
        'yyyy-MM-dd'
      );
    } else {
      deliveryDate = format(selectedDeliveryDate.date, 'yyyy-MM-dd');
    }

    if (oldVal === deliveryDate) {
      return;
    }
    if (!warning) {
      row.cartRow.extra = {
        ...row.cartRow.extra,
        warning: false
      };
    }
    if (!deliveryDate) {
      this.cartService.removeExtraField(row.productId, 'orp.ordberlevdat', row.targetStockId, row.productPartialId);
      return;
    }
    this.cartService.setExtra(
      row.productId,
      {
        orp: {
          ordberlevdat: deliveryDate
        }
      },
      row.targetStockId,
      row.productPartialId
    );
  }

  updateDeliveryDateWithWarnings(row: JeevesSalesCartListRow, selectedDeliveryDate, warning: boolean) {
    this.updateDeliveryDate2(row, selectedDeliveryDate, warning);
    this.warningWasActiveBefore[row.productId] = true;
  }

  public updateOverridePrice(row: JeevesSalesCartListRow, value: any) {
    const oldVal = row.overridePrice || row.originalPrice;

    if (oldVal === value) {
      return;
    }

    if (!value || isNaN(value) || row.originalPrice === value) {
      this.cartService.removeExtraField(row.productId, 'orp.vb_pris', row.targetStockId, row.productPartialId);
      return;
    }

    this.cartService.setExtra(
      row.productId,
      {
        orp: {
          vb_pris: parseFloat(value).toFixed(2)
        }
      },
      row.targetStockId,
      row.productPartialId
    );
  }
}
