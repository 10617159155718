import { Component, OnInit } from '@angular/core';
import { OttoOlsenAvailabilityModalComponent } from '../otto-olsen-availability-modal/otto-olsen-availability-modal.component';
import { DatePipe } from '@angular/common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { endOfDay, startOfWeek, endOfWeek, addWeeks, addMonths, startOfMonth, endOfMonth, isWithinInterval, getMonth, getYear } from 'date-fns';
import { DateUtilService } from 'gung-common';

@Component({
  selector: 'otto-olsen-availability-warehouses-modal',
  templateUrl: './otto-olsen-availability-warehouses-modal.component.html',
  styleUrls: ['./otto-olsen-availability-warehouses-modal.component.scss']
})
export class OttoOlsenAvailabilityWarehousesModalComponent extends OttoOlsenAvailabilityModalComponent implements OnInit {

  availabilities: {
    class: string;
    stock: number;
    date: Date;
    info: 'TODAY' | 'CURRENT_WEEK' | 'NEXT_WEEK' | 'AFTER_NEXT_WEEK' | 'NEXT_MONTH' | string;
  }[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    protected datePipe: DatePipe,
    public dateUtilService: DateUtilService
  ) {
    super(activeModal, dateUtilService);
  }

  ngOnInit(): void {
    if (this.availability.extra.availabilities) {
      for (const av in this.availability.extra.availabilities) {
        if (Object.prototype.hasOwnProperty.call(this.availability.extra.availabilities, av)) {
          const stock = this.availability.extra.availabilities[av];
          this.availabilities.push({
            class: stock > 0 ? 'stock' : 'unavailable',
            stock,
            date: this.parseDate(av),
            info: this.getInfoAvailability(this.parseDate(av))
          });
        }
      }
    }
  }

  getInfoAvailability(d: Date): 'TODAY' | 'CURRENT_WEEK' | 'NEXT_WEEK' | 'AFTER_NEXT_WEEK' | 'NEXT_MONTH' | string {
    const today = endOfDay(new Date());
    
    const weekStart = startOfWeek(today);
    const weekEnd = endOfWeek(today);

    const nextWeek = addWeeks(new Date(), 1);
    const nextWeekStart = startOfWeek(nextWeek);
    const nextWeekEnd = endOfWeek(nextWeek);
    
    const nextMonth = addMonths(new Date(), 1);
    const nextMonthStart = startOfMonth(nextMonth);
    const nextMonthEnd = endOfMonth(nextMonth);

    if (d < today) {
      return 'TODAY';
    } else if (isWithinInterval(d, {start: weekStart, end: weekEnd}) ) {
      return 'CURRENT_WEEK';
    } else if (isWithinInterval(d, {start: nextWeekStart, end: nextWeekEnd}) ) {
      return 'NEXT_WEEK';
    }else if (isWithinInterval(d, {start: nextMonthStart, end: nextMonthEnd}) ) {
      return 'NEXT_MONTH';
    } else if ((nextWeekEnd < nextMonthStart) && isWithinInterval(d, {start: nextWeekEnd, end: nextMonthStart} )) {
      return 'AFTER_NEXT_WEEK';
      
    } 
    const month = getMonth(d);
    const year = getYear(d);
    const currentYear = getYear(new Date());
    if (year <= currentYear) {
      return this.datePipe.transform(d, 'MMMM');
    }
    return this.datePipe.transform(d, 'MMMM YYYY');
  }
}
