<button fastsearchSelect type="button" class="btn btn-light btn-block btn-sm text-left" [routerLink]="data._path">
  <div class="row">
    <div class="col-3 pr-0">
      <img
        alt="{{ data | productName }}"
        class="img-fluid"
        *ngIf="data.extra.images && data.extra.images[0]; else noimage"
        src="https://d27ahaa1qqlr90.cloudfront.net/fit-in/100x100/filters:fill(white):background_color(white):etag({{
          data.extra.images[0].s3ETag
        }}):format(jpeg)/{{ data.extra.images[0].s3Uri }}"
      />
      <ng-template #noimage>
        <img
          class="card-img-top img-fluid w-100"
          src="https://d27ahaa1qqlr90.cloudfront.net/fit-in/100x100/filters:fill(white):background_color(white)/images/no-image.jpg"
          alt="NO IMAGE"
          class="img-fluid mx-auto d-block"
        />
      </ng-template>
    </div>
    <div class="col-9 text-truncate">
      <span class="d-block">
        <!-- <small><b>{{ data.id }}</b></small> <lib-price class="float-right" [price]="data.extra.price" type="NET" [currency]="data.extra.price.customerNetPrice.currencyCode"></lib-price> -->
      </span>
      <br />
      <span>{{ data | productName }}</span>
    </div>
  </div>
</button>
